import { gql } from "@apollo/client";

const GET_BANNER_BY_ID = gql`
	query getBannerById($id: String!) {
		getBannerById(id: $id) {
			id
			isEnabled
			title
			description
			images {
				fileName
				src
				position
			}
		}
	}
`;

export const GetBannerById = GET_BANNER_BY_ID;