import { useState, useEffect } from 'react';
import ActionButton from '../Common/ActionButton/ActionButton';
import TextInput from '../Common/TextInput/TextInput';
import { useMutation } from "@apollo/client";
import './UserRegister.scss';
import { UserRegisterGql } from '../../modules/GraphQl/Resolvers/User/userRegister.gql';
import Storage from '../../modules/Storage/Core/Storage';
import { KEY_UID } from '../../modules/Storage/Api/ConstantInterface';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../Context/UserProvider';

interface RegisterData {
    username: string,
    phone: string,
    password: string,
    repassword: string
}

const UserRegister = () => {

    const navigate = useNavigate();
    const [ registerData, setRegisterData ] = useState<RegisterData>(
        {
            username: '',
            phone: '',
            password: '',
            repassword: ''
        }
    );

    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isDisable, setIsDisable ] = useState(true);
    const [ submitBtnLabel, setSubmitBtnLabel ] = useState('sign up');
    const [ registerUser, { data, loading, error }] = useMutation(UserRegisterGql);
    const { login } = useUser();

    useEffect(() => {
        if (data) {
            if (data.userRegister.id === 'error') {
                setIsDisable(false);
                if (data.userRegister.username !== '' && data.userRegister.phone === '') {
                    setErrorMsg(data.userRegister.username);
                } else if (data.userRegister.username === '' && data.userRegister.phone !== '') {
                    setErrorMsg(data.userRegister.phone);
                } else {
                    setErrorMsg(`Both username and phone number had been registered.`);
                }
            } else {
                login(data.userRegister);
                setErrorMsg('');
                setSubmitBtnLabel('Creating account...');
                setIsDisable(true);
                const timer = setTimeout(() => {
                    if (data.userRegister.isVerified) {
                        navigate('/');
                    } else {
                        navigate('/verify');
                    }                    
                    clearTimeout(timer);
                }, 2000);
            }
        }
    }, [data]);

    const onChange = (val: string, interfaceData: string) => {
        switch (interfaceData) {
            case 'username':
                registerData.username = val;
                break;
            case 'phone':
                registerData.phone = val;
                break;
            case 'password':
                registerData.password = val;
                break;
            case 'repassword':
                registerData.repassword = val;
                break;
        }
        setRegisterData({...registerData});
        setIsDisable(validateRegisterData());
    }

    const validateRegisterData = () => {
        if (registerData.username.length <= 0) {
            return true;
        }

        if (registerData.phone.length <= 0) {
            return true;
        }

        if (registerData.password.length <= 0) {
            return true;
        }

        if (registerData.repassword.length <= 0 || registerData.password !== registerData.repassword) {
            return true;
        }

        return false;
    }

    const onSubmit = async () => {
        if (!validateRegisterData()) {
            await registerUser({
                variables: {
                  username: registerData.username,
                  phone: `60${registerData.phone}`,
                  password: registerData.password
                },
            });
        }
    }

    if (error) {
        console.error(error)
    }

    return (
        <div className="userRegisterDiv">
            <div className='userRegisterContDiv'>
                <span className='userRegisterTitle'>Create new account</span>
                <form className='userRegisterForm' autoComplete='off' >
                    <TextInput 
                        identifier='username'
                        label="Username" 
                        isRequired={true}
                        canShowError={registerData.username.length <= 0 ? true : false}
                        errorText="Username is required."
                        maxLength={15}
                        inputType={"username"}
                        isAutocomplete={false}
                        onChange={onChange}
                        tabIndex={1}
                    />
                    <TextInput 
                        identifier='phone'
                        label="Phone number" 
                        isRequired={true}
                        canShowError={registerData.phone.length <= 0 ? true : false}
                        errorText="Phone number is required."
                        isNumber={true}
                        inputType={"phone"}
                        prefix={"+60"}
                        minLength={9}
                        maxLength={10}
                        isAutocomplete={false}
                        onChange={onChange}
                        tabIndex={2}
                    />
                    <TextInput 
                        identifier='password'
                        label="Password" 
                        isRequired={true}
                        canShowError={registerData.password.length <= 0 ? true : false}
                        errorText="Password is required."
                        hasPassword={true}
                        maxLength={15}
                        isAutocomplete={false}
                        onChange={onChange}
                        tabIndex={3}
                    />
                    <TextInput
                        identifier='repassword'
                        label="Retype password" 
                        isRequired={true}
                        canShowError={registerData.repassword.length <= 0 || registerData.password != registerData.repassword ? true : false}
                        errorText={registerData.repassword.length <= 0 ? "Retype password is required." : "Passwords do not match."}
                        hasPassword={true}
                        maxLength={15}
                        isAutocomplete={false}
                        onChange={onChange}
                        tabIndex={4}
                        onEnter={onSubmit}
                    />
                    <span className='errorMsg'>{errorMsg}</span>
                    <ActionButton 
                        label={submitBtnLabel} 
                        isDisable={isDisable}
                        title={'Click to sign up.'} 
                        onClick={onSubmit}
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3}
                    />
                    <span className='haveAccount'>Already registered?&nbsp;<a href="/login">Login now.</a></span>
                </form>
            </div>
        </div>
    );
}

export default UserRegister;
