import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserRestrictor from '../Common/Restrictor/UserRestrictor';
import { useUser } from '../Context/UserProvider';
import './MyAccount.scss';
import MyAccountBooking from './MyAccountBooking/MyAccountBooking';
import MyAccountPersonal from './MyAccountPersonal/MyAccountPersonal';
import MyAccountSettings from './MyAccountPersonal/MyAccountSettings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import { useHelper } from '../Context/HelperProvider';

const MyAccount = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { user } = useUser();
  const [ username, setUsername ] = useState(user.username);
  const [ isMobileMenuActive, setIsMobileMenuActive ] = useState(false);
  const sideMenuRef = useRef(null);
  const { setSideMenuHeight } = useHelper();
  const [ activePath, setActivePath ] = useState(`/user/${username}/me/info`);

  useEffect(() => {
    if (state) {
      setActivePath(state.active);
    }
  }, [state]);

  useEffect(() => {
    setUsername(user.username);
    if (!state) {
      setActivePath(`/user/${user.username}/me/info`);
    }
  }, [user]);

  useEffect(() => {
    if (sideMenuRef.current) {
      const { clientHeight } = sideMenuRef.current;
      setSideMenuHeight(clientHeight);
    }
  }, [sideMenuRef]);

  const ROUTES = [
    {
      name: 'My Account',
      path: `/user/${username}/me`,
      level: 0
    },
    {
      name: 'Personal Information',
      path: `/user/${username}/me/info`,
      level: 1
    },
    {
      name: 'Account Settings',
      path: `/user/${username}/me/settings`,
      level: 1
    },
    {
      name: 'My Bookings',
      path: `/user/${username}/me`,
      level: 0
    },
    {
      name: 'Unpaid',
      path: `/user/${username}/booking/unpaid`,
      level: 1
    },
    {
      name: 'Paid',
      path: `/user/${username}/booking/paid`,
      level: 1
    },
    {
      name: 'Confirmed',
      path: `/user/${username}/booking/confirmed`,
      level: 1
    },
    {
      name: 'In Progress',
      path: `/user/${username}/booking/in_progress`,
      level: 1
    },
    {
      name: 'Absent',
      path: `/user/${username}/booking/absent`,
      level: 1
    },
    {
      name: 'Rescheduled',
      path: `/user/${username}/booking/rescheduled`,
      level: 1
    },
    {
      name: 'Cancelled',
      path: `/user/${username}/booking/cancelled`,
      level: 1
    },
    {
      name: 'Refunded',
      path: `/user/${username}/booking/refunded`,
      level: 1
    },
    {
      name: 'Completed',
      path: `/user/${username}/booking/completed`,
      level: 1
    },
  ];

  const showPage = () => {
    let pathnameRegex = `.*?\/user\/${username}\/booking\/[a-zA-Z0-9]*`;
    switch (location.pathname) {
      default:
      case `/user/${username}/me/info`:
        return <MyAccountPersonal/>;
      case `/user/${username}/me/settings`:
        return <MyAccountSettings/>;
      case location.pathname.match(new RegExp(pathnameRegex, 'mi'))?.input:
        return <MyAccountBooking/>;
    }
  }

  const openMobileMenu = () => {
    setIsMobileMenuActive(!isMobileMenuActive);
  }

  const onRouteClicked = (path: string) => {
    setIsMobileMenuActive(false);
    navigate(path, { state: { active: path }});
  }

  return (
    <div className='myAccountDiv'>
      <UserRestrictor/>
      <div className='myAccountSideMenuDiv'>
        <div className='myAccountSideMenu' ref={sideMenuRef}>
          <IconButton
            className={`myAccountSideMenuCaret ${isMobileMenuActive ? 'myAccountSideMenuCaretActive' : ''}`}           
            aria-label="menu"
            onClick={openMobileMenu}
          >
            <KeyboardArrowDownIcon/>
          </IconButton>
          {
            ROUTES.map((route: any, index: number) => {
              return (
                  route.level === 0 ? 
                  <span key={index} className={`myAccountSideMenuLevel0 ${isMobileMenuActive ? 'myAccountSideMenuLevelActive' : ''}`}>{route.name}</span> :
                  <span 
                    key={index} 
                    onClick={() => onRouteClicked(route.path)} 
                    className={`myAccountSideMenuLevel${route.level} ${activePath === route.path ? 'myAccountSideMenuActive' : ''} ${isMobileMenuActive ? activePath === route.path ? 'myAccountSideMenuLevelActive myAccountSideMenuLevelDActive' : 'myAccountSideMenuLevelActive' : ''}`}
                  >
                      {route.name}
                  </span>
              );
            })
          }
        </div>
      </div>
      <div className='myAccountPageDiv'>
        {showPage()}
      </div>
    </div>
  );
}//end MyAccount()

export default MyAccount;
