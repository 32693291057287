import { useState, useEffect } from 'react';
import ActionButton from '../../../../Common/ActionButton/ActionButton';
import BasicTextInput from '../../../../Common/BasicTextInput/BasicTextInput';
import '../../../AdminAction.scss';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { BACKOFFICE_URL } from '../../../../../modules/Api/Data/ConstantInterface';
import { UserInterface } from '../../../../../modules/Api/Data/DocumentInterface';
import { GetUserByIdGql } from '../../../../../modules/GraphQl/Resolvers/User/getUserById.gql';
import { useHelper } from '../../../../Context/HelperProvider';
import { IsUserExistGql } from '../../../../../modules/GraphQl/Resolvers/User/isUserExist.gql';
import { UpdateUserByIdGql } from '../../../../../modules/GraphQl/Resolvers/User/updateUserById.gql';
import { useAdmin } from '../../../../Context/AdminProvider';

const AdminEditCustomer = () => {

    const { cryptor, utility } = useHelper();
    const { gqlHeader } = useAdmin();
    const navigate = useNavigate();
    const location = useLocation();
    const [ formData, setFormData ] = useState<any>({});
    const [ oldData, setOldData ] = useState<any>({});
    const [ validateData, setValidateData ] = useState<UserInterface>({
        username: '',
        phone: ''
    });
    const [ fieldsToBeUpdated, setFieldsToBeUpdated ] = useState<string[]>([]);
    const { data } = useQuery(GetUserByIdGql, {
        variables: {
            id: location.pathname.split("/").pop()
        },
        context: gqlHeader
    });
    const [ IsUserExist, { data: existData } ] = useMutation(IsUserExistGql);
    const [ UpdateUser, { data: updateData} ] = useMutation(UpdateUserByIdGql);
    const [ isDisable, setIsDisable ] = useState(true);

    useEffect(() => {
        if (data) {
            if (typeof data.getUserById.id !== "string") {
                navigate(`/${BACKOFFICE_URL}/user/customer`);
            } else {
                setFormData({
                    username: data.getUserById.username,
                    phone: data.getUserById.phone
                });
                setOldData({
                    username: data.getUserById.username,
                    phone: `60${utility.phoneEscaper(data.getUserById.phone)}`
                });
            }
        }
    }, [data]);
    
    useEffect(() => {
		if (existData) {
            if (existData.isUserExist.username !== '' || existData.isUserExist.phone !== '') {
                setValidateData({...existData.isUserExist});
                setIsDisable(true);
            } else {
                onUpdate();
            }
        }
	}, [existData]);

    useEffect(() => {
		if (updateData) {
            if (updateData.updateUserById) {
                navigate(`/${BACKOFFICE_URL}/user/customer`);
            } else {
                setIsDisable(false);
            }
        }
	}, [updateData]);

	useEffect(() => {
		if (fieldsToBeUpdated.length > 0) {
			setIsDisable(validateFormData());
		}
	}, [fieldsToBeUpdated]);

	const updateFieldsToBeUpdated = (field: string) => {
		if (!fieldsToBeUpdated.includes(field)) {
			fieldsToBeUpdated.push(field);
		}
		setFieldsToBeUpdated([...fieldsToBeUpdated]);
	};

    const onChange = (value: string, identifier: string) => {
        setFormData({...formData, [identifier]: value });
        updateFieldsToBeUpdated(identifier);
    }

    const onValidate = async () => {
        let username: string = '';
        let phone: string = '';
        if (formData.username !== oldData.username) {
            username = formData.username;
        }
        if (`60${utility.phoneEscaper(formData.phone)}` !== oldData.phone) {
            phone = `60${utility.phoneEscaper(formData.phone)}`;
        }
        await IsUserExist({
            variables: {
                username: username,
                phone: phone,
                type: 'admin'
            }
        })
    }

    const onUpdate = async () => {
        let data: any = {
            username: formData.username,
            phone: `60${utility.phoneEscaper(formData.phone)}`
        }
        await UpdateUser({
            variables: {
                id: location.pathname.split("/").pop(),
                data: cryptor.encrypt(JSON.stringify(data))
            }
        })
    }

    const validateFormData = () => {
        /** @ts-ignore */
        if (formData.username?.length <= 0) {
            return true;
        }
        /** @ts-ignore */
        if (formData.password?.length <= 0) {
            return true;
        }

        return false;
    }

    return (
        data && data.getUserById &&
            <div className='adminActionDiv'>
            <span className="adminActionHeading adminActionBookingHeading">
                <span>{data.getUserById.username}</span>
                <span></span>
                <ActionButton 
                    label={'Confirm'} 
                    title={'Click to submit.'} 
                    isDisable={isDisable}
                    onClick={() => onValidate()}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
            <table className='adminActionTable'>
                <tbody>
                    <tr>
                        <td className='adminActionHeader'>Username</td>
                        <td>                            
                            <BasicTextInput 
                                identifier='username'
                                isRequired={true}
                                maxLength={15}
                                canShowError={formData.username?.length <= 0 || validateData.username?.length !== 0 ? true : false}
                                errorText={validateData.username !== '' ? validateData.username : "Username is required."}
                                isAutocomplete={false}
                                onChange={onChange}
                                value={data.getUserById.username}
                                tabIndex={1}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Phone</td>
                        <td>
                            <BasicTextInput 
                                identifier='phone'
                                isRequired={true}
                                canShowError={formData.phone?.length <= 0 || validateData.phone?.length !== 0 ? true : false}
                                errorText={validateData.phone !== '' ? validateData.phone : "Phone number is required."}
                                isNumber={true}
                                inputType={"phone"}
                                prefix={"+60"}
                                minLength={9}
                                maxLength={10}
                                isAutocomplete={false}
                                onChange={onChange}
                                value={utility.phoneEscaper(data.getUserById.phone)}
                                tabIndex={2}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}//end AdminEditCustomer()

export default AdminEditCustomer;
