import { gql } from "@apollo/client";
import { BOOKING_FRAGMENT } from "../../Fragments/BookingFragment";

const GET_BOOKINGS_BY_UID = gql`
  ${BOOKING_FRAGMENT}
  query getBookingsByUid($uid: String!, $status: String!) {
    getBookingsByUid(uid: $uid, status: $status) {
      ...BookingFragment
    }
  }
`;

export const GetBookingsByUidGql = GET_BOOKINGS_BY_UID;
