import { gql } from "@apollo/client";

const CREATE_FAQ = gql`
	mutation createFaq($class: String!, $title: String!, $description: String!) {
		createFaq(class: $class, title: $title, description: $description) {
			status
			message
		}
	}
`;

export const CreateFaq = CREATE_FAQ;
