import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import './StripePaymentResult.scss';
import { useEffect, useState } from "react";
import { useUser } from "../Context/UserProvider";

const StripePaymentResult = () => {
    const location = useLocation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ status, setStatus ] = useState<any>(null);
    const [ paymentId, setPaymentId ] = useState<any>('');
    const navigate = useNavigate();
    const { user } = useUser();

    useEffect(() => {
        let status = location.pathname.split('/').pop();
        setStatus(status);

        if (searchParams.has('id')) {
            setPaymentId(searchParams.get('id'));
        }
    }, []);

    const onNavigate = (path: any) => {
        navigate(path, { state: { active: path } });
    }

    return (
        <div className="paymentResultDiv">
            {
                status && status === 'success' ?
                <div>
                    <span className='paymentResultSuccessTitle'>Thank you!</span>
                    <span className="paymentContSubTitle">Your booking is successful!</span>
                    {
                        paymentId && <span className="paymentIdCont">Payment Ref. No.: <span className="paymentIdSpan">#{paymentId}</span></span>
                    }
                    <span className="paymentRedirection">You can view <span onClick={() => onNavigate(`/user/${user.username}/booking/paid`)} title='Click to view your booking(s)'>your booking</span> in your personal account.</span>
                </div> : 
                <div>
                    <span className='paymentResultFailed'>
                        <svg viewBox="0 0 125 107" xmlns="http://www.w3.org/2000/svg">
                            <defs></defs>
                            <g className="svgG" transform="matrix(2.81, 0, 0, 2.81, -63.869164, -77.022095)">
                                <circle cx="30.344" cy="33.274" r="5.864" className="svgGC1" transform="  matrix(1 0 0 1 0 0) "></circle>
                                <circle cx="59.663999999999994" cy="33.274" r="5.864" className="svgGC2" transform="  matrix(1 0 0 1 0 0) "></circle>
                                <path className='svgGP' d="M 65.778 65.49 C 65.438 65.49 65.096 65.343 64.812 65.039 C 59.239 59.078 52.209 55.795 45.018 55.795 C 37.825 55.795 30.796 59.078 25.224 65.039 C 24.571 65.739 23.608 65.611 23.074 64.756 C 22.54 63.901 22.637 62.641 23.291 61.942 C 29.407 55.398 37.123 51.795 45.018 51.795 C 52.912 51.795 60.628 55.399 66.745 61.941 C 67.398 62.64 67.495 63.9 66.961 64.755 C 66.659 65.239 66.22 65.49 65.778 65.49 Z" stroke-linecap="round"></path>
                            </g>
                        </svg>
                        <svg className='svgTear' viewBox="0 0 125 107" xmlns="http://www.w3.org/2000/svg">
                            <defs></defs>
                            <g transform="matrix(0.00482, 0, 0, -0.00482, 88.31041, 83.728745)" fill="#66C0D2" stroke="none">
                                <path d="M4526 10235 c-11 -56 -84 -317 -126 -449 -128 -403 -267 -741 -469
                            -1146 -235 -469 -420 -775 -978 -1620 -176 -267 -369 -565 -428 -664 -229
                            -381 -363 -692 -420 -976 -22 -107 -29 -368 -16 -550 90 -1217 703 -2015 1726
                            -2245 230 -52 393 -68 695 -69 281 -1 389 7 608 45 745 129 1291 528 1602
                            1171 173 359 262 756 277 1233 9 297 -20 482 -113 732 -123 330 -261 572 -754
                            1318 -575 872 -745 1154 -985 1635 -203 407 -336 731 -465 1136 -42 132 -115
                            393 -126 449 -3 19 -10 35 -14 35 -4 0 -11 -16 -14 -35z"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="paymentContSubTitle">Payment Failed!</span>
                    <span className="paymentRedirection">You can view <span onClick={() => onNavigate(`/user/${user.username}/booking/unpaid`)} title='Click to view your booking(s)'>your booking</span> in your personal account.</span>
                </div>
            }
        </div>
    );
}//end StripePaymentResult()

export default StripePaymentResult;
