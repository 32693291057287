import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const DrawerListItem = (props: any) => {
    const {
        isDrawerOpen,
        id,
        icon,
        title,
        onClick,
        isDisplayOnly,
        isSubMenu,
        isActive
    } = props;

    return (
      isDisplayOnly ? 
        <ListItem key={title} disablePadding 
          sx={{ 
            display: 'block', 
            background: isActive ? '#B4A28E' : 'transparent',
            color: isActive ? '#fff': 'inherit'
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isDrawerOpen ? 'initial' : 'center',
              px: 2.5,
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
              opacity: '1 !important'
            }}
            disableTouchRipple
            disableRipple
            disableGutters
            disabled
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerOpen ? 3 : 'auto',
                justifyContent: 'center',
                color: isActive ? '#fff': 'inherit'
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem> : 
        <ListItem key={title} disablePadding 
          sx={{ 
            display: 'block', 
            background: isActive ? '#B4A28E' : 'transparent',
            color: isActive ? '#fff': 'inherit'
          }}
        >        
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isDrawerOpen ? 'initial' : 'center',
            px: 2.5,
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => onClick(id)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isDrawerOpen ? 3 : 'auto',
              justifyContent: 'center',
              paddingLeft: isSubMenu ? isDrawerOpen ? '3em' : '0' : '0',
              color: isActive ? '#fff': 'inherit'
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    );
}//end DrawerListItem()

export default DrawerListItem;