import { Alert, Button, Fade, IconButton, Snackbar } from '@mui/material';
import { BsPhone } from "react-icons/bs";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import './Location.scss';

const Location = () => {

    const ADDRESS = "17-M Jalan Manis 4, Taman Segar, Jalan Cheras 56100, Kuala Lumpur";
    const ADDRESS_DISPLAY = "17-M Jalan Manis 4, <br/> Taman Segar,<br/> Jalan Cheras 56100,<br/> Kuala Lumpur.";
    const CONTACT_PERSON = [
        {
            name: 'Samantha',
            phone: '+60169650026'
        }
    ];
    const [ snackbar, setSnackbar ] = useState({
        open: false,
        duration: 3000,
        message: "Address Copied",
        transition: Fade,
    })

    const copyAddress = () => {
        setSnackbar({
            ...snackbar, open: true, message: "Address Copied"
        })
        navigator.clipboard.writeText(ADDRESS);
    }

    const closeSnackbar = () => {
        setSnackbar({
            ...snackbar, open: false
        })
    }

    const snackbarAction = (
        <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    )

    return (
       <div className='locationDiv'>
            <div className='locationContactDiv'>
                <span className='locationContactTitle'>Get In Touch</span>
                <div className='contactPersonDiv'>
                    {
                        CONTACT_PERSON.map((person: any) => {
                            return (
                                <Button key={person.phone} className='locationButtonDiv' component="a" href={"tel:" + person.phone} sx={{color: "#5d3b23"}}><BsPhone style={{fontSize: "20px"}}/><span className='locationPhone'>{person.phone}&nbsp;<span className="locationPhoneOwner">{person.name}</span></span></Button>
                            );
                        })
                    }
                    <Button className='locationButtonDiv' sx={{color: "#5d3b23"}} onClick={copyAddress}><LocationOnIcon sx={{fontSize: "20px", color: "#A86C6B"}}/><span className="locationAddress" dangerouslySetInnerHTML={{ __html: ADDRESS_DISPLAY }}></span></Button>
                </div>
            </div>
            <div className='locationMapDiv'>
                <iframe className="locationMap" title="Beauvie Hair Care Map Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.02027948954!2d101.74004412467593!3d3.0892589468863894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc3515bf21431b%3A0x9569daac5bcfff94!2sBeauvie%20Hair%20Care!5e0!3m2!1sen!2smy!4v1681749082339!5m2!1sen!2smy" loading="lazy"></iframe>
            </div>
            <Snackbar
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    open={snackbar.open}
                    autoHideDuration={snackbar.duration}
                    action={snackbarAction}
                    onClose={closeSnackbar}
                    TransitionComponent={snackbar.transition}
                >
                <Alert onClose={closeSnackbar} severity="success">
                    {snackbar.message}
                </Alert>
            </Snackbar>
       </div> 
    );
}//end Location()

export default Location;
