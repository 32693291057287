import { useNavigate, useSearchParams } from "react-router-dom";
import './StripePaymentRedirect.scss';
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { CreatePaymentGql } from "../../modules/GraphQl/Resolvers/Payment/createPayment.gql";
import { useUser } from "../Context/UserProvider";
import CircularProgress from '@mui/material/CircularProgress';

const StripePaymentRedirect = () => {
    const PAYMENT_INTENT = 'payment_intent';
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { gqlHeader } = useUser();
    const { data, loading, error } = useQuery(CreatePaymentGql, {
        variables: {
            id: searchParams.has(PAYMENT_INTENT) ? searchParams.get(PAYMENT_INTENT) : ''
        },
        context: gqlHeader
    });

    useEffect(() => {
        if (data) {
            if (data.createPayment.status) {
                navigate(`/payment/success?id=${data.createPayment.message}`);
            } else {
                navigate('/payment/failed');
            }
        }
    }, [data]);

    return (
        <div className="paymentStatusDiv">
            <CircularProgress/>
        </div>
    );
}//end StripePaymentRedirect()

export default StripePaymentRedirect;
