import { SHA256 } from "crypto-js";
import uuid from "react-uuid";

/**
 * A helper class with packed utilities
 */
export default class Utility {
	/**
	 * Generate random id to be appended to HTMLElement
	 * @returns string
	 */
	randomElementId() {
		return uuid().substring(0, 4);
	} //end randomElementId()

	/**
	 * Escape emojis from provided value
	 * @param value
	 * @returns string
	 */
	escapeEmoji(value: string) {
		return value.replaceAll(
			/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
			""
		);
	} //end escapeEmoji()

	/**
	 * Escape symbols from provided value, accepts letters and numbers ONLY
	 * @param value
	 * @returns string
	 */
	escapeSymbol(value: string) {
		return value.replaceAll(/[^a-z0-9]/gi, "");
	} //end escapeSymbol()

	/**
	 * Escape html entities
	 * @param value
	 * @returns string
	 */
	escapeHtml(value: string) {
		var map = {
			"&": "&amp;",
			"<": "&lt;",
			">": "&gt;",
			'"': "&quot;",
		};
		return value.replace(/[&<>"']/g, function (m) {
			return map[m];
		});
	} //end escapeHtml()

	/**
	 * Decode escaped html entities
	 * @param value
	 * @returns string
	 */
	decodeHtml(value: string) {
		var map = {
			"&amp;": "&",
			"&lt;": "<",
			"&gt;": ">",
			"&quot;": '"',
			"&#39;": "'",
		};
		return value.replace(/(&amp;|&lt;|&gt;|&quot;|&#39;)/g, function (m) {
			return map[m];
		});
	} //end decodeHtml()

	/**
	 * Masked phone into +60xxxxxx89 format
	 * To prevent phone number leaking in frontend
	 * @param phone
	 * @returns string
	 */
	phoneMasker(phone: string) {
		// Remove starting number 60 and last two number (since we are slicing later)
		let phoneLen: number = this.phoneEscaper(phone).length;
		phoneLen = phoneLen >= 0 ? phoneLen : phoneLen - 2;
		return `+60${"x".repeat(phoneLen)}${phone.slice(-2)}`;
	} //end phoneMasker()

	/**
	 * Removes 60 from phone
	 * @param phone
	 * @returns string
	 */
	phoneEscaper(phone: string) {
		if (phone.startsWith("60")) {
			return phone.replace("60", "");
		}
		return phone;
	} //end phoneEscaper()

	/**
	 * Formats date into string (exp: 10 AM)
	 * @param date
	 * @returns string
	 */
	localeDateFormatter(date: Date) {
		return new Date(date).toLocaleString("en-US", {
			hour: "numeric",
			hour12: true,
		});
	} //end localeDateFormatter()

	/**
	 * Formats date into dd MM YYYY H:i a.m./p.m. format
	 * @param date
	 * @returns string
	 */
	dateFormatter(date: Date) {
		return new Date(date).toLocaleString("en-US", {
			hour: "numeric",
			minute: "numeric",
			day: "numeric",
			month: "long",
			year: "numeric",
			hour12: true,
		});
	} //end dateFormatter()

	/**
	 * Add trailing zero for the price before submitting
	 * @param price
	 * @returns string
	 */
	preprocessPrice(price: string) {
		if (!price.includes(".")) {
			return `${price}.00`;
		}
		return price;
	} //end preprocessPrice()

	/**
	 * Hashes filename with current datetime
	 * @param fileName
	 * @param fileType
	 * @returns string
	 */
	fileNameHasher(fileName: string, fileType: string) {
		if (fileType.includes('image/')) {
			fileType = fileType.replace('image/', '');
		}
		return `${SHA256(`${fileName}-${new Date()}`).toString().substring(0, 15)}.${fileType}`;
	} //end fileNameHasher()

	/**
	 * Validates if input string is a valid url
	 * @param url
	 * @returns boolean
	 */
	isUrl(url: any) {
		if (!url || url.match(/\s/g)) {
			return false;
		}
		try {
			return Boolean(new URL(url));
		} catch (e) {
			return false;
		}
	} //end isUrl()

	/**
	 * Data grid's custom price comparator
	 * @param a
	 * @param b
	 * @returns
	 */
	dataGridPriceComparator = (a: any, b: any) => {
		return parseFloat(a) - parseFloat(b);
	}; //end dataGridPriceComparator()

	/**
	 * Validates if current user's device is online or offline
	 * @returns boolean
	 */
	isDeviceOnline = () => {
		return navigator.onLine ? true : false;
	}; //end isDeviceOnline()

	/**
	 * Capitalized the first word
	 * @returns String
	 */
	capitalizedWord = (text: String) => {
		return (text = text.charAt(0).toUpperCase() + text.slice(1));
	}; //end capitalizedWord()

	/**
	 * Create unique bearer token for header
	 * @param string 
	 * @returns string
	 */
	createUniqueBearerToken = (string: string) => {
		return SHA256(`beauvie-frontend-auth-bearer-${string}`).toString()
	}//end createUniqueBearerToken()

	/**
	 * Create urlKey Replace "" to "-"
	 * @param string 
	 * @returns string
	 */
	urlKeyCreation = (string: String) => {
        const temp = string.replace(/\s/g, "-");
        return temp;
    }//end urlKeyCreation()

	/**
	 * Modify capacity if there's a "," inside the string
	 * @param string
	 * @returns string
	 */
	modifyCapacity = (string: String) => {
		return string.replaceAll(",", " / ");
	} //end modifyCapacity()

	/* 
	 * Modify Price if there's a "," inside the string
	 * @param string
	 * @returns string
	*/
	modifyPrice = (string: String) => {
		let newPrice: string[] = [];
		string.split(",").forEach((price: string) => {
			newPrice.push(`RM ${price}`);
		});
		return newPrice.toString().replaceAll(",", " / ");
	} //end modifyPrice()

} //end class
