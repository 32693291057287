import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import QrCodeReader, { QRCode } from 'react-qrcode-reader';
import { UpdateBookingItemByIdGql } from '../../../modules/GraphQl/Resolvers/Booking/updateBookingItemById.gql';
import { useHelper } from '../../Context/HelperProvider';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import './QrCodeScanner.scss';
import ActionButton from '../../Common/ActionButton/ActionButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAdmin } from '../../Context/AdminProvider';
import { CircularProgress } from '@mui/material';

const QrCodeScanner = (props: any) => {
    const {
        onCompleted
    } = props;
    const [ result, setResult ] = useState(''); 
    const [ facingMode, setFacingMode ] = useState('user');
    const [ canSwitchCam, setCanSwitchCam ] = useState(false);
    const [ isScanning, setIsScanning ] = useState(false);
    const [ message, setMessage ] = useState("");
    const { cryptor } = useHelper();
    const { gqlHeader } = useAdmin();

    const [ UpdateBookingItem, { data, loading, error } ] = useMutation(UpdateBookingItemByIdGql);

    useEffect(() => {

        const getHasTwoCameras = async () => {
            setCanSwitchCam(await hasTwoCameras());
        }
        
        getHasTwoCameras();
    }, []);

    useEffect(() => {
        if (data) {
            if (data.updateBookingItemById.status) {
                onCompleted();
            } else {
                // setIsScanning(false);
                if (data.updateBookingItemById.message === 'QR code has been scanned!') {
                    setMessage(data.updateBookingItemById.message);
                } else {
                    setMessage('An error has occured. Please refresh the page and try again.');
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (result) {
            let { bookingItemId, parentId } = JSON.parse(cryptor.decrypt(result));
            setIsScanning(true);
            updateBookingItem(bookingItemId, parentId);
        }
    }, [result]);

    const updateBookingItem = async (bookingItemId: string, parentId: string) => {
        setMessage('');
        await UpdateBookingItem({
            variables: {
                id: bookingItemId,
                data: cryptor.encrypt(JSON.stringify({ status: 'confirmed', parentId: parentId, checkQr: true }))
            },
            context: gqlHeader
        })
    }

    const onFacingModeChange = () => {
        setFacingMode(facingMode === 'user' ? 'environment' : 'user');
    }

    const hasTwoCameras = async () => {
        const devices: any[] = await navigator.mediaDevices.enumerateDevices();
        const cameras: any[] = devices.filter((device: MediaDeviceInfo) => { return device.kind === 'videoinput' });
        if (cameras.length > 1) {
            return true;
        }
        return false;
    }

    return (
        <>
            <div className='qrCodeScannerDiv'>
                <div className='qrCodeScannerContDiv'>
                    {
                        isScanning && <div className='qrCodeScannerScanning'><CircularProgress/></div>
                    }
                    <CloseIcon fontSize='large' className='closeIcon' onClick={onCompleted}/>
                    <QrCodeReader delay={100} action={setResult} width={1080} height={1920} 
                        videoConstraints={{
                            facingMode: { exact: facingMode }
                        }}
                    />
                    <div className='qrCodeScannerActionDiv'>
                        {
                            message &&
                            <span className='qrCodeScannerMessage'>
                                {message}
                            </span> 
                        }
                        {
                            canSwitchCam &&
                            <ActionButton
                                label={'Switch Camera'} 
                                title={'Click to switch camera'} 
                                icon={<CameraswitchIcon/>}
                                isDisable={false}
                                onClick={onFacingModeChange}                            
                                canRateLimit={true}
                                rateLimitCount={10}
                                rateLimitFor={3} 
                                styling={'icon-transparent'}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}//end QrCodeScanner()

export default QrCodeScanner;
