import { DefaultContext } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { UserInterface } from "../../modules/Api/Data/DocumentInterface";
import { KEY_USER_SESSID } from "../../modules/Session/Api/Data/ConstantInterface";
import { KEY_UID, KEY_USER_DATA } from "../../modules/Storage/Api/ConstantInterface";
import { useHelper } from "./HelperProvider";

const UserContext = React.createContext<any>(null);

export const useUser = () => {
    return useContext(UserContext);
}

const UserProvider = ({ children }) => {
    const { cryptor, session, storage, utility } = useHelper();
    const [ user, setUser ] = useState<UserInterface>({});
    const [ uid, setUid ] = useState(storage.getItem(KEY_UID) ?? '');
    const [ bearerToken, setBearerToken ] = useState('');

    useEffect(() => {
        if (storage.hasItem(KEY_UID)) {
            setUid(storage.getItem(KEY_UID));
        }

        if (storage.hasItem(KEY_USER_DATA)) {
            updateUser(JSON.parse(storage.getItem(KEY_USER_DATA)));
        }
    }, []);

    useEffect(() => {
        // Bearer Token is divided into two parts (divided by :)
        // First part is encrypted user id
        // Second part is hashed bearer token included SALT and user id
        // Backend will hash first part and match it with second part
        setBearerToken(`${cryptor.encrypt(uid)}:${utility.createUniqueBearerToken(uid ?? '')}`);
    }, [uid]);

    const updateUser = (user: UserInterface) => {
        setUser({
            ...user,
            username: user.username,
            phone: user.phone
        });
        storage.setItem(KEY_UID, user.id);
        storage.setItem(KEY_USER_DATA, JSON.stringify(user));
        session.setSessionItem(KEY_USER_SESSID, bearerToken);
    }//end updateUser()

    const userLogin = (user: UserInterface) => {
        updateUser(user);
    }//end userLogin()

    const userLogout = () => {
        setUser({});
        setUid('');
        storage.deleteItem(KEY_UID);
        storage.deleteItem(KEY_USER_DATA);
        session.removeSessionItem(KEY_USER_SESSID);
    }//end userLogout()

    const isUserLoggedIn = () => {
        if (storage.hasItem(KEY_UID) && storage.hasItem(KEY_USER_DATA)) {
            return true;
        }
        return false;
    }//end isUserLoggedIn()

    const createNewBearerToken = () => {
        let result = `${cryptor.encrypt(uid)}:${utility.createUniqueBearerToken(uid ?? '')}`;
        setBearerToken(result);
        session.setSessionItem(KEY_USER_SESSID, result);
        return result;
    }//end createNewBearerToken()

    const constructGqlHeader = {
        headers: {
            authorization: `Bearer ${bearerToken === '' ? createNewBearerToken() : bearerToken}`
        }
    }//end constructGqlHeader()

    return (
        <UserContext.Provider value={{
            user: user,
            uid: uid,
            bearerToken: bearerToken === '' ? createNewBearerToken() : bearerToken,
            gqlHeader: constructGqlHeader,
            setUser: updateUser,
            isUserLoggedIn: isUserLoggedIn,
            login: userLogin,
            logout: userLogout,
            isVerified: user.isVerified
        }}>
            {children}
        </UserContext.Provider>
    );
}//end UserProdiver()

export default UserProvider;
