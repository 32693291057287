import "./Testimonial.scss";
import TestimonialList from "./TestimonialList/TestimonialList";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GetAllTestimonialImage } from "../../modules/GraphQl/Resolvers/Testimonial/getAllTestimonialImage.gql";

const Testimonial = () => {

    const { data } = useQuery(GetAllTestimonialImage);

    const [testimonial, setTestimonial] = useState<any>([]);

    useEffect(() => {
        if (data) {
            if (data.getAllTestimonialImage) {
                setTestimonial([...data.getAllTestimonialImage]);
            }
        }
    }, [data]);

    return (
        <div className="testimonialContainer">
            <div className="testimonialDiv" id="testimonialDiv">
                <div className="testimonialContent">
                    <div className="testimonialTitle"> 
                        <Typography variant="h2">Results Proven</Typography>
                        <p>
                            Scalp image & result photo gallery of 
                            <br />
                            Before & After treatment according to different types of scalp problem.
                        </p>
                    </div>
                    <div className="testimonialGrid">
                        {testimonial.map((item, index) => {
                            return (
                                <TestimonialList key={index} {...item} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial;