import CopyrightIcon from '@mui/icons-material/Copyright';
import { Toolbar } from '@mui/material';
import "./Copyright.scss";

const Copyright = () => {

    let currentDate = new Date().getFullYear();

    return (
        <Toolbar className="copyright-wrapper" sx={{ p: 1.5, justifyContent: "center", alignItems: "center", background: "#FFFBF4" }}>
            <span className='copyright-words'>Copyright&nbsp;<CopyrightIcon />&nbsp; 2023 - {currentDate} Beauvie Hair Care 201903223642 (003005630-A). All Rights Reserved. </span>
        </Toolbar>
    )
}

export default Copyright;