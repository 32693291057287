import "./Hero.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GetBannerForCustomer } from "../../modules/GraphQl/Resolvers/Banner/getBannerForCustomer.gql";

const Hero = () => {

	const { data, refetch } = useQuery(GetBannerForCustomer, {
		fetchPolicy: "cache-and-network"
	});

	const [ banner, setBanner ] = useState<any>([]);
	
	const settings = {
		dots: true,
		infinite: true,
		pauseOnHover: true,
		// autoplay: true,
		speed: 2000,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		customPaging: (i) => (
			<div className="sliderNavigation">
				<div className="navButton" id={i}></div>
			</div>
		),
	};

	useEffect(() => {
		if (data) {
			if (data.getBannerForCustomer) {
				setBanner([...data.getBannerForCustomer]);
			}
		}
	}, [data]);

	return (
		<div className="container">
			<div className="heroBannerDiv">
				<Slider {...settings}>
					{banner.map((item, index) => {
						return (
							<div key={index} className="heroBannerDiv">
								<img src={item.images[0].src} className="heroBannerImg" alt="banner" />
								<section className="heroContentSection">
									<div className="heroContentDiv">
										<div className="heroContentTitle">
											<span>{item.title}</span>
										</div>
										<p>
											{item.description}
										</p>
									</div>
								</section>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default Hero;
