import ActionButton from "../ActionButton/ActionButton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsappButton = (props: any) => {
    const { 
        label, 
        title,
        contact 
    } = props;
    return (
        <ActionButton 
            label={label ?? 'Start Chat'} 
            title={title ?? 'Click to chat with admin.'} 
            isDisable={false}
            icon={<WhatsAppIcon/>}
            onClick={() => {window.open(`https://wa.me/${contact ?? '60169650026'}`, '_blank')}}
            canRateLimit={true}
            rateLimitCount={10}
            rateLimitFor={3}
            styling={'whatsapp'}
        />
    );
}//end WhatsappButton()

export default WhatsappButton;
