import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import Cryptor from "../../../modules/Cryptor/Core/Cryptor";
import UserInterface from "../../../modules/Firebase/Api/Data/UserInterface";
import { IsUserExistGql } from "../../../modules/GraphQl/Resolvers/User/isUserExist.gql";
import { UpdateUserByIdGql } from "../../../modules/GraphQl/Resolvers/User/updateUserById.gql";
import Utility from "../../../modules/Utility/Utility";
import ActionButton from "../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../Common/BasicTextInput/BasicTextInput";
import { useUser } from "../../Context/UserProvider";
import MyAccountTitle from "../MyAccountTitle/MyAccountTitle";
import './MyAccountPersonal.scss';

const MyAccountPersonal = () => {
    const cryptor: Cryptor = new Cryptor();
    const utility: Utility = new Utility();
    const [ isDisable, setIsDisable ] = useState(true);
    const { user } = useUser();
    const [ updateUser, { data: updateData, } ] = useMutation(UpdateUserByIdGql);
    const [ isUserExist, { data: existData,  } ] = useMutation(IsUserExistGql);
    const [ formData, setFormData ] = useState<any>({});
    const [ validateData, setValidateData ] = useState<UserInterface>({
        username: '',
        phone: ''
    });
    const [ fieldsToBeUpdated, setFieldsToBeUpdated ] = useState<string[]>([]);

    useEffect(() => {
        if (user) {
            setFormData({...user});
        }
    }, [user]);

    useEffect(() => {
        if (updateData) {
            setIsDisable(true);
        }
    }, [updateData]);

    useEffect(() => {
		if (existData) {
            if (existData.isUserExist.username !== '' || existData.isUserExist.phone !== '') {
                setValidateData({...existData.isUserExist});
                setIsDisable(false);
            } else {
                onUpdateUser();
                setValidateData({username: '', phone: ''});
            }
        }
	}, [existData]);

    useEffect(() => {
        if (fieldsToBeUpdated.length > 0) {
            setIsDisable(validateFormData());
        }
    }, [formData]);

    const onChange = (value: any, identifier: any) => {
        setFormData({
            ...formData,
            [identifier]: value
        });
        updateFieldsToBeUpdated(identifier);
    }

    const validateFormData = () => {
        if (formData.username.length <= 0) {
            return true;
        }
        if (formData.phone.length <= 0) {
            return true;
        }
        return false;
    }

    const updateFieldsToBeUpdated = (field: string) => {
		if (!fieldsToBeUpdated.includes(field)) {
			fieldsToBeUpdated.push(field);
		}
		setFieldsToBeUpdated([...fieldsToBeUpdated]);
	};

    const onUpdateUser = async () => {     
        await updateUser({
            variables: {
                id: user.id,
                data: cryptor.encrypt(JSON.stringify({
                    ...formData,
                    phone: `60${utility.phoneEscaper(formData.phone)}`
                }))
            }        
        });
    }

    const onValidateUser = async () => {
        let username: string = '';
        let phone: string = '';
        if (formData.username && formData.username !== user.username) {
            username = formData.username;
        }
        if (formData.phone && `60${utility.phoneEscaper(formData.phone)}` !== user.phone) {
            phone = `60${utility.phoneEscaper(formData.phone)}`;
        }
        await isUserExist({
            variables: {
                username,
                phone
            }
        });
    }

    return (
        user && <div className="myAccountPersonal">
            <MyAccountTitle title='Personal Information'/>
            <table className="myAccountPersonalTable">
                <tbody>
                    <tr className="myAccountPersonalTableRow">
                        <td className="myAccountPersonalTableRowTitle">
                            Username
                        </td>
                        <td className="myAccountPersonalTableRowCont">
                            <BasicTextInput 
                                identifier='username'
                                label="" 
                                isRequired={true}
                                canShowError={validateData.username?.length !== 0 ? true : false}
                                errorText={validateData.username !== '' ? validateData.username : "Username is required."}
                                maxLength={15}
                                isAutocomplete={false}
                                onChange={onChange}
                                value={user.username}
                                tabIndex={1}
                            />                        
                        </td>
                    </tr>
                    <tr className="myAccountPersonalTableRow">
                        <td className="myAccountPersonalTableRowTitle">
                            Phone
                        </td>
                        <td className="myAccountPersonalTableRowCont">
                            {
                                user && user.phone && 
                                <BasicTextInput 
                                    identifier='phone'
                                    label="" 
                                    isRequired={true}
                                    canShowError={validateData.phone?.length !== 0 ? true : false}
                                    errorText={validateData.phone !== '' ? validateData.phone : "Phone number is required."}                                    isNumber={true}
                                    inputType={"phone"}
                                    prefix={"+60"}
                                    minLength={9}
                                    maxLength={10}
                                    isAutocomplete={true}
                                    onChange={onChange}
                                    value={utility.phoneEscaper(user.phone)}
                                    tabIndex={2}
                                />  
                            }                    
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <ActionButton 
                                label={'Update'} 
                                isDisable={isDisable}
                                title={'Click to update your personal information.'} 
                                onClick={onValidateUser}
                                canRateLimit={true}
                                rateLimitCount={10}
                                rateLimitFor={3}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}//end MyAccountPersonal()

export default MyAccountPersonal;
