import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import BookingCleanup from "../BookingCleanup/BookingCleanup";

const SharedLayout = () => {  
    return (
        <>        
            <BookingCleanup/>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default SharedLayout;