import "./TreatmentList.scss";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetTreatmentsForCustomerGql } from "../../../modules/GraphQl/Resolvers/Treatment/getTreatmentsForCustomer.gql";
import { KEY_CUSTOMER_TREATMENTS } from "../../../modules/Storage/Api/ConstantInterface";
import TreatmentInterface from "../../../modules/Treatment/Api/Data/TreatmentInterface";
import SingleTreatment from "../SingleTreatment/SingleTreatment";
import { useHelper } from "../../Context/HelperProvider";

const TreatmentList = () => {

    const [treatmentList, setTreatmentList] = useState<TreatmentInterface[]>([]);
    const { storage } = useHelper();
    const location = useLocation();

    const { data } = useQuery(GetTreatmentsForCustomerGql, {
		fetchPolicy: "cache-and-network",
		skip: storage.hasItem(KEY_CUSTOMER_TREATMENTS),
	});

    useEffect(() => {
		if (storage.hasItem(KEY_CUSTOMER_TREATMENTS)) {
			let treatments: any = JSON.parse(storage.getItem(KEY_CUSTOMER_TREATMENTS));
			setTreatmentList([...treatments]);
		}
	}, []);

    useEffect(() => {
        if (data) {
            if (data.getTreatmentsForCustomer) {
                setTreatmentList([...data.getTreatmentsForCustomer]);
                storage.setItem(
					KEY_CUSTOMER_TREATMENTS,
					JSON.stringify(data.getTreatmentsForCustomer)
				);
            }
        }
    }, [data])

    return (
        <div className={`treatmentContainer ${location.pathname.split("/").pop() === "treatments" ? "treatmentListPage" : ""} `}>
            {location.pathname.split("/").pop() === "treatments" ? "" : <p>Treat Your Hair Better</p>}
            <div className="treatmentDiv">
                {treatmentList.map((item, index) => {
                    return (
                        <SingleTreatment {...item} key={index} index={index} />
                    )
                })}
                
            </div>
        </div>
    )
}

export default TreatmentList;