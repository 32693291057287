import "../../ActionAdmin.scss";
import { useMutation } from "@apollo/client";
import { useAdmin } from "../../../../Context/AdminProvider";
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { CreateFaqClass } from "../../../../../modules/GraphQl/Resolvers/Faq/createFaqClass.gql";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";

const AdminAddFaqClass = () => {
	const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

    const [ formData, setFormData ] = useState<any>({
        title: "",
    });

    const [ createFaqClass, { data } ] = useMutation(CreateFaqClass);
    const [ isDisable, setIsDisable ] = useState(true);

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		setIsDisable(validateFormData());
	};

    const validateFormData = () => {
		if (formData.title.length <= 0) {
			return true;
		}
		return false;
	};

	const onSubmit = async () => {
		await createFaqClass({
			variables: {
				...formData,
			},
			context: gqlHeader
		});
	};

    useEffect(() => {
        if (data) {
            if (data.createFaqClass.status) {
                const navigateTimer = setTimeout(() => {
                    navigate(`/${BACKOFFICE_URL}/content/class-faq`);
                    return () => clearTimeout(navigateTimer);
                }, 1000);
            }
        }
    }, [data])

    return (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Add New FAQ Class</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={0}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
    )
};

export default AdminAddFaqClass;