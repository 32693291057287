import "./HomeProducts.scss";
import ProductInterface from "../../../modules/Product/Api/Data/ProductInterface";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { useQuery } from "@apollo/client";
import { GetAllProducts } from "../../../modules/GraphQl/Resolvers/Product/getAllProducts.gql";

const HomeProducts = () => {

    const [ homeProduct, setHomeProduct ] = useState<ProductInterface[]>([]);
    const { data } = useQuery(GetAllProducts);

    useEffect(() => {
        if (data) {
            if (data.getAllProducts) {
                setHomeProduct([...data.getAllProducts]);
            }
        }
    }, [data])

    return (
        <div className="homeProductsContainer">
            <div className="homeProductsDiv">
                <Typography variant="h2">Featured Products</Typography>
                <div className="productGrid">
                    {homeProduct.slice(0, 3).map((product) => {
                        return (
                            <Link to={`/products/${product.id}`} className="product-items" key={product.id}>
                                <div className="product">
                                    <div className="productImgDiv" style={{backgroundImage: `url(${product.thumbnail[0].src})`}}>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
                <Button variant="contained" href="/products" className="viewMoreButton">View More</Button>
            </div>
        </div>
    )
}

export default HomeProducts;