import { useEffect, useState } from "react";
import BasicTextInput from "../../../../../Common/BasicTextInput/BasicTextInput";
import "./AdminProductVariation.scss";

const AdminProductVariationItem = (props: any) => {

    const {
        index,
        price,
        capacity,
        onItemChange
    } = props;

    const [variantData, setVariantData] = useState<any>({
		capacity: capacity,
		price: price,
	});

    const onChange = (value: string, identifier: string) => {
		variantData[identifier] = value;
		setVariantData({...variantData});
	}

    useEffect(() => {
        onItemChange(variantData, index);
    }, [variantData]);

    return (
        <div className="adminProductVariationItem">
            <span>
                Variation {index+1} 
                { variantData.capacity.length > 0 && variantData.price.length > 0 ? ` (${variantData.capacity} - RM ${variantData.price})` : null }
            </span>
            <div>
                <BasicTextInput 
                    identifier='capacity'
                    isRequired={true}
                    inputType={'capacity'}
                    canShowError={variantData.capacity.length > 0 ? false : true}
                    errorText="Capacity is required."
                    isAutocomplete={false}
                    onChange={onChange}
                    value={variantData.capacity}
                    tabIndex={2}
                />
                <BasicTextInput 
                    identifier='price'
                    isRequired={true}
                    inputType={'price'}
                    prefix={'RM'}
                    canShowError={variantData.price.length > 0 ? false : true}
                    errorText="Price is required."
                    isAutocomplete={false}
                    onChange={onChange}
                    value={variantData.price}
                    tabIndex={3}
                />
            </div>
        </div>
    );
}//end AdminProductVariationItem()

export default AdminProductVariationItem;
