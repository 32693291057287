import { gql } from "@apollo/client";

const DELETE_FAQ_BY_ID = gql`
	mutation deleteFaqById($id: String!) {
		deleteFaqById(id: $id) {
			status
			message
		}
	}
`;

export const DeleteFaqById = DELETE_FAQ_BY_ID;