import { gql } from "@apollo/client";

const REMOVE_BANNER_BY_ID = gql`
	mutation removeBannerById($id: String!) {
		removeBannerById(id: $id) {
			status
			message
		}
	}
`;

export const RemoveBannerById = REMOVE_BANNER_BY_ID;