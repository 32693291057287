import { gql } from "@apollo/client";

const GET_ALL_FAQ = gql`
	query getAllFaq {
		getAllFaq {
			id
			title
			description
            class
            createdAt
		}
	}
`;

export const GetAllFaq = GET_ALL_FAQ;