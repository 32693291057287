import { gql } from "@apollo/client";

const UPDATE_CORE_CONFIG = gql`
  mutation updateCoreConfig($data: String!) {
    updateCoreConfig(data: $data) {
      status
      message
    }
  }
`;

export const UpdateCoreConfigGql = UPDATE_CORE_CONFIG;
