import { Accordion, AccordionDetails, AccordionSummary, Alert, Fade, IconButton, Snackbar } from '@mui/material';
import statusMapper from '../../../../modules/Booking/Model/StatusMapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './MyAccountBookingItem.scss';
import { useEffect, useState } from 'react';
import ActionButton from '../../../Common/ActionButton/ActionButton';
import Timer from '../../../Common/Timer/Timer';
import { ReactComponent as RingBell } from '../../../../assets/images/ringbell.svg';
import BookingItemInterface from '../../../../modules/Booking/Api/Data/BookingInterface';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { CancelBookingByIdGql } from '../../../../modules/GraphQl/Resolvers/Booking/cancelBookingById.gql';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Modal from '../../../Common/Modal/Modal';
import ModalInterface from '../../../Common/Modal/ModalInterface';
import WhatsappButton from '../../../Common/WhatsappButton/WhatsappButton';
import QrCodeGenerator from '../../../QrCode/Generator/QrCodeGenerator';
import { CompareBookingItemStatusGql } from '../../../../modules/GraphQl/Resolvers/Booking/compareBookingItemStatus.gql';
import CloseIcon from '@mui/icons-material/Close';
import { useHelper } from '../../../Context/HelperProvider';
import { useUser } from '../../../Context/UserProvider';

const MyAccountBookingItem = (props) => {
    const {
        id,       
        incrementId,
        status,
        username,
        pid,
        grandTotal,
        grandTotalPaid,
        children,
        createdAt,
        isFailedPayment,
        repaymentTimeout,
        repaymentId
    } = props;
    const { cryptor, utility } = useHelper();
    const { gqlHeader } = useUser();
    const navigate = useNavigate();
    const [ isExpanded, setIsExpanded ] = useState(false);
    const [ isRepaymentExpired, setIsRepaymentExpired ] = useState(false);
    const [ canReschedule, setCanReschedule ] = useState(false);
    const [ CancelBooking, { data } ] = useMutation(CancelBookingByIdGql);
    const [ qrItem, setQrItem ] = useState({
        id: '',
        status: ''
    });
    const { data: compareData, startPolling, stopPolling } = useQuery(
        CompareBookingItemStatusGql,
        {
            variables: {
                id: qrItem.id,
                status: qrItem.status
            },
            skip: qrItem.id === '',
            context: gqlHeader
        }
    );
    const [ snackbar, setSnackbar ] = useState({
        open: false,
        duration: 3000,
        message: "",
        transition: Fade,
    })
    const [ rescheduleModal, setRescheduleModal ] = useState<ModalInterface>({
        isModalActive: false,
        title: 'You will direct to admin for reschedule.',
        content: 
            <div className='myAccountBookingRescheduleModalCont'>
                <span>Please provide details such as:</span>
                <span className='important'>• booking id</span>
                <span className='important'>• booking item id</span>
                <span className='important'>• date</span>
                <span className='important'>• username</span>
                <span className='important'>• phone</span>
                <span><WhatsappButton/></span>
                <span><span className='important'>*Exp</span>: #BHPC00000099, {new Date().toLocaleDateString('en-US', { hour: 'numeric' })}</span>
                <div>
                    <p>
                        <span>*</span>You can only reschedule <span>ONCE</span>. 
                        If rescheduled booking is still not fulfilled, a refund will be created for this booking. 
                        Please note that refund will deduct for <span>RM 50.00 per booking item</span>.
                    </p>
                </div>
            </div>  
    });

    useEffect(() => {
        startPolling(10000);
        return () => stopPolling()
    }, [startPolling, stopPolling]);

    useEffect(() => {
        if (data) {
            if (data.cancelBookingById.status) {
                let path: string = `/user/${cryptor.decrypt(username)}/booking/cancelled`;
                navigate(path, { state: { active: path }});
            }
        }
    }, [data]);

    useEffect(() => {
        if (compareData) {
            if (compareData.compareBookingItemStatus.status) {
                let path: string = `/user/${cryptor.decrypt(username)}/booking/confirmed`;
                navigate(path, { state: { active: path }});
            }
        }
    }, [compareData]);

    useEffect(() => {
        canCustomerReschedule();
    }, []);

    const onRescheduleModalClose = () => {
        setRescheduleModal({...rescheduleModal, isModalActive: false})
    }

    const validateIfChildrenIsRescheduled = () => {
        let isRescheduled: boolean = false;
        
        children.every((item: BookingItemInterface, index: any) => {
            if (item.isRescheduled) {
                isRescheduled = true;
                return false;
            }
            return true;
        });
        return isRescheduled;
    }
    

    const canCustomerReschedule = () => {
        if ([
            'paid',
            'confirmed',
            'absent'
        ].includes(cryptor.decrypt(status)) && !validateIfChildrenIsRescheduled()) {
            setCanReschedule(true);
        }
    }

    const onRetryPayment = () => {
        let finalAmount: any = 0;
        children.map((child: BookingItemInterface) => {
            finalAmount = finalAmount + parseInt(child.treatmentPrice?.replace('.', '') ?? '');
        });
        finalAmount = finalAmount.toString();
        navigate('/payment', { state: { repaymentId: repaymentId, bookingId: incrementId, booking: children, finalAmount: `${finalAmount.slice(0, -2)}.${finalAmount.slice(-2)}` }});
    }

    const onRepaymentExpired = () => {
        setIsRepaymentExpired(true);
    }

    const onCancelPayment = async () => {
        let childrenIds: string[] = children.map((child: BookingItemInterface) => { return child.id });
        await CancelBooking({
            variables: {
                id: id,
                childrenIds: childrenIds,
                paymentId: repaymentId
            },
            context: gqlHeader
        });
    }

    const updateQrItem = (itemId: string, status: string) => {
        setQrItem({
            id: itemId,
            status: status
        });
    }

    const copyToClipboard = (content: any, title: any) => {
        setSnackbar({
            ...snackbar, 
            open: true,
            message: title
        })
        navigator.clipboard.writeText(content);
    }

    const closeSnackbar = () => {
        setSnackbar({
            ...snackbar, 
            open: false,
            message: ''
        })
    }

    const snackbarAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
        >
        <CloseIcon fontSize="small" />
      </IconButton>
    )

    return (
        <div className='myAccountBookingItemDiv'>
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                open={snackbar.open}
                autoHideDuration={snackbar.duration}
                action={snackbarAction}
                onClose={closeSnackbar}
                TransitionComponent={snackbar.transition}
            >
                <Alert onClose={closeSnackbar} severity="success">
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div className='myAccountBookingItemHeader'>
                <div className='myAccountBookingItemId'><div>Booking ID:&nbsp;</div> <span className='copyId' onClick={() => copyToClipboard(`#${cryptor.decrypt(incrementId)}`, 'Booking ID has been copied to clipboard!')}>{ `#${cryptor.decrypt(incrementId)}` }</span></div>
                <div className='myAccountBookingItemStatus'>{ statusMapper(cryptor.decrypt(status)) }</div>
            </div>
            <div className='myAccountBookingItemGridDiv'>
                <div className='myAccountBookingItemTreatmentImgDiv'>
                    <img className='myAccountBookingItemTreatmentImg' src={children[0].treatmentSrc} title={children[0].treatmentName} alt={children[0].treatmentName}/>
                    {
                        canReschedule &&
                        <>
                            <ActionButton 
                                label={'Contact Admin for Reschedule'} 
                                title={'Click to contact admin for rescheduling.'} 
                                isDisable={isRepaymentExpired}
                                icon={<EventRepeatIcon/>}
                                onClick={() => {setRescheduleModal({...rescheduleModal, isModalActive: true})}}
                                canRateLimit={true}
                                rateLimitCount={10}
                                rateLimitFor={3}
                                styling={'icon-transparent'}
                            />
                            <Modal
                                isModalActive={rescheduleModal.isModalActive} 
                                title={rescheduleModal.title}
                                onModalClose={onRescheduleModalClose}
                                showCancelIcon
                                content={rescheduleModal.content}
                            />
                        </>
                    }
                </div>
                <div className='myAccountBookingItemCont'>
                    <table>
                        <tbody>
                            {
                                !isFailedPayment &&
                                <>
                                    <tr>
                                        <td>Booking created at</td>
                                        <td className='myAccountBookingItemContData'>{utility.dateFormatter(new Date(createdAt))}</td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Reference No.</td>
                                        <td className='myAccountBookingItemContData'>{cryptor.decrypt(pid) ? `#${cryptor.decrypt(pid)}` : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Grand Total</td>
                                        <td className='myAccountBookingItemContData'>{`RM ${cryptor.decrypt(grandTotal)}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Paid Amount</td>
                                        <td className='myAccountBookingItemContData'>{`RM ${cryptor.decrypt(grandTotalPaid)}`}</td>
                                    </tr>
                                </>
                            }
                            {
                                isFailedPayment && children &&
                                <>
                                    {
                                        !isRepaymentExpired &&
                                        <tr>
                                            <td className='myAccountBookingItemActionData'>
                                                <div className='bookingItemRepaymentTimerDiv'>
                                                    <span><Timer end={new Date(repaymentTimeout)} isCompleted={onRepaymentExpired}/></span>
                                                    <div className='bookingItemRepaymentInfoDiv'>
                                                        <RingBell/>
                                                        <div>
                                                            <span className='title'>Reminder</span>
                                                            <span className='hint'>Please make your payment within the given timeframe.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                                                
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                            {
                                                isRepaymentExpired ? 
                                                <div className='bookingItemRepaymentHintDiv'>Payment for this booking has expired.</div> :
                                                <div className='bookingItemRepaymentActionDiv'>
                                                <ActionButton 
                                                    label={isRepaymentExpired ? 'Expired' : 'Cancel'} 
                                                    title={isRepaymentExpired ? 'You are not allowed to cancel this booking anymore.' : 'Click to cancel booking.'} 
                                                    isDisable={isRepaymentExpired}
                                                    onClick={onCancelPayment}
                                                    canRateLimit={true}
                                                    rateLimitCount={10}
                                                    rateLimitFor={3}
                                                    styling={'basic-brown-outline'}
                                                />
                                                <span>OR</span>
                                                <ActionButton 
                                                    label={isRepaymentExpired ? 'Expired' : 'Pay Now'} 
                                                    title={isRepaymentExpired ? 'You are not allowed to make payment for this booking anymore.' : 'Click to pay now.'} 
                                                    isDisable={isRepaymentExpired}
                                                    onClick={onRetryPayment}
                                                    canRateLimit={true}
                                                    rateLimitCount={10}
                                                    rateLimitFor={3}
                                                    styling={'basic-brown'}
                                                />
                                            </div> 
                                            } 
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Accordion className='myAccountBookingItemChildren' expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`myAccountBookingItemChildren#${incrementId}`}
                    id={`myAccountBookingItemChildren#${incrementId}`}
                >
                    <span className="myAccountBookingItemChildrenAction">{isExpanded ? 'View Less' : 'View More'}</span>
                </AccordionSummary>
                <AccordionDetails onClick={(e: any) => {e.stopPropagation()}}>
                    <div className='myAccountBookingItemContAccordion'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Booking Item ID</th>
                                    <th>Status</th>
                                    <th>Treatment</th>
                                    <th>Booking Slot</th>
                                    <th>Price</th>
                                    <th>Additional Information</th>
                                </tr>
                                { 
                                    children && children.map((item: BookingItemInterface, index: any) => {
                                        return (
                                            <tr key={index}>
                                                <td><span className='copyId' onClick={() => copyToClipboard(`#${item.incrementId}`, 'Booking Item ID has been copied to clipboard!')}>#{item.incrementId}</span></td>
                                                <td>{statusMapper(item.status)}</td>
                                                <td>
                                                    <div className='myAccountBookingItemChildrenTreatment'>
                                                        <img className='myAccountBookingItemChildrenTreatmentImg' src={item.treatmentSrc} alt={item.treatmentName} title={item.treatmentName}/>
                                                        <span>{item.treatmentName}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {`${new Date(item.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}<br/>
                                                    {`${new Date(item.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(item.end).toLocaleTimeString('en-US', { hour: '2-digit'})}`}
                                                </td>
                                                <td>RM {item.treatmentPrice}</td>
                                                <td>
                                                    {item.cancelReason && `Cancel Reason: ${item.cancelReason}`}
                                                    {item.isRefunded && item.refundedHistory && `Refunded Amount: RM ${item.refundedHistory.amount}`}
                                                    {
                                                        ['rescheduled'].includes(item.status) && item.isRescheduled && item.rescheduledHistory &&                                           
                                                        <>
                                                            Previous timeslot:&nbsp;
                                                            {`${new Date(item.rescheduledHistory.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}<br/>
                                                            {`${new Date(item.rescheduledHistory.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(item.rescheduledHistory.end).toLocaleTimeString('en-US', { hour: '2-digit'})}`}
                                                        </>
                                                    }
                                                    {
                                                        ['paid', 'rescheduled'].includes(item.status) && <>
                                                            <QrCodeGenerator data={{
                                                                id: cryptor.encrypt(JSON.stringify({
                                                                    bookingItemId: item.id,
                                                                    parentId: cryptor.decrypt(id)
                                                                })),
                                                                itemId: item.id,
                                                                status: item.status,
                                                                title: `QR Code for Booking Item #${item.incrementId}`,
                                                                message: 'Please show this QR code to person-in-charge upon arrival for booked treatment.',
                                                                updateQrItem: updateQrItem
                                                            }} />
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}//end MyAccountBookingItem()

export default MyAccountBookingItem;
