import { default as MuiModal } from '@mui/material/Modal';
import './TreatmentSelectionModal.scss';
import ActionButton from '../Common/ActionButton/ActionButton';
import TreatmentInterface from '../../modules/Treatment/Api/Data/TreatmentInterface';
import { useEffect, useState } from 'react';
import { KEY_BOOKING_RESCHEDULE, KEY_BOOKING_TEMPIDS } from '../../modules/Storage/Api/ConstantInterface';
import { useMutation } from '@apollo/client';
import Modal from '../Common/Modal/Modal';
import ModalInterface from '../Common/Modal/ModalInterface';
import BookingInterface from '../../modules/Booking/Api/Data/BookingInterface';
import ListIcon from '@mui/icons-material/List';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useNavigate } from 'react-router-dom';
import { RescheduleBookingGql } from '../../modules/GraphQl/Resolvers/Booking/rescheduleBooking.gql';
import TreatmentDropdown from './TreatmentDropdown/TreatmentDropdown';
import Timer from '../Common/Timer/Timer';
import DeleteIcon from "@mui/icons-material/Delete";
import { useHelper } from '../Context/HelperProvider';
import { useUser } from '../Context/UserProvider';

const TreatmentSelectionModal = (props: any) => {

    const { 
        onSelected,
        booking,
        preSelectedTreatment,
        preSelectedBooking,
        isAdmin,
        onUpdateBooking
    } = props;

    const { storage } = useHelper();
    const navigate = useNavigate();
    const { gqlHeader } = useUser();
    const [ rescheduleBooking, { 
        data: rescheduleBookingData, 
    }] = useMutation(RescheduleBookingGql);
    const [ modal, setModal ] = useState<ModalInterface>({
        isModalActive: false,
        action: {
            label: 'Confirm selection',
            title: 'Click to confirm selection.'
        }
    });
    const [ bookingModal, setBookingModal ] = useState<ModalInterface>({
        isModalActive: false,
        action: {
            label: 'Confirm selection',
            title: 'Click to confirm selection.'
        }
    });

    const [ rescheduleModal, setRescheduleModal ] = useState<ModalInterface>({
        isModalActive: false,
        action: {
            label: 'Reschedule',
            title: 'Click to confirm reschedule.'
        }
    });

    const [ selectedTreatment, setSelectedTreatment ] = useState<TreatmentInterface>();
    const [ futureTreatment, setFutureTreatment ] = useState<TreatmentInterface>();

    const onUpdateSelection = () => {
        setSelectedTreatment(futureTreatment);
        setModal({...modal, isModalActive: false});
        onSelected(futureTreatment);
    }

    useEffect(() => {
        if (preSelectedTreatment) {
            setSelectedTreatment(preSelectedTreatment);
            setFutureTreatment(preSelectedTreatment);
            onSelected(preSelectedTreatment);
        }
    }, []);

    useEffect(() => {
        if (rescheduleBookingData) {
            if (rescheduleBookingData.rescheduleBooking.status) {
                storage.deleteItem(KEY_BOOKING_RESCHEDULE);
                navigate(-1);
            }
        }
    }, [rescheduleBookingData]);

    const onActionClick = () => {
        setModal({...modal, isModalActive: true})
    }

    const onProceedPayment = () => {
        storage.deleteItem(KEY_BOOKING_TEMPIDS);
        navigate('/payment', { state: { bookingIds: booking.map((book: BookingInterface) => { return book.bid})}});
    }

    const onReschedule = () => {
        setRescheduleModal({...rescheduleModal, isModalActive: true})
    }

    const onConfirmReschedule = async () => {
        await rescheduleBooking({
            variables: {
                id: preSelectedBooking.id,
                parentId: preSelectedBooking.parentId,
                bid: booking[0].bid,
                start: booking[0].start,
                end: booking[0].end
            },
            context: gqlHeader
        })
    }

    const onTreatmentSelected = (treatment: TreatmentInterface) => {
        setFutureTreatment(treatment);
    }

    const isExpired = (id: any) => {
        setBookingModal({...bookingModal, isModalActive: false})
        onUpdateBooking(id);
    }

    const disableTreatmentSelection = () => {
        if (!selectedTreatment && !futureTreatment) {
            return true;
        }
        if (!selectedTreatment && futureTreatment) {
            return false;
        }
        if (selectedTreatment && futureTreatment && selectedTreatment.id === futureTreatment.id) {
            return true;
        }
        return false;
    }

    return (
        <MuiModal
            className={`treatmentSelectionModalDiv`}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            hideBackdrop={selectedTreatment ? true : false}
            disableScrollLock
      >
        <>
            {
                selectedTreatment ? 
                <div className='treatmentSelectionContDiv'>
                    <div className='treatmentSelectionFinalizeDiv'>
                        <div className='treatmentSelectionImgDiv'>
                            {
                                selectedTreatment && selectedTreatment.images && 
                                <img src={selectedTreatment?.images[0].src} alt="selectedTreatmentImage"/>
                            }
                        </div>
                        <div className='treatmentSelectionInfoDiv'>
                            <span><span className='treatmentSelectedTitle'>{selectedTreatment?.title}</span></span>
                            <span>RM {selectedTreatment?.price}</span>
                        </div> 
                    </div>
                    {
                        !isAdmin && 
                        <>
                            <div className='treatmentSelectionBookingDiv'>
                                <ActionButton
                                    label={'Reserved Bookings'} 
                                    title={'Reserved Bookings'} 
                                    icon={<BookmarksIcon/>}
                                    isDisable={booking.length === 0 ? true : false}
                                    onClick={() => {
                                        if (booking.length > 0) {
                                            setBookingModal({...bookingModal, isModalActive: true})
                                        }}
                                    }                            
                                    canRateLimit={true}
                                    rateLimitCount={10}
                                    rateLimitFor={3} 
                                    styling={'icon-transparent'}
                                />
                            </div>
                            <div className='treatmentSelectionActionDiv'>
                                <ActionButton
                                    label={'Reselect'} 
                                    title={'Reselect'} 
                                    icon={<ListIcon/>}
                                    isDisable={false}
                                    onClick={() => onActionClick()}
                                    canRateLimit={true}
                                    rateLimitCount={10}
                                    rateLimitFor={3} 
                                    styling={'icon-transparent'}
                                />
                            </div>
                        </>
                    }
                    {
                        isAdmin && 
                        <>
                            <div></div>
                            <div className='treatmentSelectionActionDiv'>
                                <ActionButton
                                    label={'Reschedule'} 
                                    title={'Click to confirm reschedule'} 
                                    icon={<MoveDownIcon/>}
                                    isDisable={booking.length === 0 ? true : false}
                                    onClick={() => {
                                        if (booking.length > 0) {
                                            onReschedule()
                                        }}
                                    }  
                                    canRateLimit={true}
                                    rateLimitCount={10}
                                    rateLimitFor={3} 
                                    styling={'icon-transparent'}
                                />
                            </div>
                        </>
                    }
                </div> : 
                <div className='treatmentSelectionContDiv treatmentSelectionFullwidthDiv'>
                    <div className='treatmentSelectionActionDiv'>
                        <ActionButton
                            label={'Select treatment'} 
                            title={'Click to select treatment.'} 
                            isDisable={false}
                            onClick={() => onActionClick()}
                            canRateLimit={true}
                            rateLimitCount={10}
                            rateLimitFor={3} 
                            styling={'basic-brown'}
                        />
                    </div>
                </div>
            }
            <Modal 
                isModalActive={modal.isModalActive}
                onModalClose={() => {setModal({...modal, isModalActive: false})}}
                title={'Please select your desired treatment.'}
                content={
                    <div className='treatmentDropdownDiv'>
                        <TreatmentDropdown preSelected={JSON.stringify(selectedTreatment)} onSelected={onTreatmentSelected}/>
                        { selectedTreatment?.title && <span>Previously, you have selected: <span>{selectedTreatment?.title}</span></span> }
                        <ActionButton
                            label={'Confirm Selection'} 
                            title={'Click to confirm selection.'} 
                            isDisable={disableTreatmentSelection()}
                            onClick={() => onUpdateSelection()}
                            canRateLimit={true}
                            rateLimitCount={10}
                            rateLimitFor={3} 
                            styling={'basic-brown'}
                        />
                    </div>
                }
                showCancelButton={false}
                showCancelIcon={true}
            />
            <Modal 
                isModalActive={bookingModal.isModalActive}
                onModalClose={() => {setBookingModal({...bookingModal, isModalActive: false})}}
                title={'Your reserved booking(s)'}
                content={
                    <div>
                        <div className='tempBookingTableDiv'>
                        <table className='tempBookingTable'>
                            <tbody>
                                <tr>
                                    <th>Treatment</th>
                                    <th>Selected timeslot</th>
                                    <th>Expired in</th>
                                    <th>Remove</th>
                                </tr>
                            {
                                booking && booking.length > 0 ?
                                booking.map((tempBooking: BookingInterface) => {
                                    return (
                                        <tr key={tempBooking.bid}>
                                            <td><div className='tempBookingDiv'><img src={tempBooking.treatmentSrc} title={tempBooking.treatmentName} alt={tempBooking.treatmentName}/>{tempBooking.treatmentName}</div></td>
                                            <td>
                                                {`${new Date(tempBooking.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}<br/>
                                                {`${new Date(tempBooking.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(tempBooking.end).toLocaleTimeString('en-US', { hour: '2-digit'})}`}
                                            </td>
                                            <td className='tempBookingTimerDiv'><Timer end={new Date(tempBooking.expiredAt)} isCompleted={isExpired} extra={tempBooking.bid}/></td>
                                            <td className='tempBookingDelete'><DeleteIcon className='tempBookingDeleteIcon' onClick={() => {isExpired(tempBooking.bid)}} /></td>
                                        </tr>
                                    );
                                }) : null
                            }
                            </tbody>
                        </table>
                        </div>
                        <span className='tempBookingInfo'>You can only make up to 3 reserved booking(s) at a single time. Once you leave the page, your reserved booking(s) will be resetted.</span>
                    </div>
                }
                action={
                    {
                        label: 'Proceed to payment',
                        title: 'Click to proceed to payment',
                        onClick: onProceedPayment,
                        isDisable: booking.length === 0 ? true : false
                    }
                }
                showCancelButton={false}
                showCancelIcon={true}
            />
            {
                isAdmin &&
                <Modal 
                    isModalActive={rescheduleModal.isModalActive}
                    onModalClose={() => {setRescheduleModal({...rescheduleModal, isModalActive: false})}}
                    title={'Reschedule details'}
                    content={
                        <div>
                            <table className='tempBookingTable'>
                                <tbody>
                                    <tr>
                                        <th>Previous timeslot</th>
                                        <th>New timeslot</th>
                                    </tr>
                                {
                                    booking && booking.length > 0 && preSelectedBooking ?
                                    booking.map((tempBooking: BookingInterface) => {
                                        return (
                                            <tr key={tempBooking.bid}>
                                                <td>
                                                    {`${new Date(preSelectedBooking.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}<br/>
                                                    {`${new Date(preSelectedBooking.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(preSelectedBooking.end).toLocaleTimeString('en-US', { hour: '2-digit'})}`}
                                                </td>                                            
                                                <td>
                                                    {`${new Date(tempBooking.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}<br/>
                                                    {`${new Date(tempBooking.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(tempBooking.end).toLocaleTimeString('en-US', { hour: '2-digit'})}`}
                                                </td>
                                            </tr>
                                        );
                                    }) : null
                                }
                                </tbody>
                            </table>
                            <span className='tempBookingInfo'>Once rescheduled, previous timeslot will be available for other customer(s).</span>
                        </div>
                    }
                    action={
                        {
                            label: 'Confirm Reschedule',
                            title: 'Click to confirm reschedule',
                            onClick: onConfirmReschedule,
                            isDisable: booking.length === 0 ? true : false
                        }
                    }
                    showCancelButton={false}
                    showCancelIcon={true}
                />
            }
        </>
      </MuiModal>
    );
}//end TreatmentSelectionModal()

export default TreatmentSelectionModal;
