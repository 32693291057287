import { gql } from "@apollo/client";

const GET_PRODUCTS_FOR_CUSTOMER = gql`
	query getProductsForCustomer {
		getProductsForCustomer {
			id
			title
			price
			urlKey
			thumbnail {
				fileName
				src
				position
			}
		}
	}
`;

export const GetProductsForCustomer = GET_PRODUCTS_FOR_CUSTOMER;
