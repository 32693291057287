import { gql } from "@apollo/client";

const DELETE_TREATMENT_BY_ID = gql`
	mutation deleteTreatmentById($id: String!) {
		deleteTreatmentById(id: $id) {
			status
			message
		}
	}
`;

export const DeleteTreatmentByIdGql = DELETE_TREATMENT_BY_ID;
