import { Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import TreatmentInterface, { TreatmentImageUploadInterface } from '../../../../../modules/Treatment/Api/Data/TreatmentInterface';
import ActionButton from '../../../../Common/ActionButton/ActionButton';
import BasicTextInput from '../../../../Common/BasicTextInput/BasicTextInput';
import ImageUpload from '../../../../Common/ImageUpload/ImageUpload';
import TextArea from '../../../../Common/TextArea/TextArea';
import { useMutation } from '@apollo/client';
import { CreateTreatmentGql } from '../../../../../modules/GraphQl/Resolvers/Treatment/createTreatment.gql';
import { useNavigate } from 'react-router-dom';
import { BACKOFFICE_URL } from '../../../../../modules/Api/Data/ConstantInterface';
import { useAdmin } from '../../../../Context/AdminProvider';
import { useHelper } from '../../../../Context/HelperProvider';

const AddAdminTreatment = () => {

    const { utility } = useHelper();
    const { gqlHeader } = useAdmin();
    const navigate = useNavigate();
    const [ formData, setFormData ] = useState<TreatmentInterface>({
        title: '',
        isEnable: false,
        price: '',
        description: '',
        urlKey: "",
        images: [],
    });

    const [ createTreatment, { data, loading, error } ] = useMutation(CreateTreatmentGql);
    const [ isDisable, setIsDisable ] = useState(true);

    useEffect(() => {
        if (data) {
            if (data.createTreatment.status) {
                const navigateTimer = setTimeout(() => {
                    navigate(`/${BACKOFFICE_URL}/core/treatment`);
                    return () => clearTimeout(navigateTimer);
                }, 1000);
            }
        }
    }, [data]);

    useEffect(() => {
        setIsDisable(validateFormData());
    }, [formData]);

    const onSwitchChange = (e: any) => {
        setFormData({
            ...formData,
            isEnable: e.target.checked
        });
    }

    const onChange = (value: string, identifier: string) => {
        formData[identifier] = value;
        setFormData({...formData});
    }

    const onSubmit = async () => {

        const urlKey = utility.urlKeyCreation(formData.title);

        await createTreatment({
            variables: {
                ...formData,
                price: utility.preprocessPrice(formData.price),
                urlKey: urlKey,
            },
            context: gqlHeader
        });
    }

    const onUpload = (position: number, fileObj: any) => {
        let image: TreatmentImageUploadInterface = {
            base64: fileObj.base64,
            contentType: fileObj.type,
            fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
            position: position
        };
        /** @ts-ignore */
        formData.images[position] = image;
        setFormData({...formData});
    }

    const onDelete = (position: number) => {
		setFormData({ ...formData, images: formData.images.filter((img: any) => { return img.position !== position }) });
	};

    const validateFormData = () => {
        if (formData.price.length <= 0) {
            return true;
        }

        if (formData.title.length <= 0) {
            return true;
        }

        if (typeof formData.images[0] === 'undefined') {
			return true;
		}

        return false;
    }

    return (
        <div className='adminActionDiv'>
            <span className='adminActionHeading adminActionBookingHeading'>
                <span>Add New Treatment</span>
                <span></span>
                <ActionButton 
                    label={'Confirm'} 
                    title={'Click to submit.'} 
                    isDisable={isDisable}
                    onClick={() => onSubmit()}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
            </span>
            <table className='adminActionTable'>
                <tbody>
                    <tr>
                        <td className='adminActionHeader'>Enable</td>
                        <td><Switch onChange={onSwitchChange} /></td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Title</td>
                        <td>                            
                            <BasicTextInput 
                                identifier='title'
                                isRequired={true}
                                /** @ts-ignore */
                                canShowError={formData.title?.length > 0 ? false : true}
                                errorText="Title is required."
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={1}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Price</td>
                        <td>
                            <BasicTextInput 
                                identifier='price'
                                isRequired={true}
                                inputType={'price'}
                                prefix={'RM'}
                                canShowError={formData.price.length > 0 ? false : true}
                                errorText="Price is required."
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={2}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Description</td>
                        <td>
                            <TextArea 
                                identifier='description'
                                onChange={onChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Main thumbnail</td>
                        <td><ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired/></td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Secondary thumbnail</td>
                        <td><ImageUpload position={1} onUpload={onUpload} onDelete={onDelete} isRequired/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}//end AddAdminTreatment()

export default AddAdminTreatment;
