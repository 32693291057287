import { gql } from "@apollo/client";

const UPDATE_USER_BY_ID = gql`
  mutation updateUserById($id: String!, $data: String!) {
    updateUserById(id: $id, data: $data) {
      id
      username
      phone
      isVerified
    }
  }
`;

export const UpdateUserByIdGql = UPDATE_USER_BY_ID;
