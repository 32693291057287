import { gql } from "@apollo/client";

const GET_ALL_PRODUCTS = gql`
	query getAllProducts {
		getAllProducts {
			id
			isEnabled
			title
			price
			description
			capacity
			urlKey
			thumbnail {
				fileName
				src
				position
			}
			ingredients {
				id
				name
				description
			}
			functionalities
			usageDirection
		}
	}
`;

export const GetAllProducts = GET_ALL_PRODUCTS;
