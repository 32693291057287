import { gql } from "@apollo/client";

const CREATE_BANNER = gql`
	mutation createBanner($title: String!, $description: String, $isEnabled: Boolean!, $images: [BannerImageInput]!) {
		createBanner(title: $title, description: $description, isEnabled: $isEnabled, images: $images) {
			status
			message
		}
	}
`;

export const CreateBanner = CREATE_BANNER;
