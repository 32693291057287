import { gql } from "@apollo/client";

const DELETE_ADMIN_BY_ID = gql`
  mutation deleteAdminById($id: String!) {
    deleteAdminById(id: $id) {
      status
      message
    }
  }
`;

export const DeleteAdminByIdGql = DELETE_ADMIN_BY_ID;
