import "../AdminContent.scss";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GetAllBanner } from "../../../../modules/GraphQl/Resolvers/Banner/getAllBanner.gql";
import { RemoveBannerById } from "../../../../modules/GraphQl/Resolvers/Banner/removeBannerById.gql";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import BannerInterface from "../../../../modules/Banner/Api/Data/BannerInterface";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminHero = () => {
    const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

	const { data, refetch } = useQuery(GetAllBanner, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [ removeBanner, { data: deleteData } ] = useMutation(RemoveBannerById);
	const [ banner, setBanner ] = useState<BannerInterface[]>([]);
    const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

    const columns: GridColDef[] = [
		{
			field: "images",
			headerName: "Images",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<div className="gridTableImgDiv">
						{params.row.images.length > 0 && (
							<img className="gridTableImg" src={params.row.images[0].src} alt={"bannerImage"}/>
						)}
					</div>
				);
			},
		},
		{
			field: "title",
			headerName: "Title",
			width: 200,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "description",
			headerName: "Description",
			width: 350,
			sortable: false,
			editable: false,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				return <span> {params.row.description} </span>;
			},
		},
		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 100,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span className="actionTab" onClick={() => {
						navigate(`/${BACKOFFICE_URL}/content/hero/edit/${params.row.id}`);
					}}>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							deleteItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span>
				);
			},
			align: "left",
		},
	];

	useEffect(() => {
		if (data) {
			if (data.getAllBanner) {
				setBanner([...data.getAllBanner]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (deleteData) {
			if (deleteData.removeBannerById.status) {
				refetch();
			}
		}
	}, [deleteData]);

	const deleteItemPrompt = (banner: BannerInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${banner.title}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected faq class - ${banner.title}.`,
				onClick: removeItem,
				extra: banner,
			},
		});
	};

    
    const removeItem = async (banner: BannerInterface) => {
        await removeBanner({
            variables: {
                id: banner.id,
            },
			context: gqlHeader
        })
    }


    return (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Hero Banner</span>
                <span></span>
                <ActionButton 
					label={"Add New Banner"}
					title={"Click to add a new Banner."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/content/hero/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={banner}
						columns={columns}
						pageSize={5}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
    );
}//end AdminHero()

export default AdminHero;
