import { gql } from "@apollo/client";

const UPDATE_PRODUCT_BY_ID = gql`
	mutation updateProductById($id: String!, $data: String!) {
		updateProductById(id: $id, data: $data) {
			status
			message
		}
	}
`;

export const UpdateProductById = UPDATE_PRODUCT_BY_ID;