export const KEY_UID = "uid";
export const KEY_AID = "aid";
export const KEY_ADMIN_DATA = "cached_admin";
export const KEY_RESET_PASSWORD_FOR_UID = "reset_for";
export const KEY_RESET_PASSWORD_DONE_OTP = "reset_otp";

/** For admin listing view purposes ONLY */
export const KEY_ADMIN_DASHBOARD = "cached_admin_dashboard";
export const KEY_ADMIN_TREATMENTS = "cached_admin_treatments";
export const KEY_ADMIN_PRODUCTS = "cached_admin_products";
export const KEY_ADMIN_BOOKINGS = "cached_admin_bookings";
export const KEY_ADMIN_INGREDIENT = "cached_admin_ingredient";
export const KEY_ADMIN_PRODUCT_INGREDIENT = "cached_admin_product_ingredient";
export const KEY_ADMIN_CONFIGS = "cached_admin_configs";
export const KEY_ADMIN_HEROS = "cached_admin_heros";
export const KEY_ADMIN_TESTIMONIALS = "cached_admin_testimonials";
export const KEY_ADMIN_FAQS = "cached_admin_faqs";
export const KEY_ADMIN_CUSTOMERS = "cached_admin_customers";
export const KEY_ADMIN_ADMINS = "cached_admin_admins";
export const KEY_ADMIN_PAYMENTS = "cached_admin_payments";
export const KEY_BOOKING_RESCHEDULE = "cached_booking_reschedule";
/** End admin listing */

/** Frontend caching */
export const KEY_BOOKING_TREATMENTS = "cached_booking_treatments";
export const KEY_USER_DATA = "cached_user";
export const KEY_BOOKING_MAXMONTH = "cached_booking_maxmonth";
export const KEY_CUSTOMER_TREATMENTS = "cached_customer_treatments";
export const KEY_CUSTOMER_TREATMENTS_BOOKING = "cached_customer_treatments_booking";
export const KEY_CUSTOMER_TESTIMONIAL_DIV = "cached_customer_testimonial_div";
export const KEY_BOOKING_TEMPIDS = "cached_temp_booking_ids";