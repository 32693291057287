import { gql } from "@apollo/client";

const UPDATE_TEMP_BOOKING = gql`
  mutation updateTempBooking($bid: String!, $start: String!, $end: String!) {
    updateTempBooking(bid: $bid, start: $start, end: $end) {
      id
      bid
      status
      treatmentId
      treatmentName
      treatmentPrice
      treatmentSrc
      uid
      start
      end
      createdAt
    }
  }
`;
export const UpdateTempBookingGql = UPDATE_TEMP_BOOKING;
