import { gql } from "@apollo/client";

const USER_REGISTER = gql`
  mutation userRegister($username: String!, $phone: String!, $password: String!) {
    userRegister(username: $username, phone: $phone, password: $password) {
      id
      username
      phone
      isVerified
    }
  }
`;

export const UserRegisterGql = USER_REGISTER;
