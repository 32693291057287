import { useMutation } from "@apollo/client";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import Cryptor from "../../../modules/Cryptor/Core/Cryptor";
import Messaging from "../../../modules/Firebase/Model/Messaging";
import { UpdateUserByIdGql } from "../../../modules/GraphQl/Resolvers/User/updateUserById.gql";
import { KEY_UID } from "../../../modules/Storage/Api/ConstantInterface";
import Storage from "../../../modules/Storage/Core/Storage";
import MyAccountTitle from "../MyAccountTitle/MyAccountTitle";
import './MyAccountPersonal.scss';

declare const navigator: any;

const MyAccountSettings = () => {
    const messaging: Messaging = new Messaging();
    const storage: Storage = new Storage();
    const cryptor: Cryptor = new Cryptor();
    const [ isGranted, setIsGranted ] = useState<any>(false);
    const [ errorMsg, setErrorMsg ] = useState('');
    const [ isDisable, setIsDisable ] = useState(false);
    const [ fcmToken, setFcmToken ] = useState('');
    const [ updateUser, { data, loading, error } ] = useMutation(UpdateUserByIdGql);

    useEffect(() => {
        navigator.permissions.query({ name: 'notifications' }).then(async (result: any) => { 
            if (result.state === 'granted' || result.state === 'denied') {
                setIsGranted(result.state === 'granted' ? true : false);
                setIsDisable(true);
                setErrorMsg('Please reset your browser permissions from site settings.');
            }
        });
    }, []);

    useEffect(() => {
        if (fcmToken) {
            saveFcmTokenForUser();
        }
    }, [fcmToken])

    const saveFcmTokenForUser = async () => {
        await updateUser({
            variables: {
                id: storage.getItem(KEY_UID),
                data: cryptor.encrypt(JSON.stringify({ fcmToken }))
            }
        })
    }

    const onSwitchChange = async(e: any) => {
        if (e.target.checked) {
            navigator.permissions.query({ name: 'notifications' }).then(async (result: any) => { 
                if (result.state === 'granted') {
                    setIsGranted(true);
                } else if (result.state === 'denied') {
                    setIsGranted(await requestPermission());
                } else {
                    setIsGranted(await requestPermission());
                }
            }).catch(async (error: any) => {
                setIsGranted(await requestPermission());            
            });
        } else {
            setIsGranted(false);
        }
    }

    const requestPermission = () => {
        try {
            return new Promise((resolve, reject) => {
                Notification.requestPermission().then(async (result: NotificationPermission) => {
                    if (result === 'granted') {
                        const token = await messaging.getClientToken();
                        setFcmToken(token);
                    }
                    resolve(result === 'granted' ? true : false);
                    setIsDisable(true);
                    setErrorMsg('Please reset your browser permissions from site settings.');
                }).catch((error: any) => {
                    reject(false);
                })
            })
        } catch {
            setErrorMsg("Your browser doesn't support notification!");
        }
        return false;
    }

    return (
        <div className="myAccountPersonal">
            <MyAccountTitle title='Account Settings'/>
            <table className="myAccountPersonalTable">
                <tbody>
                    <tr className="myAccountPersonalTableRow">
                        <td rowSpan={2} className="myAccountPersonalTableRowTitle">
                            Allow notification?
                        </td>
                        <td className="myAccountPersonalTableRowCont">
                            <Switch onChange={onSwitchChange} checked={isGranted} disabled={isDisable} className={`myAccountPersonalSwitch ${isDisable ? 'myAccountPersonalSwitchDisabled' : ''}`}/>
                            <span className="errorMsg">{errorMsg}</span>
                        </td>
                    </tr>
                    <tr className="myAccountPersonalTableInfo">
                        <td className="myAccountPersonalTableRowCont">Receive notifications for your booking sessions 30 minutes before scheduled time.</td>                    
                    </tr>
                </tbody>
            </table>
        </div>
    );
}//end MyAccountSettings()

export default MyAccountSettings;
