import BookingItemInterface from "../../modules/Booking/Api/Data/BookingInterface";
import BookingInterface from "../../modules/Booking/Api/Data/BookingInterface";
import { KEY_BOOKING_TEMPIDS } from "../../modules/Storage/Api/ConstantInterface";
import Storage from "../../modules/Storage/Core/Storage";
import TreatmentInterface from "../../modules/Treatment/Api/Data/TreatmentInterface";
import { default as evt } from "./BookingReducerDispatchEvents";

const BOOKING_MAXMONTH_FALLBACK = 2;
const RESERVED_BOOKING_LIMIT = 3;
const storage = new Storage();

interface BookingReducerStateInterface {
    selectedDate: Date,
    events: BookingInterface[],
    isCalendarOpen: boolean,
    startDate: Date,
    endDate: Date,
    maxMonth: number,
    slotMinTime: string,
    slotMaxTime: string,
    reservedBookingLimit: number,
    reservedBookingCount: number,
    temporaryBookings: BookingInterface[],
    temporaryBookingIds: string[],
    selectedTreatment: TreatmentInterface[],
}

interface BookingReducerActionInterface {
    type: evt,
    payload?: any
}

export const bookingReducerInitialState: BookingReducerStateInterface = {
    selectedDate: new Date(),
    events: [],
    isCalendarOpen: false,
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + BOOKING_MAXMONTH_FALLBACK)),
    maxMonth: BOOKING_MAXMONTH_FALLBACK,
    slotMinTime: '10:00:00',
    slotMaxTime: '18:00:00',
    reservedBookingLimit: RESERVED_BOOKING_LIMIT,
    reservedBookingCount: 0,
    temporaryBookings: [],
    temporaryBookingIds: [],
    selectedTreatment: []
}

const bookingReducer = (state: any | BookingReducerStateInterface, action: BookingReducerActionInterface) => {
    switch (action.type) {
        case evt.EVT_ADD_RESERVED_BOOKING_COUNT:
            return {
                ...state,
                reservedBookingCount: state.reservedBookingCount + 1
            };
        case evt.EVT_MINUS_RESERVED_BOOKING_COUNT:
            return {
                ...state,
                reservedBookingCount: state.reservedBookingCount - 1
            };
        case evt.EVT_IS_CALENDAR_OPEN:
            return {
                ...state,
                isCalendarOpen: action.payload
            };
        case evt.EVT_SET_SLOT_MIN_TIME:
            return {
                ...state,
                slotMinTime: action.payload
            };
        case evt.EVT_SET_SLOT_MAX_TIME:
            return {
                ...state,
                slotMaxTime: action.payload
            };
        case evt.EVT_SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload
            };
        case evt.EVT_SET_START_DATE:
            return {
                ...state,
                startDate: action.payload
            };
        case evt.EVT_SET_END_DATE:
            return {
                ...state,
                endDate: action.payload
            };
        case evt.EVT_SET_TEMP_BOOKING:
            return {
                ...state,
                temporaryBookings: action.payload
            };
        case evt.EVT_SET_TEMP_BOOKING_IDS_LOCALSTORAGE:
            storage.setItem(KEY_BOOKING_TEMPIDS, action.payload.toString());
            return {
                ...state,
                temporaryBookingIds: action.payload
            };
        case evt.EVT_UPDATE_TEMP_BOOKING:
            let newTempBookings: any = state.temporaryBookings;
            newTempBookings.map((booking: BookingItemInterface, index: any) => {
                if (booking.bid === action.payload.bid) {
                    newTempBookings[index] = action.payload;
                } 
            });
            return {
                ...state,
                temporaryBookings: [...newTempBookings]
            };
        case evt.EVT_DELETE_TEMP_BOOKING:
            let latestTempBooking: any = state.temporaryBookings;
            latestTempBooking.filter((booking: BookingItemInterface) => {
                return booking.bid !== action.payload
            });
            return {
                ...state,
                temporaryBookings: [...latestTempBooking]
            };
        case evt.EVT_SET_SELECTED_TREATMENT:
            return {
                ...state,
                selectedTreatment: action.payload
            };
        case evt.EVT_SET_MAX_MONTH:
            return {
                ...state,
                maxMonth: action.payload
            };
        case evt.EVT_SET_RESERVED_BOOKING_LIMIT:
            return {
                ...state,
                reservedBookingLimit: action.payload
            };
        case evt.EVT_SET_EVENTS:
            return {
                ...state,
                events: action.payload
            };
        default: 
            return {
                ...state
            }
    }
}//end bookingReducer()

export default bookingReducer;
