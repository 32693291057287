import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../modules/Api/Data/ConstantInterface";
import { useAdmin } from "../../Context/AdminProvider";

const AdminRestrictor = () => {
    const { isAdminLoggedIn } = useAdmin();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isAdminLoggedIn()) {
            if (location.pathname !== '/') {
                navigate(`/${BACKOFFICE_URL}?redirect=${location.pathname.charAt(0) === '/' ? location.pathname.substring(1) : location.pathname}`)
            } else {
                navigate('/${BACKOFFICE_URL}');
            }        
        }
    }, []);

    return <></>
}//end AdminRestrictor()

export default AdminRestrictor;
