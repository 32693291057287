import { Box, Grid, styled, IconButton, Button } from "@mui/material";
import "./NavigationBar.scss";
import { Facebook, Instagram } from "@mui/icons-material";
import WhatsappButton from "../Common/WhatsappButton/WhatsappButton";

const SocialBox = styled(Box)({
    gap: 10,    
    width: "fit-content",
});

const NavigationBar = () => {

    const noHover = {
        "&:hover": {
            backgroundColor: "transparent",
        }
    }

    return (
        <Box sx={{background: "#f4eee9", height: "100%", width: "100%"}}>
            <Grid container columnGap={.8} gridRow={1} gridColumn={5} className="navigationBarGrid">
                <div>
                    {/** logo */}
                    <p className="footerTitle">Beauvie Hair Care</p>
                </div>
                <div>
                    <p className="h5">Discover</p>
                    <div className="navigationBox">                    
                        <Button className="navigationBoxLink" sx={noHover} variant="text" component="a" href="/products"><span>Products</span></Button>
                        <Button className="navigationBoxLink" sx={noHover} variant="text" component="a" href="/treatments"><span>Treatment</span></Button>
                        <Button className="navigationBoxLink" sx={noHover} variant="text" component="a" href="/testimonial"><span>Testimonial</span></Button>
                    </div>
                </div>
                <div>
                    <p className="h5">Help Center</p>
                    <div className="navigationBox">                    
                        <Button className="navigationBoxLink" sx={noHover} variant="text" component="a" href="/about_us"><span>About Us</span></Button>
                        <Button className="navigationBoxLink" sx={{noHover, minWidth: 0}} variant="text" component="a" href="/faq"><span>FAQ</span></Button>
                    </div>
                </div>
                <div>
                    <p className="h5">Location</p>
                    <div className="navigationBox">                    
                        17-M Jalan Manis 4, Taman Segar, Jalan Cheras 56100, Kuala Lumpur.
                    </div>
                </div>
                <div>
                    <p className="h5">Feel Free To Contact Us Through</p>
                    <WhatsappButton title="Click here for more enquiries." />
                    <SocialBox className="social-box">
                        <IconButton sx={noHover} component="a" href="/facebook" aria-label="facebook-button">
                            <Facebook />
                        </IconButton>
                        <IconButton sx={noHover} component="a" href="/instagram" aria-label="instagram-button">
                            <Instagram />
                        </IconButton>
                    </SocialBox>
                </div>
            </Grid>
        </Box>
    )
}

export default NavigationBar;