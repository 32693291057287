import "../../CoreActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import ImageUpload from '../../../../Common/ImageUpload/ImageUpload';
import MultiSelectChip from '../../../../Common/MultiSelectChip/MultiSelectChip';
import { Switch, TextField } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ProductImageInputInterface } from "../../../../../modules/Product/Api/Data/ProductInterface";
import { GetProductById } from "../../../../../modules/GraphQl/Resolvers/Product/getProductById.gql";
import { UpdateProductById } from '../../../../../modules/GraphQl/Resolvers/Product/updateProductById.gql';
import { useAdmin } from "../../../../Context/AdminProvider";
import { useHelper } from "../../../../Context/HelperProvider";
import AdminProductVariation from "./AdminProductVariation/AdminProductVariation";

const AdminEditProduct = () => {
	const { cryptor, utility } = useHelper();
	const { gqlHeader } = useAdmin();
	const location = useLocation();
    const navigate = useNavigate();

	const { data: initData } = useQuery(GetProductById, {
		variables: {
			id: location.pathname.split("/").pop(),
		},
		fetchPolicy: "no-cache",
		context: gqlHeader
	});
	const [ updateProductById, { data: updateData } ] = useMutation(UpdateProductById);
	
	const [formData, setFormData] = useState<any>({
		title: "",
		description: "",
		isEnabled: true,
		capacity: "",
		price: "",
		ingredients: [""],
		functionalities: [""],
		usageDirection: [""],
		thumbnail: [],
	});
	const [oldData, setOldData] = useState<any>({
		thumbnail: [],
	});
	const [isDisable, setIsDisable] = useState(true);
	const [fieldsToBeUpdated, setFieldsToBeUpdated] = useState<string[]>([]);
	
	const onSwitchChange = (e: any) => {
		setFormData({
			...formData,
			isEnabled: e.target.checked,
		});
		updateFieldsToBeUpdated("isEnabled");
	};

    const addFunction = () => {
		setFormData({...formData, functionalities: [...formData.functionalities, ""]})
		updateFieldsToBeUpdated("functionalities");
	}

	const removeFunction = (index) => {
		const list = [...formData.functionalities];
		list.splice(index, 1);
		setFormData({...formData, functionalities: list});
		updateFieldsToBeUpdated("functionalities");
	}

	const functionChange = (e, index) => {
		const value = utility.escapeEmoji(e.target.value);
		let list = [...formData.functionalities];
		list[index] = value;
		setFormData({...formData, functionalities: list});
		updateFieldsToBeUpdated("functionalities");
	}

	const addUsage = () => {
		setFormData({...formData, usageDirection: [...formData.usageDirection, ""]})
		updateFieldsToBeUpdated("usageDirection");
	}

	const removeUsage = (index) => {
		const usageList = [...formData.usageDirection];
		usageList.splice(index, 1);
		setFormData({...formData, usageDirection: usageList})
		updateFieldsToBeUpdated("usageDirection");
	}

	const usageChange = (e, index) => {
		const value = utility.escapeEmoji(e.target.value);
		const usageList = [...formData.usageDirection];
		usageList[index] = value;
		setFormData({...formData, usageDirection: usageList});
		updateFieldsToBeUpdated("usageDirection");
	}

    const chipChange = (value: any) => {
		const temp: any = [];
		value.map((item) => {
			temp.push("/ingredients/" + item);
		})	
		formData.ingredients = temp;
		setFormData({...formData});
		updateFieldsToBeUpdated("ingredients");
	}

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		updateFieldsToBeUpdated(identifier);
	};

	const onVariantChange = (variant: any) => {
		let newPrice: string[] = [];
		let newCapacity: string[] = [];
		variant.forEach((item: any) => {
			newPrice.push(item.price);
			newCapacity.push(item.capacity);
		});
		formData["price"] = newPrice.toString();
		formData["capacity"] = newCapacity.toString();
		setFormData({ ...formData });
		updateFieldsToBeUpdated("price");
		updateFieldsToBeUpdated("capacity");
	};

    const updateFieldsToBeUpdated = (field: string) => {
		if (!fieldsToBeUpdated.includes(field)) {
			fieldsToBeUpdated.push(field);
		}
		setFieldsToBeUpdated([...fieldsToBeUpdated]);
	};

    const validateFormData = () => {
		if (fieldsToBeUpdated.includes('thumbnail[0]_del')) {
			if (formData.thumbnail.filter((img: any) => { return img.position === 0}).length === 0) {
				return true;
			}
		}

        if (fieldsToBeUpdated.length > 0) {
			return false;
		}

		if (formData.ingredients.length <= 0) {
			return true;
		}

        if (formData.thumbnail.length <= 0) {
            return true;
        }
        return false;
    }

	const onUpload = (position: number, fileObj: any) => {
		let image: ProductImageInputInterface = {
			base64: fileObj.base64,
			contentType: fileObj.type,
            fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
			position: position,
		};
		let tempThumbnail: any = [...formData.thumbnail];
		tempThumbnail[position] = image;
		updateFieldsToBeUpdated(`thumbnail[${position}]`);
		setFormData({ ...formData, thumbnail: tempThumbnail });
	};

	const onDelete = (position: number) => {
		if (oldData.thumbnail[position] && oldData.thumbnail[position].hasOwnProperty('src')) {
			setFormData({ ...formData, thumbnail: formData.thumbnail.filter((img: any) => { return img.position !== position }) });
			updateFieldsToBeUpdated(`thumbnail[${position}]_del`);
		}
	};

	const onSubmit = async () => {
		let data: any = {
			oldImages: [],
			thumbnail: oldData.thumbnail.length > 0 ? [...oldData.thumbnail] : [],
		};

		fieldsToBeUpdated.forEach((field: string) => {
			if (field === "price") {
				let tempPrice = formData[field].split(",");
				tempPrice.map((price: string) => {
					utility.preprocessPrice(price);
				});
				data[field] = tempPrice.toString();
			} else if (field === "thumbnail[0]") {
				if(oldData.thumbnail[0]) {
					data["oldImages"].push(oldData.thumbnail[0].src);
				}
				data.thumbnail[0] = formData.thumbnail[0];
			} else if (field === "thumbnail[0]_del") {
				data["oldImages"].push(oldData.thumbnail[0].src); 
				let filtered: any[] = [...data.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== 0 })];
				data.thumbnail.splice(0, filtered.length, ...filtered);
			} else if (field === "thumbnail[1]") {
				if(oldData.thumbnail[1]) {
					data["oldImages"].push(oldData.thumbnail[1].src);
				}
				data.thumbnail[1] = formData.thumbnail[1];
			} else if (field === "thumbnail[1]_del") {
				data["oldImages"].push(oldData.thumbnail[1].src); 
				let filtered: any[] = [...data.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== 1 })];
				data.thumbnail.splice(0, filtered.length, ...filtered);			
			} else if (field === "thumbnail[2]") {
				if(oldData.thumbnail[2]) {
					data["oldImages"].push(oldData.thumbnail[2].src);
				}
				data.thumbnail[2] = formData.thumbnail[2];
			} else if (field === "thumbnail[2]_del") {
				data["oldImages"].push(oldData.thumbnail[2].src); 
				let filtered: any[] = [...data.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== 2 })];
				data.thumbnail.splice(0, filtered.length, ...filtered);				
			} else if (field === "thumbnail[3]") {
				if(oldData.thumbnail[3]) {
					data["oldImages"].push(oldData.thumbnail[3].src);
				}
				data.thumbnail[3] = formData.thumbnail[3];
			} else if (field === "thumbnail[3]_del") {
				data["oldImages"].push(oldData.thumbnail[3].src); 
				let filtered: any[] = [...data.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== 3 })];
				data.thumbnail.splice(0, filtered.length, ...filtered);	
			} else if (field === "thumbnail[4]") {
				if(oldData.thumbnail[4]) {
					data["oldImages"].push(oldData.thumbnail[4].src);
				}
				data.thumbnail[4] = formData.thumbnail[4];
			} else if (field === "thumbnail[4]_del") {
				data["oldImages"].push(oldData.thumbnail[4].src); 
				let filtered: any[] = [...data.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== 4 })];
				data.thumbnail.splice(0, filtered.length, ...filtered);				
			} else {
				data[field] = formData[field];
			}
		});

		data.thumbnail = data.thumbnail.filter((thumbnail: any) => { return thumbnail });
		let processedThumbnail: any = [];
		data.thumbnail.forEach((thumbnail: any, index: number) => {
			processedThumbnail.push({
				...thumbnail,
				position: index
			});
		})

		data.thumbnail = [...processedThumbnail];

		await updateProductById({
			variables: {
				id: location.pathname.split("/").pop(),
				data: cryptor.encrypt(JSON.stringify(data)),
			},
			context: gqlHeader
		});
	};

	useEffect(() => {
		if (initData) {
			if (typeof initData.getProductById.id !== "string") {
				navigate(`/${BACKOFFICE_URL}/core/products`);
			} else {
				setFormData({...initData.getProductById});
				const tempThumbnail: any = [];
				tempThumbnail.push(...initData.getProductById.thumbnail);
				setOldData({...oldData, thumbnail: tempThumbnail});
			}
		}
	}, [initData])

	useEffect(() => {
		if (updateData) {
			if (updateData.updateProductById.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/core/product`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [updateData]);

	useEffect(() => {
		if (fieldsToBeUpdated.length > 0) {
			setIsDisable(validateFormData());
		}
	}, [fieldsToBeUpdated]);


    return initData && initData.getProductById && formData ? (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>{initData.getProductById.title}</span>
                <span></span>
				<ActionButton
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
					styling={'basic-brown'}
				/>
			</span>   
			<table className="adminActionTable">
				<tbody>
					<tr>
                        <td className='adminActionHeader'>Enable</td>
                        <td><Switch onChange={onSwitchChange} checked={formData.isEnabled}/></td>
                    </tr>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								value={initData.getProductById.title}
								tabIndex={1}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} value={initData.getProductById.description} />
						</td>
					</tr>
					<AdminProductVariation 
						price={initData.getProductById.price} 
						capacity={initData.getProductById.capacity}
						onVariantChange={onVariantChange}
					/>					
					<tr>
                        <td className='adminActionHeader'>Ingredients</td>
						{ formData && formData.ingredients && <MultiSelectChip onChange={chipChange} selectedData={initData.getProductById.ingredients} isRequired={true} errorText="At least one ingredient is required"/> }
                    </tr>
					<tr>
                        <td className='adminActionHeader'>Functionalities</td>
							{formData.functionalities.map((singleItem, index) => (
								<div key={index}>
									<TextField sx={{ width: "300px" }} variant="outlined" id="function" name="function" value={singleItem} onChange={(e) => functionChange(e, index)}/>
									<div className="first-division">
										{formData.functionalities.length - 1 === index && formData.functionalities.length < 4 && (
											<button type="button" className="add-btn" onClick={addFunction}>
												<span>Add Functionalities</span>
											</button>
										)}
									</div>
									<div className="second-division">
										{formData.functionalities.length > 1 && (
											<button type="button" className="remove-btn" onClick={() => removeFunction(index)}>
												<span>Remove</span>
											</button>
										)}
			  						</div>
								</div>
							))}
                    </tr>

					<tr>
                        <td className='adminActionHeader'>Usage Direction</td>
							{formData.usageDirection.map((singleItem, index) => (
								<div key={index}>
									<TextField sx={{ width: "300px" }} variant="outlined" id="function" name="function" value={singleItem} onChange={(e) => usageChange(e, index)}/>
									<div className="first-division">
										{formData.usageDirection.length - 1 === index && formData.usageDirection.length < 4 && (
											<button type="button" className="add-btn" onClick={addUsage}>
												<span>Add Usage Direction</span>
											</button>
										)}
									</div>
									<div className="second-division">
										{formData.usageDirection.length > 1 && (
											<button type="button" className="remove-btn" onClick={() => removeUsage(index)}>
												<span>Remove</span>
											</button>
										)}
			  						</div>
								</div>
							))}
                    </tr>
					<tr>
                        <td className='adminActionHeader'>Main thumbnail</td>
						<td>
							{initData.getProductById.thumbnail && 
							initData.getProductById.thumbnail[0] ? ( 
								<ImageUpload position={0} onUpload={onUpload} imgSrc={initData.getProductById.thumbnail[0].src} onDelete={onDelete} isRequired/> 
							) : ( 
								<ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired/>
							)}
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Secondary thumbnail</td>
                        <td>
							{initData.getProductById.thumbnail && 
							initData.getProductById.thumbnail[1] ? ( 
								<ImageUpload position={1} onUpload={onUpload} imgSrc={initData.getProductById.thumbnail[1].src} onDelete={onDelete}/> 
							) : ( 
								<ImageUpload position={1} onUpload={onUpload} onDelete={onDelete}/>
							)}
                        </td>
                    </tr>
					<tr>
                        <td className='adminActionHeader'>Third thumbnail</td>
                        <td>
							{initData.getProductById.thumbnail && 
							initData.getProductById.thumbnail[2] ? ( 
								<ImageUpload position={2} onUpload={onUpload} imgSrc={initData.getProductById.thumbnail[2].src} onDelete={onDelete}/> 
							) : ( 
								<ImageUpload position={2} onUpload={onUpload} onDelete={onDelete}/>
							)}
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Fourth thumbnail</td>
                        <td>
							{initData.getProductById.thumbnail && 
							initData.getProductById.thumbnail[3] ? ( 
								<ImageUpload position={3} onUpload={onUpload} imgSrc={initData.getProductById.thumbnail[3].src} onDelete={onDelete}/> 
							) : ( 
								<ImageUpload position={3} onUpload={onUpload} onDelete={onDelete}/>
							)}
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Fifth thumbnail</td>
                        <td>
							{initData.getProductById.thumbnail && 
							initData.getProductById.thumbnail[4] ? ( 
								<ImageUpload position={4} onUpload={onUpload} imgSrc={initData.getProductById.thumbnail[4].src} onDelete={onDelete}/> 
							) : ( 
								<ImageUpload position={4} onUpload={onUpload} onDelete={onDelete}/>
							)}
                        </td>
                    </tr>
				</tbody>
			</table>
		</div>
	) : null;
};

export default AdminEditProduct;