enum EDIT_ADMIN_BOOKING_REDUCER_DISPATCH_EVENTS_ENUM {
    EVT_UPDATE_STATUS = 'update_status',
    EVT_SET_PHONE = 'set_phone',
    EVT_SET_BOOKING_ID = 'set_booking_id',
    EVT_SET_PAYMENT_ID = 'set_payment_id',
    EVT_SET_CAN_BULK_REFUND = 'set_can_bulk_refund',
    EVT_SET_BULK_REFUND = 'set_bulk_refund'
}

export default EDIT_ADMIN_BOOKING_REDUCER_DISPATCH_EVENTS_ENUM;
