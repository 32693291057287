import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { BACKOFFICE_URL } from "../../../modules/Api/Data/ConstantInterface";
import ActionButton from "../../Common/ActionButton/ActionButton";
import { useAdmin } from "../../Context/AdminProvider";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GetRefundsForAdminGql } from "../../../modules/GraphQl/Resolvers/Refund/getRefundsForAdmin.gql";
import RefundInterface from "../../../modules/Firebase/Api/Data/RefundInterface";
import { useHelper } from "../../Context/HelperProvider";

const AdminRefund = () => {
    const { utility } = useHelper();
    const { gqlHeader, coreConfig } = useAdmin();
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(GetRefundsForAdminGql, {
        fetchPolicy: 'cache-and-network',
        context: gqlHeader
    });
    const [ refunds, setRefunds ] = useState<RefundInterface[]>([]);

    useEffect(() => {
        if (data) {
            if (data.getRefundsForAdmin) {
                setRefunds([...data.getRefundsForAdmin]);
            }
        }
    }, [data]);

    const columns: GridColDef[] = [
        {
            field: 'incrementId',
            headerName: 'Refund ID',
            width: 200,
            sortable: true,
            editable: false
        },
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            width: 150,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    RM {params.row.originalAmount}
                </span>
            ),
            align: 'left',
            sortComparator: utility.dataGridPriceComparator
        },
        {
            field: 'refundedAmount',
            headerName: 'Refunded Amount',
            width: 150,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    RM {params.row.refundedAmount}
                </span>
            ),
            align: 'left',
            sortComparator: utility.dataGridPriceComparator
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            sortable: true,
            editable: false,
            width: 210,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${utility.dateFormatter(new Date(params.row.createdAt))}`
                );
            },
            align: 'left'
        },
        {
            field: 'booking',
            headerName: 'View Booking',
            sortable: false,
            editable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span className='actionTab' onClick={() => {
                        navigate(`/${BACKOFFICE_URL}/core/booking/edit/${params.row.bookingId}`);
                    }}><OpenInNewIcon fontSize="small"/>View Booking</span>
                );
            },
            align: 'left'
        },
        {
            field: 'viewPayment',
            headerName: 'View Payment',
            sortable: false,
            editable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span className='actionTab' onClick={() => {
                        window.open(`https://dashboard.stripe.com/${coreConfig.stripe.isProduction ? '' : 'test/'}payments/${params.row.paymentId}`, '_blank')
                    }}><OpenInNewIcon fontSize="small"/>View Payment</span>
                );
            },
            align: 'left'
        }
    ];

    return (
        <div className="adminActionDiv">
            <span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Refunds</span>
                <ActionButton 
                    label={'Refund Dashboard'} 
                    title={'Click to navigate to Stripe Refunds Dashboard.'} 
                    isDisable={false}
                    onClick={() => {window.open(`https://dashboard.stripe.com/${coreConfig.stripe.isProduction ? '' : 'test/'}payments?status[0]=refunded&status[1]=refund_pending&status[2]=partially_refunded`, '_blank')}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
                <ActionButton 
                    label={'Stripe Dashboard'} 
                    title={'Click to navigate to Stripe Dashboard.'} 
                    isDisable={false}
                    onClick={() => {window.open(`https://dashboard.stripe.com/${coreConfig.stripe.isProduction ? '' : 'test/'}payments`, '_blank')}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown-outline'}
                />
			</span>
            <div className="adminActionContDiv">
                <div className='dataGrid'>
                    <DataGrid
                        rows={refunds}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </div>
            </div>
        </div>
    );
}//end AdminRefund()

export default AdminRefund;
