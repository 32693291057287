import './BookingItem.scss';

const BookingItem = (props: any) => {

    const {
        treatmentSrc: src,
        treatmentName: title,
        treatmentPrice: price,
        start,
        end,
    } = props;

    return (
        <div className='bookingItemMainDiv'>
            <div className='bookingItemImgDiv'><img src={src} title={title} alt={title}/></div>
            <div className='bookingItemContDiv'>
                <span className='bookingItemContTitle'>{title}</span>
                <span>
                    <span>
                        <span>{new Date(start).toLocaleDateString('en-US', { day: 'numeric' })}&nbsp;</span>
                        <span>{new Date(start).toLocaleDateString('en-US', { month: 'short' })}&nbsp;</span>
                        <span>{new Date(start).toLocaleDateString('en-US', { year: 'numeric' })}</span>
                    </span>
                    <span>&nbsp;|&nbsp;{new Date(start).toLocaleDateString('en-US', { weekday: 'long' })}</span>
                    <span>&nbsp;|&nbsp;{new Date(start).toLocaleTimeString('en-US', { hour: '2-digit'})} - {new Date(end).toLocaleTimeString('en-US', { hour: '2-digit'})}</span>
                </span>
                <span className='bookingItemContPrice'>RM {price}</span>
            </div>
        </div>
    );
}//end BookingItem()

export default BookingItem;
