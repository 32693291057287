import { gql } from "@apollo/client";

const GET_LIVE_BOOKINGS = gql`
  query getLiveBookings {
    getLiveBookings {
      id
      bid
      status
      treatmentId
      treatmentName
      treatmentPrice
      treatmentSrc
      uid
      username
      phone
      aid
      start
      end
      expiredAt
      createdAt
    }
  }
`;

export const GetLiveBookingsGql = GET_LIVE_BOOKINGS;
