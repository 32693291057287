import { gql } from "@apollo/client";

const CANCEL_BOOKING_BY_ID = gql`
  mutation cancelBookingById($id: String!, $childrenIds: [String]!, $paymentId: String!) {
    cancelBookingById(id: $id, childrenIds: $childrenIds, paymentId: $paymentId) {
      status
      message
    }
  }
`;
export const CancelBookingByIdGql = CANCEL_BOOKING_BY_ID;
