import "./ProductListing.scss";
import ProductInterface from "../../../modules/Product/Api/Data/ProductInterface";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetProductsForCustomer } from "../../../modules/GraphQl/Resolvers/Product/getProductsForCustomer.gql";

const ProductListing = () => {

    const [ productList, setProductList ] = useState<ProductInterface[]>([]);
    const { data } = useQuery(GetProductsForCustomer);

    useEffect(() => {
        if (data) {
            if (data.getProductsForCustomer) {
                setProductList([...data.getProductsForCustomer]);
            }
        }
    }, [data]);

    return (
        <div className="productListingContainer">
            <div className="productListingDiv">
                <Typography variant="h2">All Products</Typography>
                <div className="productListingGrid">
                    {productList.map((product) => {
                        return (
                            <Link to={`/products/${product.id}`} className="productListingItemDiv" key={product.id}>
                                <div className="productContent">
                                    <div className="productImg" style={{backgroundImage: `url(${product.thumbnail[0].src})`}} />
                                </div>
                                <div className="productTitle">
                                    <Typography variant="h6">{product.title}</Typography>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProductListing;