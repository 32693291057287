import { gql } from "@apollo/client";

const IS_USER_EXIST = gql`
  mutation isUserExist($username: String!, $phone: String!) {
    isUserExist(username: $username, phone: $phone) {
      username
      phone
    }
  }
`;

export const IsUserExistGql = IS_USER_EXIST;
