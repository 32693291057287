import { gql } from "@apollo/client";

const UPDATE_TREATMENT_BY_ID = gql`
  mutation updateTreatmentById($id: String!, $data: String!) {
    updateTreatmentById(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const UpdateTreatmentByIdGql = UPDATE_TREATMENT_BY_ID;
