import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { BACKOFFICE_URL } from "../../modules/Api/Data/ConstantInterface";
import { AdminInterface } from "../../modules/Api/Data/DocumentInterface";
import ConfigInterface from "../../modules/Firebase/Api/Data/ConfigInterface";
import { GetCoreConfigMutationGql } from "../../modules/GraphQl/Resolvers/Config/getCoreConfigMutation.gql";
import { KEY_ADMIN_SESSID } from "../../modules/Session/Api/Data/ConstantInterface";
import { KEY_ADMIN_DATA, KEY_AID } from "../../modules/Storage/Api/ConstantInterface";
import { useHelper } from "./HelperProvider";

const AdminContext = React.createContext<any>(null);

export const useAdmin = () => {
    return useContext(AdminContext);
}

const AdminProvider = ({ children }) => {
    const { cryptor, session, storage, utility } = useHelper();
    const [ GetCoreConfig, { data }] = useMutation(GetCoreConfigMutationGql);
    const [ admin, setAdmin ] = useState<AdminInterface>({});
    const [ aid, setAid ] = useState(storage.getItem(KEY_AID) ?? '');
    const [ bearerToken, setBearerToken ] = useState('');
    const [ coreConfig, setCoreConfig ] = useState<ConfigInterface>({});

    useEffect(() => {
        if (storage.hasItem(KEY_AID)) {
            setAid(storage.getItem(KEY_AID));
        }

        if (storage.hasItem(KEY_ADMIN_DATA)) {
            updateAdmin(JSON.parse(storage.getItem(KEY_ADMIN_DATA)));
        }
    }, []);

    useEffect(() => {
        if (bearerToken) {
            // Only retrieve core configuration if the url resides in Backoffice
            if (window.location.pathname.includes(BACKOFFICE_URL)) {
                getCoreConfig();
            }
        }
    }, [bearerToken]);

    useEffect(() => {
        if (data) {
            if (data.getCoreConfig) {
                setCoreConfig(data.getCoreConfig);
            }
        }
    }, [data]);

    useEffect(() => {
        // Bearer Token is divided into two parts (divided by :)
        // First part is encrypted admin id
        // Second part is hashed bearer token included SALT and admin id
        // Backend will hash first part and match it with second part
        setBearerToken(`${cryptor.encrypt(aid)}:${utility.createUniqueBearerToken(aid ?? '')}`);
    }, [aid]);

    const getCoreConfig = async () => {
        await GetCoreConfig({
            context: constructGqlHeader
        })
    }//end getCoreConfig()

    const updateAdmin = (admin: AdminInterface) => {
        setAdmin({...admin});
    }//end updateAdmin()

    const adminLogin = (admin: AdminInterface) => {
        updateAdmin(admin);
        storage.setItem(KEY_AID, admin.id);
        storage.setItem(KEY_ADMIN_DATA, JSON.stringify(admin));
        session.setSessionItem(KEY_ADMIN_SESSID, bearerToken);
    }//end adminLogin()

    const adminLogout = () => {
        setAdmin({});
        setAid('');
        storage.deleteItem(KEY_AID);
        storage.deleteItem(KEY_ADMIN_DATA);
        session.removeSessionItem(KEY_ADMIN_SESSID);
    }//end adminLogout()

    const isAdminLoggedIn = () => {
        if (storage.hasItem(KEY_AID) && storage.hasItem(KEY_ADMIN_DATA)) {
            return true;
        }
        return false;
    }//end isAdminLoggedIn()

    const createNewBearerToken = () => {
        let result = `${cryptor.encrypt(aid)}:${utility.createUniqueBearerToken(aid ?? '')}`;
        setBearerToken(result);
        session.setSessionItem(KEY_ADMIN_SESSID, result);
        return result;
    }//end createNewBearerToken()

    const constructGqlHeader = {
        headers: {
            authorization: `Bearer ${bearerToken === '' ? createNewBearerToken() : bearerToken}`
        }
    }//end constructGqlHeader()

    return (
        <AdminContext.Provider value={{
            admin: admin,
            aid: aid,
            bearerToken: bearerToken === '' ? createNewBearerToken() : bearerToken,
            gqlHeader: constructGqlHeader,
            setAdmin: updateAdmin,
            isAdminLoggedIn: isAdminLoggedIn,
            login: adminLogin,
            logout: adminLogout,
            coreConfig: coreConfig
        }}>
            {children}
        </AdminContext.Provider>
    );
}//end AdminProvider()

export default AdminProvider;
