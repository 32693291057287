import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import reportWebVitals from './reportWebVitals';
import client from './modules/Apollo/Core/Apollo';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import UserProvider from './components/Context/UserProvider';
import AdminProvider from './components/Context/AdminProvider';
import Routes from './components/Routes/Routes';
import HelperProvider from './components/Context/HelperProvider';
import ProductProvider from './components/Context/ProductProvider';
import { ENDPOINT } from './modules/Api/Data/ConstantInterface';
import Maintenance from './components/Maintenance/Maintenance';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderRootContent = async () => {
  await fetch(ENDPOINT)
    .then((response) => {
      return response
    })
    .then((data) => {
      root.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
              <HelperProvider>
                  <ProductProvider>
                    <UserProvider>
                      <AdminProvider>
                        <RouterProvider router={Routes} />
                      </AdminProvider>
                    </UserProvider>
                  </ProductProvider>
              </HelperProvider>
            </ApolloProvider>
        </React.StrictMode>
      );
    })
    .catch((err) => {
      root.render(
        <Maintenance/>
      );    
    });
}

renderRootContent();

serviceWorkerRegistration.register();

reportWebVitals();
