import { gql } from "@apollo/client";

const GET_REFUNDS_FOR_ADMIN = gql`
  query getRefundsForAdmin {
    getRefundsForAdmin {
      id
      paymentId
      paymentIncrementId
      refundId
      originalAmount
      refundedAmount
      incrementId
      currency
      createdAt
      bookingId
      bookingItemId
    }
  }
`;
export const GetRefundsForAdminGql = GET_REFUNDS_FOR_ADMIN;
