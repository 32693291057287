import { useMutation } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../../modules/Api/Data/ConstantInterface';
import { CreateRealBookingGql } from '../../modules/GraphQl/Resolvers/Booking/createRealBooking.gql';
import { useHelper } from '../Context/HelperProvider';
import { useUser } from '../Context/UserProvider';
import StripeCheckoutForm from '../StripeCheckoutForm/StripeCheckoutForm';
import './StripePayment.scss';
import BookingInterface from '../../modules/Booking/Api/Data/BookingInterface';
import BookingItem from '../BookingItem/BookingItem';
import Divider from '../Common/Divider/Divider';
import { CircularProgress } from '@mui/material';

const StripePayment = () => {
    const navigate = useNavigate();
    const [ clientSecret, setClientSecret ] = useState('');
    const [ publishableKey, setPublishableKey ] = useState('');
    const location = useLocation();
    const { state } = location;
    const [ stripePromise, setStripePromise ] = useState<any>(null);
    const [ createRealBookingGql, { data } ] = useMutation(CreateRealBookingGql);
    const { cryptor } = useHelper();
    const { bearerToken, gqlHeader } = useUser();
    const [ gqlResponse, setGqlResponse ] = useState<any>({});
    const [ bookingId, setBookingId ] = useState('');

    useEffect(() => {
        if (state) {
            if (state.hasOwnProperty('bookingIds') || state.hasOwnProperty('repaymentId')) {
                if (state.bookingId) {
                    setBookingId(state.bookingId);
                }
                getPublishableKey();
            }
        } else {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (publishableKey) {
            if (state.repaymentId) {
                createPaymentIntentWithRepaymentId(state.repaymentId);
                setGqlResponse({
                    booking: state.booking,
                    finalAmount: state.finalAmount
                });
            } else {
                createRealBooking(state.bookingIds);
            }
        }
    }, [publishableKey]);

    useEffect(() => {
        if (data) {
            if (data.createRealBooking) {
                const { finalAmount, bookingItemIds, bookingId } = data.createRealBooking;
                createPaymentIntent(finalAmount, bookingItemIds, bookingId);
                setGqlResponse(data.createRealBooking); 
            }
        }
    }, [data]);

    const createRealBooking = async (bookingIds: string[]) => {
        await createRealBookingGql({
            variables: {
                bookingIds: bookingIds
            },
            context: gqlHeader
        })
    }

    const getPublishableKey = () => {
        fetch(`${ENDPOINT}/gpk`, {
            method: "GET",
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`
            })
        }).then(async (result: Response) => {
            const { publishableKey } = await result.json();
            setPublishableKey(cryptor.decrypt(publishableKey));
            setStripePromise(await loadStripe(cryptor.decrypt(publishableKey)));
        });
    }

    const createPaymentIntent = (finalAmount: number, bookingItemIds: string[], bookingId: string) => {
        fetch(`${ENDPOINT}/cpi?finalAmount=${finalAmount}&bookingItemIds=${bookingItemIds.join(',')}&bookingId=${bookingId}`, {
            method: "GET",
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`
            })
        }).then(async (result: Response) => {
            const { clientSecret } = await result.json();
            setClientSecret(clientSecret);
        });
    }

    const createPaymentIntentWithRepaymentId = (repaymentId: string) => {
        fetch(`${ENDPOINT}/rcpi?repaymentId=${repaymentId}`, {
            method: "GET",
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`
            })
        }).then(async (result: Response) => {
            const { clientSecret } = await result.json();
            setClientSecret(clientSecret);
        });
    }

    return (
        <div className='paymentDiv'>
            {
                stripePromise && clientSecret ? 
                <>
                <div className='stripeDiv'>
                    <div className='stripeContDiv'>
                        <span className='stripeContTitle'>Payment Methods</span>
                        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                            <StripeCheckoutForm/>
                        </Elements>
                    </div>
                </div> 
                <div className='paymentOrderDetails'>
                    <div className='paymentOrderCont'>
                        <span className='paymentOrderTitle'>
                            My Booking(s) {bookingId ? `for #${cryptor.decrypt(bookingId)}` : ''}
                            <Divider/>
                        </span>
                        <div>
                        {
                            gqlResponse.booking && gqlResponse.booking.length > 0 ?
                            gqlResponse.booking.map((tempBooking: BookingInterface) => {
                                return (
                                    <>
                                        <BookingItem {...tempBooking}/>
                                        <Divider/>
                                    </>
                                );
                            }) : null
                        }
                        </div>
                        {
                            gqlResponse && gqlResponse.finalAmount &&
                            <div className='paymentOrderGrandDiv'>
                                <span>Grand Total: </span>
                                <span className='grandTotal'>RM {`${gqlResponse.finalAmount.slice(0, -2)}.${gqlResponse.finalAmount.slice(-2)}`}</span>
                            </div>
                        }
                    </div>
                </div>
                </>
                : <div className='loading'><CircularProgress/></div>
            }
        </div>
    );
}//end StripePayment()

export default StripePayment;
