import { useMutation } from '@apollo/client';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserInterface } from '../../modules/Api/Data/DocumentInterface';
import auth from '../../modules/Firebase/Core/auth.inc';
import { KEY_OTP_BUFFER } from '../../modules/Session/Api/Data/ConstantInterface';
import { KEY_RESET_PASSWORD_DONE_OTP, KEY_RESET_PASSWORD_FOR_UID, KEY_UID } from '../../modules/Storage/Api/ConstantInterface';
import ActionButton from '../Common/ActionButton/ActionButton';
import BasicTextInput from '../Common/BasicTextInput/BasicTextInput';
import { UpdateUserByIdGql } from '../../modules/GraphQl/Resolvers/User/updateUserById.gql';
import './UserVerification.scss';
import { useHelper } from '../Context/HelperProvider';
import { useUser } from '../Context/UserProvider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const UserVerification = () => {
    const { cryptor, utility, storage } = useHelper();
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    
    const [ updateUser, { data: updateUserData }] = useMutation(UpdateUserByIdGql);
    const [ isDisable, setIsDisable ] = useState(true);
    const [ isVerified, setIsVerified ] = useState(false); 
    const [ redirectTimer, setRedirectTimer ] = useState(5);
    const [ userData, setUserData ] = useState<UserInterface>({});
    const [ otpInput, setOtpInput ] = useState('');
    const [ isRecaptchaCreated, setIsRecaptchaCreated ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ isError, setIsError ] = useState(false);
    
    useEffect(() => {
        if (storage.hasItem(KEY_RESET_PASSWORD_FOR_UID)) {
            setUserData({...JSON.parse(storage.getItem(KEY_RESET_PASSWORD_FOR_UID))});
        }
    }, []);

    useEffect(() => {
        if (user && !storage.hasItem(KEY_RESET_PASSWORD_FOR_UID)) {
            if (user.isVerified) {
                if (searchParams.has('redirect')) {
                    navigate(`/${searchParams.get('redirect')}`)
                } else {
                    navigate('/');
                }
            }
            setUserData({...user});
        }
    }, [user]);

    useEffect(() => {
        if (updateUserData) {
            if (updateUserData.updateUserById) {
                setUser({...updateUserData.updateUserById});
                if (storage.hasItem(KEY_RESET_PASSWORD_FOR_UID)) {
                    storage.setItem(KEY_RESET_PASSWORD_DONE_OTP, 'true');
                    navigate('/reset_password');
                } else {
                    redirectOnVerified(updateUserData.updateUserById.isVerified);
                }
            }
        } else {
            setIsDisable(true);
        }
    }, [updateUserData]);

    useEffect(() => {
        if (isVerified) {
            let sec: number = 5;
            const timer = setInterval(() => {
                sec--;
                setRedirectTimer(sec);
                if (sec <= 0) {
                    if (searchParams.has('redirect')) {
                        navigate(`/${searchParams.get('redirect')}`)
                    } else {
                        navigate('/');
                    }
                }
            }, 1000);
            return () => clearInterval(timer);
        }
        return () => false;
    }, [isVerified])

    const onOtpInput = (val: string, identifier: any) => {
        setOtpInput(val);
        if (val.length !== 6) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }

    const createAppVerifier = () => {
        if (!isRecaptchaCreated) {
            (window as any).recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
                'size': 'invisible',
                'callback': (response: any) => {
                }
            }, auth);
            setIsRecaptchaCreated(true);
        }
    }

    const onRequestOtp = () => {
        try {
            createAppVerifier();
            let appVerifier: any = (window as any).recaptchaVerifier;
            signInWithPhoneNumber(auth, `+${userData.phone}`, appVerifier)
            .then((confirmationResult: any) => {
                (window as any).confirmationResult = confirmationResult;
            })
            .catch((error: any) => {
                console.error(error)
                setIsError(true);
            })
        } catch (error: any) {
            console.error(error)
            setIsError(true);
        }
    }

    const onSubmitOtp = () => {
        setIsDisable(true);
        let confirmationResult: any = (window as any).confirmationResult;
        confirmationResult.confirm(otpInput).then(async (result: any) => {
            userData.isVerified = true;
            userData.authId = result.user.uid;
            await updateUser({
                variables: {
                    id: storage.hasItem(KEY_RESET_PASSWORD_FOR_UID) ? userData.id : storage.getItem(KEY_UID),
                    data: cryptor.encrypt(JSON.stringify(userData))
                }
            });
        })
    }

    const redirectOnVerified = (isVerified: boolean, onInit: boolean = false) => {
        if (onInit && isVerified) {
            if (searchParams.has('redirect')) {
                navigate(`/${searchParams.get('redirect')}`)
            } else {
                navigate('/');
            }
        }
        setIsVerified(isVerified);
    }

    return (
        <div className='userVerificationDiv'>
            {
                user && !isVerified ?             
                    <div className='userVerificationContDiv'>
                    <form className='userVerificationForm'>
                        <span className='userVerificationTitle'>
                            {storage.hasItem(KEY_RESET_PASSWORD_FOR_UID) ? 'Reset Password' : 'Verification required!'}
                            <span className='userVerificationSubTitle'>OTP will be send to { utility.phoneMasker(userData.phone ?? '') }.</span>
                        </span>
                        <BasicTextInput 
                            identifier='otp'
                            label="OTP Code" 
                            isRequired={true}
                            canShowError={otpInput === '' ? true : false}
                            errorText={"OTP code is required."}
                            isNumber={true}
                            minLength={6}
                            maxLength={6}
                            isAutocomplete={false}
                            onChange={onOtpInput}
                            tabIndex={1}
                            value={otpInput}
                            onEnter={onSubmitOtp}
                        />
                        <ActionButton 
                            label={'Request OTP'} 
                            isDisable={false}
                            title={'Click to request new OTP code.'} 
                            onClick={onRequestOtp}
                            canBuffer={true}
                            bufferId={KEY_OTP_BUFFER}
                            bufferTitle={'to request for new OTP.'}
                            bufferFor={1}
                        />
                        <span className='errorMsg'>{isError && "An error occured! Please try again later."}</span>
                        <span id="recaptcha"></span>
                    </form>
                    <ActionButton 
                        label={storage.hasItem(KEY_RESET_PASSWORD_FOR_UID) ? 'Continue' : 'Submit'} 
                        isDisable={isDisable}
                        title={'Submit your OTP code.'} 
                        onClick={onSubmitOtp}
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3}
                        styling={'form-bottom'}
                    />
                    </div> :             
                    <div className='userVerificationCompletedDiv'>
                        <AdminPanelSettingsIcon className='icon'/>
                        <span className='title'>Congratulations! You are now <span>verified</span>!</span>
                        <span className='subtitle'>You will be redirected in <span>{redirectTimer}</span> second(s).</span>
                    </div>
            }
        </div>
    );
}//end UserVerification()

export default UserVerification;
