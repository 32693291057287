import { gql } from "@apollo/client";

const CREATE_TEMP_BOOKING = gql`
  mutation createTempBooking($bid: String!, $treatmentId: String!, $treatmentName: String!, $treatmentPrice: String!, $treatmentSrc: String!, $uid: String!, $username: String!, $phone: String!, $aid: String, $start: String!, $end: String!) {
    createTempBooking(bid: $bid, treatmentId: $treatmentId, treatmentName: $treatmentName, treatmentPrice: $treatmentPrice, treatmentSrc: $treatmentSrc, uid: $uid, username: $username, phone: $phone, aid: $aid, start: $start, end: $end) {
      id
      bid
      status
      treatmentId
      treatmentName
      treatmentPrice
      treatmentSrc
      uid
      username
      phone
      aid
      start
      end
      expiredAt
      createdAt
    }
  }
`;
export const CreateTempBookingGql = CREATE_TEMP_BOOKING;
