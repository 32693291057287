import { gql } from "@apollo/client";

const UPDATE_INGREDIENT_BY_ID = gql`
	mutation updateIngredientById($id: String!, $data: ProductIngredientsInput!) {
		updateIngredientById(id: $id, data: $data) {
			status
			message
		}
	}
`;

export const UpdateIngredientById = UPDATE_INGREDIENT_BY_ID;
