import { gql } from "@apollo/client";

const CREATE_FAQ_CLASS = gql`
	mutation createFaqClass($title: String!) {
		createFaqClass(title: $title) {
			status
			message
		}
	}
`;

export const CreateFaqClass = CREATE_FAQ_CLASS;