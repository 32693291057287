import { useState, useEffect, useRef } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Storage from '../../../modules/Storage/Core/Storage';
import './MyAccountDropdownModal.scss';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserProvider';

const MyAccountDropdownModal = () => {
    const navigate = useNavigate();
    const [ canShowDropdown, setCanShowDropdown ] = useState(false);
    const [ isClicked, setIsClicked ] = useState(false);
    const [ scroll, setScroll ] = useState(0);
    const myAccountDropdownModalRef = useRef(null);
    const { isUserLoggedIn, user, logout } = useUser();

    useEffect(() => {
        if (isUserLoggedIn()) {
            setCanShowDropdown(true);
        } else {
            setIsClicked(false);
            setCanShowDropdown(false);
        }

        const handleScroll = (e: any) => {
          setScroll(window.scrollY);
          if (window.scrollY !== scroll) {
            setIsClicked(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            /** @ts-ignore */
          if (myAccountDropdownModalRef.current && !myAccountDropdownModalRef.current?.contains(event.target)) {
            setIsClicked(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myAccountDropdownModalRef]);

    const onIconClick = () => {
        if (canShowDropdown || isUserLoggedIn()) {
            setIsClicked(!isClicked);
        } else {
            navigate('/login');
        }
    }

    const onNavigate = (path: string) => {
        setIsClicked(false);
        navigate(path, { state: { active: path } });
    }

    const onLogout = () => {
        setIsClicked(false);
        setCanShowDropdown(false);
        logout();
        navigate('/login');
    }

    return (
        <div className={`myAccountDropdownModalDiv ${isClicked ? 'activeIcon' : ''}`} ref={myAccountDropdownModalRef}>
            <PersonIcon className={`myAccountDropdownModalMain ${isClicked ? 'active' : ''}`} onClick={() => onIconClick()}/>
            {
                canShowDropdown || user.username ? 
                <div className={`myAccountDropdownModalCont ${isClicked ? 'slideIn' : ''}`}>
                    <span className='myAccountDropdownModalContUsername'>Hi, {user.username}!</span>
                    <div className='navigationBox'>
                        <span className='navigationBoxLink' onClick={() => {onNavigate(`/user/${user.username}/me/info`)}}>My Account</span>
                        <span className='navigationBoxLink' onClick={() => {onNavigate(`/user/${user.username}/booking/unpaid`)}}>My Bookings</span>
                        <span className='navigationBoxLink' onClick={() => {onNavigate(`/booking`)}}>Make a new booking</span>
                    </div>
                    <span className='divider'></span>
                    <span className='logout' onClick={() => onLogout()}>Logout</span>
                </div> : null
            }
        </div>
    );
}//end MyAccountDropdownModal()

export default MyAccountDropdownModal;
