import { useQuery } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { GetTreatmentsForCustomerGql } from "../../../modules/GraphQl/Resolvers/Treatment/getTreatmentsForCustomer.gql";
import TreatmentInterface from "../../../modules/Treatment/Api/Data/TreatmentInterface";
import { useUser } from "../../Context/UserProvider";
import './TreatmentDropdown.scss';

const TreatmentDropdown = (props: any) => {
    const {
        preSelected,
        onSelected
    } = props;
    const { gqlHeader } = useUser();
    const [ treatments, setTreatments ] = useState<TreatmentInterface[]>([]);
    const [ selectedTreatment, setSelectedTreatment ] = useState<any>('');
    const { data, loading, error } = useQuery(GetTreatmentsForCustomerGql, {
        fetchPolicy: 'cache-and-network',
        context: gqlHeader
    });

    useEffect(() => {
        if (preSelected) {
            setSelectedTreatment(JSON.parse(preSelected).id);
        }
    }, []);

    useEffect(() => {
        if (data) {
            if (data.getTreatmentsForCustomer) {
                setTreatments(data.getTreatmentsForCustomer);
            }
        }
    }, [data]);

    const onChange = (event: SelectChangeEvent) => {
        let treatmentId: string = event.target.value as string;
        setSelectedTreatment(treatmentId);
        onSelected(treatments.filter((treatment: TreatmentInterface) => { return treatment.id === treatmentId })[0]);
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="treatment-dropdown">Treatment</InputLabel>
            <Select
                labelId="treatment-dropdown-select-label"
                id="treatment-dropdown-select"
                value={selectedTreatment}
                label="Treatment"
                onChange={onChange}
            >
                {
                    treatments && treatments.map((treatment: TreatmentInterface) => {
                        return (
                            <MenuItem key={treatment.id} value={treatment.id}><img className="treatmentDropdownImage" src={treatment.images[0].src} />{treatment.title}</MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
}//end TreatmentDropdown()

export default TreatmentDropdown;
