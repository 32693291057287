/* eslint-disable react-hooks/exhaustive-deps */
import "../../ActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import ImageUpload from '../../../../Common/ImageUpload/ImageUpload';
import { useMutation } from "@apollo/client";
import { useAdmin } from "../../../../Context/AdminProvider";
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { CreateBanner } from "../../../../../modules/GraphQl/Resolvers/Banner/createBanner.gql";
import { BannerImageInputInterface } from "../../../../../modules/Banner/Api/Data/BannerInterface";
import { useHelper } from "../../../../Context/HelperProvider";

const AdminAddHero = () => {
	const navigate = useNavigate();
	const { utility } = useHelper();
	const { gqlHeader } = useAdmin();

    const [ formData, setFormData ] = useState<any>({
        title: "",
        description: "",
        isEnabled: true,
		images: [""],
    });

	const [ createBanner, { data } ] = useMutation(CreateBanner);

    const [ isDisable, setIsDisable ] = useState(true);
	
	const onSwitchChange = (e: any) => {
        setFormData({
            ...formData,
            isEnabled: e.target.checked
        });
    }

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
	};

    const validateFormData = () => {
		if (formData.title.length <= 0) {
			return true;
		}

        if (formData.images[0].length <= 0) {
            return true;
        }
		return false;
	};

	const onUpload = (position: number, fileObj: any) => {
        let image: BannerImageInputInterface = {
            base64: fileObj.base64,
            contentType: fileObj.type,
            fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
            position: position
        };
        /** @ts-ignore */
        formData.images[position] = image;
        setFormData({...formData});
    }

	const onDelete = (position: number) => {
		setFormData({ ...formData, images: formData.images.filter((image: any) => { return image.position !== position }) });
	};

	const onSubmit = async () => {
		await createBanner({
			variables: {
				...formData,
			},
			context: gqlHeader
		});
	};

    useEffect(() => {
        if (data) {
            if (data.createBanner.status) {
                const navigateTimer = setTimeout(() => {
                    navigate(`/${BACKOFFICE_URL}/content/hero`);
                    return () => clearTimeout(navigateTimer);
                }, 1000);
            }
        }
    }, [data])

	useEffect(() => {
        setIsDisable(validateFormData());
    }, [formData]);


    return (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Add New Banner</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
                        <td className='adminActionHeader'>Enable</td>
                        <td><Switch onChange={onSwitchChange}/></td>
                    </tr>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={0}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange}/>
						</td>
					</tr>
					<tr>
                        <td className='adminActionHeader'>Main thumbnail</td>
                        <td><ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired/></td>
                    </tr>
				</tbody>
			</table>
		</div>
    )
};

export default AdminAddHero;