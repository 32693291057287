import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { KEY_BOOKING_RESCHEDULE } from "../../../../../modules/Storage/Api/ConstantInterface";
import Booking from "../../../../Booking/Booking";
import { useAdmin } from "../../../../Context/AdminProvider";
import { useHelper } from "../../../../Context/HelperProvider";

const RescheduleAdminBooking = () => {
	const navigate = useNavigate();
	const [ data, setData ] = useState<any>();
	const { storage } = useHelper();

	useEffect(() => {
		if (storage.hasItem(KEY_BOOKING_RESCHEDULE)) {
			setData({...JSON.parse(storage.getItem(KEY_BOOKING_RESCHEDULE))});
		} else {
			navigate(-1);
		}
	}, []);

	return (
		<div className="rescheduleBookingDiv">
		{
			data && 
			<Booking 
				isAdmin 
				selectedTreatment={{
					id: data.treatmentId,
					title: data.treatmentName,
					price: data.treatmentPrice,
					images: [
						{
							src: data.treatmentSrc
						}
					]
				}}
				selectedBooking={{
					start: data.start,
					end: data.end,
					id: data.id,
					parentId: data.parentId
				}}
			/>
		}
		</div>
	);
}//end RescheduleAdminBooking()

export default RescheduleAdminBooking;
