import uuid from 'react-uuid';
import Cookies from 'js-cookie'
import { KEY_SESSID } from '../Api/Data/ConstantInterface';
import Utility from '../../Utility/Utility';

/**
 * Session class
 * Manages session storage
 */
export default class Session {

    utility: Utility;

    constructor() {
        this.utility = new Utility();
    }//end constructor()

    /**
     * Set session id
     * @param {*} sessionId 
     */
    setSessionId(sessionId: string)
    {
        Cookies.set(KEY_SESSID, sessionId, {
            path: "/",
            secure: true,
            sameSite: 'strict',
            expires: new Date(new Date().setDate(new Date().getDate() + 1))
        });
    }

    /**
     * Get session id
     * @returns string
     */
    getSessionId()
    {
        if (!this.hasSessionId()) {
            this.setSessionId(this.utility.createUniqueBearerToken(new Date().toISOString()));
        }
        return Cookies.get(KEY_SESSID);
    }//end getSessionId()

    /**
     * Check if there's session id in cookie storage
     * @returns boolean
     */
    hasSessionId()
    {
        let sessionId = Cookies.get(KEY_SESSID);
        if (typeof sessionId !== 'undefined') {
            return true;
        }
        return false;
    }//end hasSessionId()

    setSessionItem(key: any, value: any) {
        Cookies.set(key, value, {
            path: "/",
            secure: true,
            sameSite: 'strict'
        });
    }//end setSessionItem()

    getSessionItem(key: any) {
        return Cookies.get(key);
    }//end getSessionItem()

    /**
     * Check if there's session item in cookie storage
     * @returns boolean
     */
    hasSessionItem(key: any)
    {
        let sessionItem = Cookies.get(key);
        if (typeof sessionItem !== 'undefined') {
            return true;
        }
        return false;
    }//end hasSessionItem()

    removeSessionItem(key: any) {
        Cookies.remove(key);
    }//end removeSessionItem()
}//end class
