import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import Modal from '../../Common/Modal/Modal';
import ModalInterface from '../../Common/Modal/ModalInterface';
import './QrCodeGenerator.scss';
import QrCodeIcon from '@mui/icons-material/QrCode';

const QrCodeGenerator = (props: any) => {
    const {
        data
    } = props;

    const [ generatedQr, setGeneratedQr ] = useState('');
    const [ modal, setModal ] = useState<ModalInterface>({
        isModalActive: false,
        title: data.title
    });

    useEffect(() => {
        generateQrCode();
    }, []);

    const generateQrCode = async () => {
        const imageUrl = await QRCode.toDataURL(data.id);
        setGeneratedQr(imageUrl);
    }

    const onOpenModal = () => {
        setModal({...modal, isModalActive: true});
        data.updateQrItem(data.itemId, data.status);
    }

    const onCloseModal = () => {
        setModal({...modal, isModalActive: false});
        data.updateQrItem('', data.status);
    }

    return (
        <div className='qrCodeGeneratorDiv'>
            {
                generatedQr &&
                <>
                    <span className='qrCodeCta' onClick={() => onOpenModal()}><QrCodeIcon/></span>
                    <Modal 
                        isModalActive={modal.isModalActive}
                        title={modal.title}
                        content={
                            <div className='qrCodeFullDiv'>
                                <img src={generatedQr}/>
                                <span>{data.message}</span>
                            </div>
                        }
                        showCancelIcon
                        onModalClose={() => onCloseModal()}
                    />
                </>
            }
        </div>
    );
}//end QrCodeGenerator()

export default QrCodeGenerator;
