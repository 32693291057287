import { gql } from "@apollo/client";

const GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      username
      phone
      isVerified
      createdAt
    }
  }
`;

export const GetUserByIdGql = GET_USER_BY_ID;
