import "./MultiSelectChip.scss";
import { Box, MenuItem, FormControl, Select, Chip, FormHelperText } from '@mui/material';
import { useState, useEffect } from 'react';
import { GetAllIngredients } from '../../../modules/GraphQl/Resolvers/Product/getAllIngredients.gql';
import { KEY_ADMIN_PRODUCT_INGREDIENT } from '../../../modules/Storage/Api/ConstantInterface';
import { ProductIngredientsInterface } from '../../../modules/Product/Api/Data/ProductInterface';
import { useQuery } from '@apollo/client';
import { useHelper } from "../../Context/HelperProvider";
import { useAdmin } from "../../Context/AdminProvider";

const MultiSelectChip = (props: any) => {
    const { onChange, selectedData, isRequired, errorText } = props;
    const { storage } = useHelper();
    const { gqlHeader } = useAdmin();
    const [ chipData, setChipData ] = useState<any>([]);
    const [ ingredientList, setIngredientList ] = useState<ProductIngredientsInterface[]>([]);

    const { data: ingredientsData } = useQuery(GetAllIngredients, {
      fetchPolicy: "cache-and-network",
      skip: storage.hasItem(KEY_ADMIN_PRODUCT_INGREDIENT),
      context: gqlHeader
    });

    useEffect(() => {
      if (ingredientsData) {
        if (ingredientsData.getAllIngredients) {
          setIngredientList([...ingredientsData.getAllIngredients]);
        }
      }
    }, [ingredientsData]);

    useEffect(() => {
      let tempData: any = [];
      if(selectedData) {
        selectedData.map((singleItem) => {
          tempData.push(singleItem.name);
        })

        setChipData(tempData.filter((item, index) => {
          return tempData.indexOf(item) === index;
        }));
      }
    }, [selectedData])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const handleChange = (event) => {
      const { value } = event.target;
      let temp: any = [];
      let idList: any = [];

      value.map((name) => {
        temp.push(ingredientList.find((ingredient => ingredient.name === name)));
      })

      idList = temp.map((item) => {
        return item.id
      })

      onChange(idList);
      setChipData(
        typeof value === "string" ? value.split[","] : value
      );
    };

    const getStyles = (chipData, key) => {
        return {
            fontWeight:
              chipData.indexOf(key) === -1
                ? "400"
                : "900"
          };
    }

    return (
        <div>
          <FormControl sx={{ width: 300, mb: 2 }} className={isRequired && chipData.length === 0 ? "emptyIngredient" : ""}>
            <Select
              id="multiple-chip"
              multiple
              value={chipData}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {ingredientList.map((singleItem, index) => (
                <MenuItem
                  key={index}
                  value={singleItem.name}
                  style={getStyles(chipData, singleItem.name)}
                >
                  {singleItem.name}
                </MenuItem>
              ))}
            </Select>
            {isRequired && chipData.length === 0 && <FormHelperText sx={{ ml: 0, mr: 0 }}>{errorText}</FormHelperText>}
          </FormControl>
        </div>
    )
}

export default MultiSelectChip;