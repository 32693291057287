import "./SingleTreatmentDetail.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from "../../Common/ActionButton/ActionButton";
import BottomCarousel from "../../Common/BottomCarousel/BottomCarousel";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Breadcrumbs, Link, Modal } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetTreatmentByIdGql } from "../../../modules/GraphQl/Resolvers/Treatment/getTreatmentById.gql";
import { KEY_CUSTOMER_TREATMENTS_BOOKING } from "../../../modules/Storage/Api/ConstantInterface";
import { GetTreatmentsForCustomerGql } from "../../../modules/GraphQl/Resolvers/Treatment/getTreatmentsForCustomer.gql";
import { useHelper } from "../../Context/HelperProvider";

const SingleTreatmentDetail = () => {

    const location = useLocation();
    const locationUrl = location.pathname.split("/");
    const { storage } = useHelper();
    const navigate = useNavigate();
    const { urlKey } = useParams();

    const [ treatments, setTreatments ] = useState<any>({
        price: "",
        title: "",
        images: [],
    });
    const [ filteredTreatment, setFilteredTreatment ] = useState([]);
    const [ image, setImage ] = useState<any>([]);
    const [ open, setOpen ] = useState(false);
    const [ fullSizeImage, setFullSizeImage ] = useState("");

    const { data } = useQuery(GetTreatmentByIdGql, {
        variables: {
			id: urlKey,
        }
    });
    const { data: allData } = useQuery(GetTreatmentsForCustomerGql);

    const handleOpen = (imageSource) => {
        setFullSizeImage(imageSource.props.children.props.src);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const sendBookingDetails = () => {
        let tempTreatment = {id: treatments.id, title: treatments.title, price: treatments.price, images: treatments.images};
        storage.setItem(KEY_CUSTOMER_TREATMENTS_BOOKING, JSON.stringify(tempTreatment));
        navigate(`/booking`);
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: "auto",
        })
    }, [])

    useEffect(() => {
        if (data) {
            if (typeof data.getTreatmentById.id !== "string") {
				navigate(`/treatment`);
			} else {
                setTreatments({...data.getTreatmentById});
                setImage([...data.getTreatmentById.images]);
			}
        }
    }, [data])

    useEffect(() => {
        if (allData) {
            if (allData.getTreatmentsForCustomer) {
                let filtered = [];
                filtered = allData.getTreatmentsForCustomer.filter((item) => {
                    return item.id !== urlKey;
                });
                setFilteredTreatment(filtered);
            }
        }
    }, [allData])

    return (
        <div className="singleTreatmentContainer">
            <Box sx={{ height: "fit-content", background: "#f4eee9", m: "100px 0 40px 0"}}>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{ display: "flex", justifyContent: "center", p: 1, mt: "20px" }}>
                            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbsDiv">
                                <Link href="/">
                                    Home
                                </Link>
                                <Link sx={{ textTransform: "capitalize" }} href={`/${locationUrl[1]}`}>
                                    {locationUrl[1]}
                                </Link>
                                <Typography className="primaryBreadcrumbs">{treatments.title}</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                </Grid>
                <div className="singleTreatmentDiv">
                    <div className="carouselContent">
                        <Carousel 
                            showIndicators={false}
                            showStatus={false}
                            showArrows={false}
                            swipeable={true}
                            dynamicHeight={true}
                            thumbWidth={50}
                            autoFocus={true}
                            useKeyboardArrows={true}
                            onClickItem={(index, item) => {
                                handleOpen(item);
                            }}
                        >
                            {image.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={item.src} alt={index} />
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div className="treatmentContent">
                        <div className="contentHeader">
                            {treatments.title}
                        </div>
                        <div className="contentDetails">
                            <div className="priceDetails">RM {treatments.price}</div>
                            <div className="descriptionDetails">{treatments.description}</div>
                        </div>
                        <ActionButton 
                            isDisable={false}
                            onClick={() => sendBookingDetails()} 
                            title={"Click here to start your booking!"} 
                            label={"Book Now"}
                            canRateLimit={true}
                            rateLimitCount={10}
                            rateLimitFor={3}
                        />
                    </div>
                </div>
                <BottomCarousel data={filteredTreatment} viewItem={"treatments"}/>     
            </Box>
            <Modal open={open} onClose={handleClose} className="modalRootDiv">
                <div className={`${open ? "open modal" : "modal"} modalDiv`}>
                    <div className="modalHeader">
                        <CloseIcon onClick={handleClose}/>
                    </div>
                    <div className="modalImageDiv">
                        <img src={fullSizeImage} className="modalImage"/>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SingleTreatmentDetail;