import './DashboardCard.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const DashboardCard = (props: any) => {
    const {
        title,
        subtitle,
        content,
        url,
        bgIcon,
        isLogo
    } = props;
    return (
        <div className="dashboardCard">
            <span>{title}</span>
            <span>{content}</span>
            <span>{subtitle}</span>
            {url && <span className='url' onClick={() => {window.open(url, '_blank')}}><OpenInNewIcon/></span>}
            <div className={`bgIcon ${isLogo ? 'bgIconLogo' : ''}`}>{bgIcon}</div>
        </div>
    );
}//end DashboardCard()

export default DashboardCard;
