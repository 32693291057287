import "./FAQ.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { GetAllFaqClass } from "../../modules/GraphQl/Resolvers/Faq/getAllFaqClass.gql";
import { GetAllFaq } from "../../modules/GraphQl/Resolvers/Faq/getAllFaq.gql";

const FAQ = () => {

    const [ faqClass, setFaqClass ] = useState<any[]>([]);
    const [ faqData, setFaqData ] = useState<any[]>([]);
    const [ finalData, setFinalData ] = useState<any[]>([]);

    const { data: classData } = useQuery(GetAllFaqClass);
    const { data: allFaqData } = useQuery(GetAllFaq);

    //Faq Class
    useEffect(() => {
        if (classData) {
            if (classData.getAllFaqClass) {
                setFaqClass([...classData.getAllFaqClass]);
            }
        }
    }, [classData])

    //Faq Data
    useEffect(() => {
        if (allFaqData) {
            if (allFaqData.getAllFaq) {
                setFaqData([...allFaqData.getAllFaq]);
            }
        }
    }, [allFaqData])

    useEffect(() => {
        let tempData = compare(faqClass, faqData);
        setFinalData(tempData);
    }, [faqData])

    const compare = (faqClass, faqData) => {
        let finalArray: any = [];
        faqClass.forEach((item) => {
            let temp: any = {...item, details: faqData.filter((data) => {return data.class === item.title}).sort(function(a, b) {
                let DateA = new Date(a.createdAt);
                let DateB = new Date(b.createdAt);
                return Number(DateA) - Number(DateB);
            })};
            finalArray.push(temp);
        })
        
        return finalArray;
    }

    return (
        <div className="faqContentContainer">
            <div className="faqContentDiv">
                <div className="faqHeader">
                    <p>FAQ</p>
                </div>
                <div className="faqAccordionDiv">
                    {finalData && finalData.map((item, index) => {
                        return (
                            item.details &&
                            <Accordion className="accordionOuter" key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="accordionTitle">{item.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails> 
                                    {item.details.map((detailsData, index) => {
                                        return (
                                            <div className="faqDetailsContent" key={index}>
                                                <Typography className="accordionQuestion">{ index + 1 }. {detailsData.title}</Typography>
                                                <Typography className="accordionAnswer">{detailsData.description}</Typography>
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FAQ;