import './Modal.scss';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from '../ActionButton/ActionButton';
import ModalInterface from './ModalInterface';
import { default as MuiModal } from '@mui/material/Modal';

const Modal = (props: ModalInterface) => {
    const {
        isModalActive,
        onModalClose,
        title,
        content,
        action,
        showCancelIcon
    } = props;

    const onActionClick = (actRef: any) => {
        const delay = setTimeout(() => {
          actRef.onClick(actRef.extra);
            onModalClose();
            clearTimeout(delay)
        }, 1500);
    }//end onActionClick()

    return (
        <MuiModal
            className='modalDiv'
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isModalActive}
            onClose={onModalClose}
            closeAfterTransition
            slots={{
                backdrop: Backdrop,
            }}
            slotProps={{
                backdrop: {
                    timeout: 500
                }
            }}
      >
        <Fade in={isModalActive}>
          <div className='modalContDiv'>
            {
                showCancelIcon ? <CloseIcon className='modalContCloseIcon' onClick={onModalClose}/> : null
            }
            <span className='modalContTitle'>{title}</span>
            <>
              {content}
            </>
            {
              action && action.length > 0 && action.map((act: any) => {
                return (<ActionButton
                    label={act.label} 
                    title={act.title} 
                    isDisable={act.isDisable}
                    onClick={() => onActionClick(act)}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3} 
                    styling={'basic-brown'}
                />);
              })
            }
            {
              action && !Array.isArray(action) &&
              <ActionButton
                label={action.label} 
                title={action.title} 
                isDisable={action.isDisable}
                onClick={() => onActionClick(action)}
                canRateLimit={true}
                rateLimitCount={10}
                rateLimitFor={3} 
                styling={'basic-brown'}
            />
            }
          </div>
        </Fade>
      </MuiModal>
    );
}//end Modal()

export default Modal;
