import { BOOKING_STATUS } from "../Api/Data/ConstantInterface"

/**
 * Returns mapped booking status
 * @param statusFromDb 
 * @returns string
 */
const statusMapper = (statusFromDb: string) => {
    return BOOKING_STATUS[statusFromDb];
}//end statusMapper()

export default statusMapper;
