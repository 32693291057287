import { FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './BasicTextInput.scss';
import { useEffect, useState } from 'react';
import Utility from '../../../modules/Utility/Utility';

const BasicTextInput = (props: any) => {
    const {
        identifier,
        label,
        isRequired,
        canShowError,
        errorText,
        onChange,
        hasPassword,
        minLength,
        maxLength,
        prefix,
        isNumber,
        inputType,
        isAutocomplete,
        tabIndex,
        value: parentValue,
        onEnter
    } = props;

    const [ isPassword, setIsPassword ] = useState<boolean>(typeof hasPassword === 'undefined' ? false : true);
    const [ value, setValue ] = useState('');
    const [ inputMaxLength, setInputMaxLength ] = useState(maxLength);
    const [ onInit, setOnInit ] = useState(true);
    const utility: Utility = new Utility();
    let randomId: string = utility.randomElementId();
    let randomElementId: string = `basicTextInput${randomId}${identifier}`;
    let helperTextId: string = `basicTextInputHelperText${randomId}${identifier}`;

    useEffect(() => {
        if (parentValue) {
            setValue((prevValue: any) => prevValue = parentValue);
        }
    }, [parentValue]);

    const onShowPassword = () => {
        setIsPassword(true);
    }

    const onHidePassword = () => {
        setIsPassword(false);
    }

    const onInputChange = (e: any, identifier: string) => {
        let value: string = e.target.value;
        if (isNumber) {
            value = value.replace(/[^\d]+/g, '');
        }

        if (inputType === 'price') {
            value = value.replace(/[^\d|\.]+/g, '');
            if (value.includes('.')) {
                let valueArr = value.split('.');
                value = `${valueArr[0]}.${valueArr[1].slice(0, 2)}`
            }
        }

        if (inputType === 'phone') {
            setInputMaxLength(maxLength);
            if (value.length >= 3) {
                let phone: number = parseInt(value.substring(0, 3));
                if (phone === 111) {
                    setInputMaxLength(10);
                }
            }
        }

        if (inputType === 'username') {
            value = utility.escapeSymbol(value);
        }

        value = utility.escapeEmoji(value);

        if (onInit) {
            setOnInit(false);
        }

        setValue(value);
        onChange(value, identifier);
    }

    return (
        <div className="basicTextInputDiv">
            { label && <span className='basicTextInputLabel'>{label}</span> }
            <OutlinedInput 
                fullWidth 
                autoComplete={isAutocomplete ? 'on' : 'new-password'}
                id={randomElementId}
                required={isRequired}
                error={onInit ? false : canShowError ? true : false}
                onInput={(e: any) => onInputChange(e, identifier)}
                onTouchEnd={(e: any) => onInputChange(e, identifier)}
                onChange={(e: any) => onInputChange(e, identifier)}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        if (onEnter instanceof Function) {
                            e.preventDefault();
                            onEnter();
                        }
                    }
                }}
                value={value}
                className="basicTextInput"
                tabIndex={tabIndex}
                inputProps={
                    {
                        minLength: minLength,
                        maxLength: inputMaxLength,
                        placeholder: parentValue ?? errorText,
                    }
                }
                type={isPassword ? 'password' : 'text' }
                startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
                endAdornment={
                    hasPassword ?
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onShowPassword}
                            onMouseDown={onHidePassword}
                            edge="end"
                        >
                            {isPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment> : null
                }
            />
            {
                canShowError && <FormHelperText id={helperTextId}>{onInit ? ' ' : canShowError ? errorText : ' '}</FormHelperText>
            }
        </div>
    );
}

export default BasicTextInput;
