import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import { useEffect, useState } from "react";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import "./Admin.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import { GetAllAdminsGql } from "../../../../modules/GraphQl/Resolvers/Admin/getAllAdmins.gql";
import { DeleteAdminByIdGql } from "../../../../modules/GraphQl/Resolvers/Admin/deleteAdminById.gql";
import { AdminInterface } from "../../../../modules/Api/Data/DocumentInterface";
import { useHelper } from "../../../Context/HelperProvider";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminAdmin = () => {
	const { utility } = useHelper();
	const { aid, gqlHeader } = useAdmin();
	const navigate = useNavigate();

	const { data, loading, error, refetch } = useQuery(GetAllAdminsGql, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [
		deleteAdmin,
		{ data: deleteData, loading: deleteLoading, error: deleteError },
	] = useMutation(DeleteAdminByIdGql);

	const [admin, setAdmins] = useState<AdminInterface[]>([]);
	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

	const columns: GridColDef[] = [
		{
			field: "username",
			headerName: "Admin Username",
			width: 150,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "createdAt",
			headerName: "Created at",
			sortable: true,
			editable: false,
			width: 210,
			renderCell: (params: GridRenderCellParams) => {
				return `${utility.dateFormatter(new Date(params.row.createdAt))}`;
			},
			align: "left",
		},
		{
			field: "delete",
			headerName: "Action",
			sortable: false,
			editable: false,
			width: 150,
			renderCell: (params: GridRenderCellParams) => {
				return (
					params.row.id !== aid ? 
                    <span
						className="actionTab"
						onClick={() => {
							deleteItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span> : 
					<>
						You are logged in.
					</>
				);
			},
			align: "left",
		},
	];

	useEffect(() => {
		if (data) {
			if (data.getAllAdmins) {
				setAdmins([...data.getAllAdmins]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (deleteData) {
			if (deleteData.deleteAdminById.status) {
				refetch();
			}
		}
	}, [deleteData]);

	const deleteItemPrompt = (admin: AdminInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${admin.username}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected admin - ${admin.username}.`,
				onClick: deleteItem,
				extra: admin,
			},
		});
	};

	const deleteItem = async (admin: AdminInterface) => {
		await deleteAdmin({
			variables: {
				id: admin.id,
			},
			context: gqlHeader
		});
	};

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Admins</span>
                <span></span>
                <ActionButton 
					label={"Create New Admin"}
					title={"Click to create a new admin."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/user/admin/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={admin}
						columns={columns}
						pageSize={5}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
	);
}; //end AdminAdmin()

export default AdminAdmin;
