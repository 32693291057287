export enum BOOKING_STATUS {
    'new'                 = 'New',                 //Booking is created
    'unpaid'              = 'Unpaid',              //Payment is not completed
    'paid'                = 'Paid',                //Payment is completed
    'confirmed'           = 'Confirmed',           //The booking is confirmed by customer (QR Code scanned by admin upon arrival)
    'in_progress'         = 'In Progress',         //The booking treatment has been started
    'absent'              = 'Absent',              //Customer failed to showup on booked timeslot
    'rescheduled'         = 'Rescheduled',         //Rescheduled booking
    'cancelled'           = 'Cancelled',           //Booking cancelled by customer
    'refunded'            = 'Refunded',            //If booking is paid, proceed to refund
    'completed'           = 'Completed'            //Booking has ended/completed
}

export const BOOKING_STATUS_SELECTION = [
    {
        key: "unpaid",
        value: [
            'unpaid',
            'paid',
            'confirmed',
            'absent',
            'in_progress',
            'completed'
        ]
    },
    {
        key: "paid",
        value: [
            'paid',           
            'confirmed',
            'absent',
            'in_progress',
            'completed'
        ]
    },
    {
        key: "in_progress",
        value: [
            'in_progress',
            'completed'
        ]
    },
    {
        key: "rescheduled",
        value: [
            'rescheduled',
            'absent',
            'in_progress',
            'completed'
        ]
    },
    {
        key: "confirmed",
        value: [
            'confirmed',
            'in_progress',
            'completed'
        ]
    },
    {
        key: "absent",
        value: [
            'absent',
        ]
    },
    {
        key: "cancelled",
        value: [
            'cancelled'
        ]
    },
    {
        key: "refunded",
        value: [
            'refunded'
        ]
    },
    {
        key: "completed",
        value: [
            'completed'
        ]
    }
];