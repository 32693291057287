import { gql } from "@apollo/client";

const CREATE_REFUND = gql`
  mutation createRefund($paymentId: String!, $bookingId: String!, $bookingItemId: String!, $amount: String!) {
    createRefund(paymentId: $paymentId, bookingId: $bookingId, bookingItemId: $bookingItemId, amount: $amount) {
      status
      message
    }
  }
`;
export const CreateRefundGql = CREATE_REFUND;
