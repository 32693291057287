import "./AboutUs.scss";
import aboutUs01 from "../../../assets/images/about-us-01.jpg";
import aboutUs02 from "../../../assets/images/about-us-02.jpg";
import { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";

const AboutUs = () => {
	const [missions, setMissions] = useState([
		{
			position: "First",
			content:
				"We're passionate about creating strong corporate values and building meaningful partnerships with like-minded individuals.",
		},
		{
			position: "Second",
			content:
				"Our mission is to provide you with reliable and honest services, all with the ultimate goal of ensuring your complete satisfaction.",
		},
		{
			position: "Third",
			content:
				"We take great care in preparing our service attendants and products, ensuring that they meet the highest standards before being delivered to market.",
		},
	]);

	const [visions, setVisions] = useState([
		{
			position: "First",
			content:
				"We're dedicated to making our products accessible to everyone, and hope that they will be enjoyed by all.",
		},
		{
			position: "Second",
			content:
				"Our goal is to lead the way in scalp care, setting the standard for excellence in the industry.",
		},
		{
			position: "Third",
			content:
				"Our commitment to providing customers with the best possible experience means using only the purest, most natural ingredients and formulas available.",
		},
		{
			position: "Fourth",
			content:
				"Our company believes that hair care should be affordable for all, which is why we offer fair pricing on all of our products and services.",
		},
	]);

	const [missionValue, setMissionValue] = useState(0);

	const handleChange = (event, newValue) => {
		setMissionValue(newValue);
	};

	const [visionValue, setVisionValue] = useState(0);

	const visionHandleChange = (event, newValue) => {
		setVisionValue(newValue);
	};

	const TabPanel = (props) => {
		const { children, index, value } = props;
		return (
			<div role="tabpanel" hidden={value !== index}>
				{value === index && (
					<Box
						sx={{ p: 10, width: "400px", margin: "0 auto" }}
						className="tabPanel"
					>
						<Typography className="missionInfo">{children}</Typography>
					</Box>
				)}
			</div>
		);
	};

	return (
		<div className="aboutUsContainer">
			<div className="missionContainer">
				<h3>Mission</h3>
				<div className="missionContent">
					<img src={aboutUs01}></img>
					<Box sx={{ width: "70%" }}>
						<Tabs value={missionValue} onChange={handleChange} centered>
							<Tab label={missions[0].position + " Mission"} />
							<Tab label={missions[1].position + " Mission"} />
							<Tab label={missions[2].position + " Mission"} />
						</Tabs>
						{missions.map((mission, index) => {
							return (
								<TabPanel value={missionValue} index={index} key={index}>
									{mission.content}
								</TabPanel>
							);
						})}
					</Box>
				</div>
			</div>
			<div className="visionContainer">
				<h3>Vision</h3>
				<div className="missionContent vision">
					<Box sx={{ width: "70%" }}>
						<Tabs value={visionValue} onChange={visionHandleChange} centered>
							<Tab label={visions[0].position + " Vision"} />
							<Tab label={visions[1].position + " Vision"} />
							<Tab label={visions[2].position + " Vision"} />
							<Tab label={visions[3].position + " Vision"} />
						</Tabs>
						{visions.map((vision, index) => {
							return (
								<TabPanel value={visionValue} index={index} key={index}>
									{vision.content}
								</TabPanel>
							);
						})}
					</Box>
					<img src={aboutUs02}></img>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
