import { gql } from "@apollo/client";

const GET_INGREDIENT_BY_ID = gql`
	query getIngredientById($id: String!) {
		getIngredientById(id: $id) {
			id
			name
			description
		}
	}
`;

export const GetIngredientById = GET_INGREDIENT_BY_ID;
