import { gql } from "@apollo/client";

const GET_ALL_BANNER = gql`
	query getAllBanner {
		getAllBanner {
			id
			isEnabled
			title
			description
			images {
				fileName
				src
				position
			}
		}
	}
`;

export const GetAllBanner = GET_ALL_BANNER;
