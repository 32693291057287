import { gql } from "@apollo/client";

const CREATE_PRODUCT = gql`
    mutation createProduct($title: String!, $isEnabled: Boolean!, $price: String!, $description: String!, $capacity: String!, $urlKey: String!, $ingredients: [String]!, $functionalities: [String]!, $thumbnail: [ProductImageInput]!, $usageDirection: [String]!) {
        createProduct(title: $title, isEnabled: $isEnabled, price: $price, description: $description, capacity: $capacity, urlKey: $urlKey, ingredients: $ingredients, functionalities: $functionalities, thumbnail: $thumbnail, usageDirection: $usageDirection) {
            status
            message
        }
    }
`;

export const CreateProduct = CREATE_PRODUCT;
