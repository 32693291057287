import { FpxBankElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../Common/ActionButton/ActionButton';
import { useHelper } from '../Context/HelperProvider';
import './StripeCheckoutForm.scss';

const StripeCheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [ isProcessing, setIsProcessing ] = useState(false);
    
    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/payment/redirect`,
            },
            redirect: 'always'
        });

        setIsProcessing(false);
    }

    return (
        <div className='checkoutFormDiv'>
            <form>
                <PaymentElement 
                    options={{
                        layout: {
                            type: 'accordion',
                        },
                    }}
                />
                <FpxBankElement/>
                <ActionButton 
                    label={`${isProcessing ? 'Processing' : 'Pay Now'}`} 
                    title={'Click to pay now.'} 
                    isDisable={stripe ? isProcessing ? true : false : true}
                    onClick={onSubmit}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
            </form>
        </div>
    );
};

export default StripeCheckoutForm;