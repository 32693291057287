import "./TestimonialList.scss";

const TestimonialList = (testimonial) => {

    const { fields0, fields1 } = testimonial;

    return (
        <div className="singleTestDiv">
            <div className="singleTestContent singleTestBefore">
                <span>Before</span>
                <img src={fields0} alt="testimonial-before"/>
            </div>
            <div className="singleTestContent singleTestAfter">
                <span>After</span>
                <img src={fields1} alt="testimonial-after"/>
            </div>
        </div>
    )
}

export default TestimonialList;