import { PAYMENT_METHOD, PAYMENT_STATUS } from "../Api/Data/PaymentInterface";

/**
 * Returns mapped payment status
 * @param status
 * @returns string
 */
export const paymentStatusMapper = (status: string) => {
    return PAYMENT_STATUS[status];
}//end paymentStatusMapper()

/**
 * Returns mapped payment method
 * @param method
 * @returns string
 */
export const paymentMethodMapper = (method: string) => {
    return PAYMENT_METHOD[method];
}//end paymentMethodMapper()
