import { gql } from "@apollo/client";

const GET_DASHBOARD_INFO = gql`
  query getDashboardInfo {
    getDashboardInfo {
      booking_count
      user_count
      product_count
      treatment_count
    }
  }
`;

export const GetDashboardInfoGql = GET_DASHBOARD_INFO;
