import { useEffect, useState, useRef } from 'react';
import UploadPlaceholder from '../../../assets/images/upload-placeholder.jpg';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete";
import './ImageUpload.scss';

const DEFAULT_ERROR_MSG = 'Only jpg, jpeg, png & bmp are allowed.';

const ImageUpload = (props: any) => {

    const {
        onUpload,
        onDelete,
        position,
        imgSrc,
        isRequired
    } = props;

    const [ uploadSrc, setUploadSrc ] = useState(imgSrc ?? UploadPlaceholder);
    const fileBase64Ref = useRef<HTMLInputElement>(null);
    const [ isError, setIsError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(DEFAULT_ERROR_MSG);
    const [ isDragActive, setIsDragActive ] = useState(false);

    useEffect(() => {
        if (imgSrc) {
            setUploadSrc((prevUploadSrc: any) => prevUploadSrc = imgSrc);
        }
    }, []);

    const onClick = () => {
        /** @ts-ignore */
        fileBase64Ref.current?.click();
    }

    const onDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setIsDragActive(true);
        } else if (e.type === "dragleave") {
            setIsDragActive(false);
        }
    }

    const onDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        convertToBase64(e, true);
    }

    const doneConverted = (fileObj: any) => {
        onUpload(position, fileObj);
    }

    const validateExtension = (filePath: any) => {
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.bmp)$/i;
        if (!allowedExtensions.exec(filePath)) {
            return false;
        }
        return true;
    }

    const deleteImg = () => {
        if (isRequired) {
            setIsError(true);
            setErrorMsg('Image/Thumbnail is required!');
        } else {
            setIsError(false);
        }
        
        if (fileBase64Ref) {
            if (fileBase64Ref.current) {
                fileBase64Ref.current.value = '';
            }
        }

        onDelete(position);
        setUploadSrc(UploadPlaceholder);
    }

    /**
     * Original code for converting image to base 64: 
     * https://github.com/BosNaufal/react-file-base64/blob/master/src/js/components/react-file-base64.js
     * @param e 
     */
    const convertToBase64 = (e: any, isDragAndDrop: boolean = false) => {

        let files: any = e.target.files;

        if (isDragAndDrop) {
            files = e.dataTransfer.files;
        }
    
        if (files && files[0]) {
            if (validateExtension(files[0].name ?? '')) {
                setIsError(false);
                // Process each file
                var allFiles: any = [];
                for (var i = 0; i < files.length; i++) {
                
                    let file = files[i];
                
                    // Make new FileReader
                    let reader = new FileReader();
                
                    // Convert the file to base64 text
                    reader.readAsDataURL(file);
                
                    // on reader load somthing...
                    reader.onload = () => {
                
                        // Make a fileInfo Object
                        let fileInfo = {
                        name: file.name,
                        type: file.type,
                        size: Math.round(file.size / 1000) + ' kB',
                        base64: reader.result,
                        file: file,
                        };
                
                        // Push it to the state
                        allFiles.push(fileInfo);
    
                        // If all files have been proceed
                        if(allFiles.length == files.length){
                            // Apply Callback function
                            doneConverted(allFiles[0])
                        }
                    } // reader.onload
                    if (isDragAndDrop) {
                        setUploadSrc(URL.createObjectURL(e.dataTransfer.files[0]));
                    } else {
                        setUploadSrc(URL.createObjectURL(e.target.files[0]));
                    }
                } // for
            } else {
                setIsError(true);
                setErrorMsg(DEFAULT_ERROR_MSG);
            }
        } else {
            setIsError(true);
            setErrorMsg('Unable to process image. Please try again.');
        }
    }

    return (
        <div className='imageUploadMainDiv'>
            <form 
                onClick={() => onClick()} 
                onDragEnter={(e: any) => onDrag(e)}
                onDragLeave={(e: any) => onDrag(e)}
                onDragOver={(e: any) => onDrag(e)}
                onDrop={(e: any) => onDrop(e)}
            >
                <div className={`imageUploadDiv ${isError ? 'imgRestrictionBorder' : ''}`}>
                    <img className='imageUploadImg' src={uploadSrc} />
                    <input type="file" className='imageUploadInput' ref={fileBase64Ref} multiple={false} onChange={(e: any) => convertToBase64(e)}/>
                </div>
                {
                    isError ? 
                    <span className='imgRestrictionDiv'><InfoIcon fontSize='small'/>&nbsp;{errorMsg}</span> 
                    : null
                }
            </form>
            {
                uploadSrc !== UploadPlaceholder && <DeleteIcon className='imageUploadRemove' fontSize='medium' onClick={() => {deleteImg()}}/>
            }
        </div>
    );
}//end ImageUpload()

export default ImageUpload;
