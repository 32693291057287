import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import { UserInterface } from "../../../../modules/Api/Data/DocumentInterface";
import { GetAllUsersGql } from "../../../../modules/GraphQl/Resolvers/User/getAllUsers.gql";
import EditIcon from "@mui/icons-material/Edit";
import { useHelper } from "../../../Context/HelperProvider";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminCustomer = () => {
	const { utility } = useHelper();
	const { gqlHeader } = useAdmin();
    const navigate = useNavigate();

	const { data, loading, error } = useQuery(GetAllUsersGql, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [ users, setUsers ] = useState<UserInterface[]>([]);
	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

	const columns: GridColDef[] = [
		{
			field: "username",
			headerName: "Username",
			width: 150,
			sortable: true,
			editable: false,
			align: "left",
		},
        {
			field: "phone",
			headerName: "Phone",
			width: 150,
			sortable: true,
			editable: false,
			align: "left",
		},
        {
			field: "isVerified",
			headerName: "Is customer verified?",
			sortable: true,
			editable: false,
			width: 180,
            renderCell: (params: GridRenderCellParams) => {
				return params.row.isVerified ? 'Yes' : 'No';
			},
			align: "left",
		},
		{
			field: "createdAt",
			headerName: "Created at",
			sortable: true,
			editable: false,
			width: 210,
			renderCell: (params: GridRenderCellParams) => {
				return `${utility.dateFormatter(new Date(params.row.createdAt))}`;
			},
			align: "left",
		},
        {
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							navigate(
								`/${BACKOFFICE_URL}/user/customer/edit/${params.row.id}`
							);
						}}
					>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
			align: "left",
		}
	];

	useEffect(() => {
		if (data) {
			if (data.getAllUsers) {
				setUsers([...data.getAllUsers]);
			}
		}
	}, [data]);

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Customers</span>
                <span></span>
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={users}
						columns={columns}
						pageSize={5}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
	);
}; //end AdminCustomer()

export default AdminCustomer;
