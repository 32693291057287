import { useEffect, useState } from "react";
import AdminProductVariationItem from "./AdminProductVariationItem";

interface VariantItem {
    price: string,
    capacity: string
}

const AdminProductVariation = (props: any) => {
    const {
        price,
        capacity,
        onVariantChange
    } = props;

    const [ variantList, setVariantList ] = useState<VariantItem[]>([]);

    const addFunction = () => {
		setVariantList([...variantList, {
            price: "",
            capacity: ""
        }])
	}

	const removeFunction = (index: number) => {
		const list = variantList;
		list.splice(index, 1);
		setVariantList([...variantList]);
	}

    useEffect(() => {
        const priceArr: any = price.split(",");
        const capacityArr: any = capacity.split(",");
        let newVariantList: any = [];
        priceArr.map((price: any, i: number) => {
            newVariantList.push({
                price: price,
                capacity: capacityArr[i]
            })
        });
        setVariantList([...newVariantList]);
    }, []);

    const onItemChange = (item: VariantItem, i: number) => {
        variantList[i] = item;
        setVariantList([...variantList]);
    }

    useEffect(() => {
        onVariantChange(variantList);
    }, [variantList]);

    return (
        <tr>
            <td className="adminActionHeader">Variations</td>
            <td>
                {
                    variantList && variantList.map((variant: any, i: number) => {
                        return (
                        <div key={i}>
                            <AdminProductVariationItem 
                                key={i}
                                index={i}
                                price={variant.price}
                                capacity={variant.capacity}
                                onItemChange={onItemChange}
                            />
                            <div className="first-division">
                                {variantList.length - 1 === i && (
                                    <button type="button" className="add-btn" onClick={addFunction}>
                                        <span>Add Variant</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {variantList.length > 1 && (
                                    <button type="button" className="remove-btn" onClick={() => removeFunction(i)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        )
                    })
                }
            </td>
        </tr>
    );
}//end AdminProductVariation()

export default AdminProductVariation;
