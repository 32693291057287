import { useQuery } from "@apollo/client";
import { GetDashboardInfoGql } from "../../../modules/GraphQl/Resolvers/Dashboard/getDashboardInfo.gql";
import DashboardCard from "./DashboardCard/DashboardCard";
import './Dashboard.scss';
import { BACKOFFICE_URL } from "../../../modules/Api/Data/ConstantInterface";
import EventIcon from "@mui/icons-material/Event";
import InventoryIcon from "@mui/icons-material/Inventory";
import PersonIcon from "@mui/icons-material/Person";
import ScienceIcon from "@mui/icons-material/Science";
import { ReactComponent as StripeLogo } from "../../../assets/logo/stripe_logo_bw.svg";
import { useAdmin } from "../../Context/AdminProvider";

const Dashboard = () => {

    const { gqlHeader, coreConfig } = useAdmin();
    const { data } = useQuery(
        GetDashboardInfoGql,
        {
            context: gqlHeader
        }
    );

    return (
        data && data.getDashboardInfo &&
        <div className="dashboardDiv">
            <DashboardCard
                title={'Total Booking(s)'}
                subtitle={'Excluding Unpaid booking(s)'}
                content={data.getDashboardInfo.booking_count}
                url={`/${BACKOFFICE_URL}/core/booking`}
                bgIcon={<EventIcon />}
            />
            <DashboardCard
                title={'Total Customer(s)'}
                subtitle={''}
                content={data.getDashboardInfo.user_count}
                url={`/${BACKOFFICE_URL}/user/customer`}
                bgIcon={<PersonIcon />}
            />
            <DashboardCard
                title={'Total Product(s)'}
                subtitle={''}
                content={data.getDashboardInfo.product_count}
                url={`/${BACKOFFICE_URL}/core/product`}
                bgIcon={<InventoryIcon />}
            />
            <DashboardCard
                title={'Total Treatment(s)'}
                subtitle={''}
                content={data.getDashboardInfo.treatment_count}
                url={`/${BACKOFFICE_URL}/core/treatment`}
                bgIcon={<ScienceIcon />}
            />
            <DashboardCard
                title={'My Revenue(s)'}
                subtitle={'View it on Stripe.'}
                content={''}
                url={`https://dashboard.stripe.com/${coreConfig?.stripe?.isProduction ? '' : 'test/'}payments`}
                bgIcon={<StripeLogo />}
                isLogo
            />
        </div>
    );
}//end Dashboard()

export default Dashboard;
