import { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import './ActionButton.scss';
import './FormBottomButton.scss';
import './IconTransparentButton.scss';
import './ActionButtonBrown.scss';
import './ActionButtonBrownOutline.scss';
import './ActionButtonWhatsapp.scss';
import Session from '../../../modules/Session/Core/Session';
import { KEY_RATE_LIMITED } from '../../../modules/Session/Api/Data/ConstantInterface';
import Timer from '../Timer/Timer';
import ActionButtonInterface from './ActionButtonInterface';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Utility from '../../../modules/Utility/Utility';

const ActionButton = (props: ActionButtonInterface) => {
    const {
        isDisable,
        onClick,
        label,
        title,
        icon, //For styling = icon-transparent ONLY
        tabIndex,
        canRateLimit,
        rateLimitCount, // Counter to hit rate limit
        rateLimitFor, // Rate limit for how many minutes
        canBuffer,
        bufferId,
        bufferTitle,
        bufferFor, //Buffer for how many minutes
        styling
    } = props;

    const session: Session = new Session();
    const utility: Utility = new Utility();
    const [ isClicked, setIsClicked ] = useState(false);
    const [ rateLimit, setRateLimit ] = useState(1);
    const [ isRateLimited, setIsRateLimited ] = useState(false);
    const [ isBuffering, setIsBuffering ] = useState(false);
    const [ timerEnd, setTimerEnd ] = useState<any>(0);
    const [ buttonClasses, setButtonClasses ] = useState({
        actionButtonDisabled: 'actionButtonDisabled',
        actionButton: 'actionButton',
        actionButtonSubmitting: 'actionButtonSubmitting'
    });

    useEffect(() => {
        if (session.getSessionItem(KEY_RATE_LIMITED) && canRateLimit) {
            setIsRateLimited(true);
            setTimerEnd(session.getSessionItem(KEY_RATE_LIMITED));
        }

        if (session.getSessionItem(bufferId) && canBuffer) {
            setIsBuffering(true);
            setTimerEnd(session.getSessionItem(bufferId));
        }

        switch (styling) {
            case 'icon-transparent':
                buttonClasses.actionButton = 'iconTransparentButton';
                buttonClasses.actionButtonDisabled = 'iconTransparentButtonDisabled';
                buttonClasses.actionButtonSubmitting = 'iconTransparentButtonSubmitting';
                break;
            case 'form-bottom':
                buttonClasses.actionButton = 'formBottomButton';
                buttonClasses.actionButtonDisabled = 'formBottomButtonDisabled';
                buttonClasses.actionButtonSubmitting = 'formBottomButtonSubmitting';
                break;
            case 'basic-brown':
                buttonClasses.actionButton = 'actionButtonBrown';
                buttonClasses.actionButtonDisabled = 'actionButtonBrownDisabled';
                buttonClasses.actionButtonSubmitting = 'actionButtonBrownSubmitting';
                break;
            case 'basic-brown-outline':
                buttonClasses.actionButton = 'actionButtonBrownOutline';
                buttonClasses.actionButtonDisabled = 'actionButtonBrownOutlineDisabled';
                buttonClasses.actionButtonSubmitting = 'actionButtonBrownOutlineSubmitting';
                break;
            case 'whatsapp':
                buttonClasses.actionButton = 'actionButtonWhatsapp';
                buttonClasses.actionButtonDisabled = 'actionButtonWhatsappDisabled';
                buttonClasses.actionButtonSubmitting = 'actionButtonWhatsappSubmitting';
                break;
            case 'basic':
            default:
                buttonClasses.actionButton = 'actionButton';
                buttonClasses.actionButtonDisabled = 'actionButtonDisabled';
                buttonClasses.actionButtonSubmitting = 'actionButtonSubmitting';
                break;
        }
        setButtonClasses({...buttonClasses});
    }, []);

    const btnOnClick = () => {
        if (isDisable || isRateLimited || isBuffering) {
            return;
        }
        
        if (!isClicked) {
            setIsClicked(true);
        }

        if (canRateLimit) {
            /** @ts-ignore */
            if (rateLimit < rateLimitCount) {
                setRateLimit(prevRateLimit => prevRateLimit + 1);
            } else {
                let endDate: Date = new Date();
                /** @ts-ignore */
                endDate.setMinutes(endDate.getMinutes() + rateLimitFor);
                session.setSessionItem(KEY_RATE_LIMITED, endDate.toISOString());
                setIsRateLimited(true);
                setTimerEnd(session.getSessionItem(KEY_RATE_LIMITED));
                setIsClicked(false);
            }
        }

        if (canBuffer) {
            let endDate: Date = new Date();
            /** @ts-ignore */
            endDate.setMinutes(endDate.getMinutes() + bufferFor);
            session.setSessionItem(bufferId, endDate.toISOString());
            setIsBuffering(true);
            setTimerEnd(session.getSessionItem(bufferId));
            setIsClicked(false);
        }

        if (!isRateLimited || !isClicked) {
            onClick();
        }
    }

    const isCompleted = () => {
        if (canRateLimit) {
            setIsRateLimited(false);
            setRateLimit(1);
            session.removeSessionItem(KEY_RATE_LIMITED);
        }

        if (bufferId) {
            setIsBuffering(false);
            session.removeSessionItem(bufferId);
        }

        setTimerEnd(0);
        setIsClicked(false);
    }

    return (
        !utility.isDeviceOnline() ? 
        <div className={buttonClasses.actionButtonDisabled}>
            <span className='actionButtonOffline'><WifiOffIcon/>&nbsp;You are offline.</span>
        </div> :
        <div className={`${isDisable || isClicked || isRateLimited || isBuffering ? `${buttonClasses.actionButtonDisabled} ${buttonClasses.actionButton}` : buttonClasses.actionButton}`} onClick={btnOnClick} tabIndex={tabIndex}>
            { isBuffering ? <span>Wait for</span> : null }
            {
                canRateLimit || canBuffer ? 
                <Timer end={timerEnd} isCompleted={isCompleted}/> :
                null
            }
            {
                styling === 'icon-transparent' ? 
                <span title={title} className='iconTransparentCont'>
                    <span className='icon'>{icon}</span>
                    <span className='label'>{label}</span>
                </span> :
                styling === 'whatsapp' ? 
                <span title={title} className='whatsappCont'>
                    <span className='label'>{label}</span>
                    <span className='icon'>{icon}</span>
                </span> :
                <span title={isRateLimited ? 'You had been rate limited.' : isBuffering ? bufferTitle : title}>
                    {isRateLimited ? 'You had been rate limited.' : isBuffering ? bufferTitle : label}
                </span>
            }
            {
                isClicked ?          
                    <span className={buttonClasses.actionButtonSubmitting}>
                        <LinearProgress/>
                    </span> : null
            }
        </div>
    );
}//end ActionButton()

export default ActionButton;
