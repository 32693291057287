import "../AdminCore.scss";
import ProductInterface from "../../../../modules/Product/Api/Data/ProductInterface";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { GetAllProducts } from "../../../../modules/GraphQl/Resolvers/Product/getAllProducts.gql";
import { RemoveProductById } from "../../../../modules/GraphQl/Resolvers/Product/removeProductById.gql";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../../Context/AdminProvider";
import { useHelper } from "../../../Context/HelperProvider";

const AdminProduct = () => {
	const { utility } = useHelper();
	const { gqlHeader } = useAdmin();
	const navigate = useNavigate();

	const { data, refetch } = useQuery(GetAllProducts, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});
	const [removeProduct, { data: deleteData }] = useMutation(RemoveProductById);
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

	const columns: GridColDef[] = [
		{
			field: "thumbnail",
			headerName: "Thumbnail",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<div className="gridTableImgDiv">
						{params.row.thumbnail.length > 0 && (
							<img className="gridTableImg" src={params.row.thumbnail[0].src} alt={"productImage"} />
						)}
					</div>
				);
			},
		},
		{
			field: "title",
			headerName: "Title",
			width: 200,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "price",
			headerName: "Price",
			width: 100,
			sortable: true,
			editable: false,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				let newPrice: string[] = [];
				params.row.price.split(",").forEach((price: string) => {
					newPrice.push(`RM ${price}`);
				});
				return <span>{newPrice.toString().replaceAll(",", " / ")}</span>
			},
			sortComparator: utility.dataGridPriceComparator,
		},
		{
			field: "description",
			headerName: "Description",
			width: 350,
			sortable: false,
			editable: false,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				return <span> {params.row.description} </span>;
			},
		},
		{
			field: "isEnabled",
			headerName: "Is Enabled ?",
			sortable: false,
			editable: false,
			width: 100,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				return `${params.row.isEnabled === true ? "Yes" : "No"}`;
			},
		},
		{
			field: "capacity",
			headerName: "Capacity",
			sortable: false,
			editable: false,
			width: 100,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				return <span>{params?.row?.capacity && params.row.capacity.replaceAll(",", " / ")}</span>
			},
		},
		{
			field: "ingredients",
			headerName: "Ingredients",
			sortable: false,
			editable: false,
			width: 200,
			align: "left",
			renderCell: (params: GridRenderCellParams) => {
				return params?.row?.ingredients && params.row.ingredients
					.map((ingredient) => {
						return utility.capitalizedWord(ingredient.name);
					})
					.join(", ");
			},
		},
		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 100,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span className="actionTab" onClick={() => {
						navigate(`/${BACKOFFICE_URL}/core/product/edit/${params.row.id}`);
					}}>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							removeItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span>
				);
			},
			align: "left",
		},
	];

	useEffect(() => {
		if (data) {
			if (data.getAllProducts) {
				setProducts([...data.getAllProducts]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (deleteData) {
			if (deleteData.removeProductById.status) {
				refetch();
			}
		}
	}, [deleteData]);

	const removeItemPrompt = (product: ProductInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${product.title}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected product - ${product.title}.`,
				onClick: removeItem,
				extra: product,
			},
		});
	};

	const removeItem = async (product: ProductInterface) => {
		await removeProduct({
			variables: {
				id: product.id,
			},
			context: gqlHeader
		});
	};

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionHeading">
				<span>Manage Products</span>
				<span></span>
				<ActionButton
					label={"Add New Product"}
					title={"Click to add a new product."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/core/product/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
					styling={'basic-brown'}
				/>
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={products}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
	);
}; //end AdminProduct()

export default AdminProduct;
