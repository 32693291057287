import "../../CoreActionAdmin.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ProductIngredientsInterface } from "../../../../../modules/Product/Api/Data/ProductInterface";
import { GetIngredientById } from "../../../../../modules/GraphQl/Resolvers/Product/getIngredientById.gql";
import { UpdateIngredientById } from "../../../../../modules/GraphQl/Resolvers/Product/updateIngredientById.gql";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import { useAdmin } from "../../../../Context/AdminProvider";

const AdminEditIngredient = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { gqlHeader } = useAdmin();

	const [formData, setFormData] = useState<ProductIngredientsInterface>({
		name: "",
		description: "",
	});

	const { data: initData } = useQuery(GetIngredientById, {
		variables: {
			id: location.pathname.split("/").pop(),
		},
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [ updateIngredient, { data: updateData } ] = useMutation(UpdateIngredientById);
	const [isDisable, setIsDisable] = useState(true);
	
	const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		setIsDisable(validateFormData());
	};

	const validateFormData = () => {
		if (formData.name.length <= 0) {
			return true;
		}
		return false;
	};

	const onSubmit = async () => {
		await updateIngredient({
			variables: {
				id: location.pathname.split("/").pop(),
				data: formData,
			},
			context: gqlHeader
		})
	};

	useEffect(() => {
		if (initData) {
			if (typeof initData.getIngredientById.id !== "string") {
				navigate(`/${BACKOFFICE_URL}/core/ingredient`);
			} else {
				setFormData({
					name: initData.getIngredientById.name,
					description: initData.getIngredientById.description,
				});
			}
		}
	}, [initData]);

	useEffect(() => {
		if (updateData) {
			if (updateData.updateIngredientById.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/core/ingredient`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [updateData]);

	return initData && initData.getIngredientById ? (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>{initData.getIngredientById.name}</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Name</td>
						<td>
							{initData.getIngredientById.name && (
								<BasicTextInput
								identifier="name"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.name?.length > 0 ? false : true}
								errorText="Name is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={1}
								value={initData.getIngredientById.name}
							/>
							)}
							
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} value={initData.getIngredientById.description}/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	) : null;
};

export default AdminEditIngredient;
