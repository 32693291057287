import { gql } from "@apollo/client";

const GET_CORE_CONFIG = gql`
  mutation getCoreConfig {
    getCoreConfig {
      prefix {
        booking
        bookingItem
        payment
        refund
      }
      booking {
        maxMonth
      }
      payment {
        repaymentTimeout
        autoCancelDuration
      }
      stripe {
        isProduction
        refundAmount
        testPublishableKey
        testSecretKey
        publishableKey
        secretKey
      }
    }
  }
`;

export const GetCoreConfigMutationGql = GET_CORE_CONFIG;
