import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { BACKOFFICE_URL } from "../../../modules/Api/Data/ConstantInterface";
import ActionButton from "../../Common/ActionButton/ActionButton";
import { GetPaymentsForAdminGql } from "../../../modules/GraphQl/Resolvers/Payment/getPaymentsForAdmin.gql";
import PaymentInterface from "../../../modules/Firebase/Api/Data/PaymentInterface";
import { useAdmin } from "../../Context/AdminProvider";
import { paymentStatusMapper, paymentMethodMapper } from "../../../modules/Firebase/Model/PaymentMapper";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHelper } from "../../Context/HelperProvider";

const AdminPayment = () => {
    const { utility } = useHelper();
    const { gqlHeader, coreConfig } = useAdmin();
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(GetPaymentsForAdminGql, {
        fetchPolicy: 'cache-and-network',
        context: gqlHeader
    });
    const [ payments, setPayments ] = useState<PaymentInterface[]>([]);
    const [ searchedIncrementId, setSearchedIncrementId ] = useState('');

    useEffect(() => {
        if (data) {
            if (data.getPaymentsForAdmin) {
                setPayments([...data.getPaymentsForAdmin]);
            }
        }
    }, [data]);

    const columns: GridColDef[] = [
        {
            field: 'incrementId',
            headerName: 'Payment ID',
            width: 200,
            sortable: true,
            editable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${paymentStatusMapper(params.row.status)}`
                );
            }
        },
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            width: 150,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    RM {params.row.amount}
                </span>
            ),
            align: 'left',
            sortComparator: utility.dataGridPriceComparator
        },
        {
            field: 'paymentMethod',
            headerName: 'Payment Method',
            width: 150,
            editable: false,
            align: 'left',
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${paymentMethodMapper(params.row.paymentMethod)}`
                );
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            sortable: true,
            editable: false,
            width: 210,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${utility.dateFormatter(new Date(params.row.createdAt))}`
                );
            },
            align: 'left'
        },
        {
            field: 'booking',
            headerName: 'View Booking',
            sortable: false,
            editable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span className='actionTab' onClick={() => {
                        navigate(`/${BACKOFFICE_URL}/core/booking/edit/${params.row.bookingId}`);
                    }}><OpenInNewIcon fontSize="small"/>View Booking</span>
                );
            },
            align: 'left'
        },
        {
            field: 'viewPayment',
            headerName: 'View Payment',
            sortable: false,
            editable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span className='actionTab' onClick={() => {
                        window.open(`https://dashboard.stripe.com/${coreConfig.stripe.isProduction ? '' : 'test/'}payments/${params.row.paymentId}`, '_blank')
                    }}><OpenInNewIcon fontSize="small"/>View Payment</span>
                );
            },
            align: 'left'
        }
    ];

    return (
        <div className="adminActionDiv">
            <span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Payments</span>
                <ActionButton 
                    label={'Payout Settings'} 
                    title={'Click to navigate to Stripe Payout settings.'} 
                    isDisable={false}
                    onClick={() => {window.open(`https://dashboard.stripe.com/settings/payouts`, '_blank')}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
                <ActionButton 
                    label={'Stripe Dashboard'} 
                    title={'Click to navigate to Stripe Dashboard.'} 
                    isDisable={false}
                    onClick={() => {window.open(`https://dashboard.stripe.com/${coreConfig.stripe.isProduction ? '' : 'test/'}payments`, '_blank')}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown-outline'}
                />
			</span>
            <div className="adminActionContDiv">
                <div className='dataGrid'>
                    <DataGrid
                        rows={payments}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </div>
            </div>
        </div>
    );
}//end AdminPayment()

export default AdminPayment;
