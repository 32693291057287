import { gql } from "@apollo/client";

const GET_PAYMENTS_FOR_ADMIN = gql`
  query getPaymentsForAdmin {
    getPaymentsForAdmin {
      id
      amount
      status
      type
      incrementId
      currency
      paymentCreatedAt
      customer
      env
      paymentId
      chargeId
      chargeCreatedAt
      paymentMethodId
      paymentMethod
      balanceTxnId
      balanceTxnCreatedAt
      processingFee
      netAmount
      isRefunded
      isDisputed
      createdAt
      bookingId
    }
  }
`;
export const GetPaymentsForAdminGql = GET_PAYMENTS_FOR_ADMIN;
