import { gql } from "@apollo/client";

const GET_PRODUCT_BY_ID = gql`
	query getProductById($id: String!) {
		getProductById(id: $id) {
			id
			isEnabled
			title
			capacity
			description
			price
			urlKey
			thumbnail {
				fileName
				src
				position
			}
			functionalities
			usageDirection
			ingredients {
				name
				description
			}
		}
	}
`;

export const GetProductById = GET_PRODUCT_BY_ID;
