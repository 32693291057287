import "./MainAboutUs.scss";
import homePageImage from "../../../assets/images/Home.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const MainAboutUs = () => {

    //Force Window Scroll to top when clicked on the about us button
    const scrollToTop = () => {
        window.scroll(0, 0);
    }

    return (
        <div className="mainPageAboutUs">
            <div className="aboutUsContent">
                <div className="aboutUsImage">
                    <img src={homePageImage} alt="aboutUsImage"></img>
                </div>
                <div className="aboutUsDesc">
                    <p className="motto">Your time to shine.</p>
                    <p className="aboutUsTitleDesc"><span className="descTitle">You deserve</span> <span className="descBody">to have professional hair growth and scalp care, because you are <span className="descTitle">worthy.</span></span>
                    </p>
                    <div className="aboutUsButton">
                        <Link to="about_us" className="exploreButton" onClick={scrollToTop}>
                            <Button variant="outlined" sx={{border: "1px solid #b2a08b", color: "#5d3b23", background: "#b2a08b", borderRadius: 0, width: "150px", fontFamily: "PoppinsRegular"}}>
                                <span className="buttonText">Explore</span>
                            </Button>
                        </Link>
                        <span className="aboutUsHiddenText">To Know More About Us</span>
                    </div>
                </div>
            </div>
            <div className="break-line"></div>
        </div>
    )
}

export default MainAboutUs;