import { TextareaAutosize, FormHelperText } from "@mui/material";
import Utility from "../../../modules/Utility/Utility";
import { useEffect, useState } from 'react';
import './TextArea.scss';

const TextArea = (props: any) => {
    const {
        onChange,
        identifier,
        value,
        isRequired,
        errorText
    } = props;

    const utility: Utility = new Utility();
    const [ textareaValue, setTextareaValue ] = useState('');

    useEffect(() => {
        if (value) {
            setTextareaValue((prevTextareaValue: any) => prevTextareaValue = value);
        }
    }, []);

    const onInputChange = (e: any) => {
        let value: string = e.target.value;

        value = utility.escapeEmoji(value);

        setTextareaValue(value);
        onChange(value, identifier);
    }

    return (
        <div className="textAreaDiv">
            <TextareaAutosize
                className={`textAreaCustom ${isRequired && textareaValue.length === 0 ? "emptyDescription" : ""}`}
                onChange={onInputChange}
                minRows={4}
                value={textareaValue}
            />
            {isRequired && textareaValue.length === 0 && <FormHelperText sx={{ mt: "-3px", ml: 0, mr: 0 }}>{errorText}</FormHelperText>}
        </div>
    );
}//end TextArea()

export default TextArea;
