import { AppBar, Toolbar, Grid } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "./Header.scss";
import MyAccountDropdownModal from "../MyAccount/MyAccountDropdownModal/MyAccountDropdownModal";
import { useHelper } from "../Context/HelperProvider";
import Storage from "../../modules/Storage/Core/Storage";
import { KEY_CUSTOMER_TESTIMONIAL_DIV } from "../../modules/Storage/Api/ConstantInterface";

const Header = () => {
	const [switchToggle, setSwitchToggle] = useState(false);
	const location = useLocation();
	const checkToggle = switchToggle ? "active" : "";
	const [ offset, setOffset ] = useState(0);
	const headerRef = useRef(null);
	const { setHeaderHeight } = useHelper();
	const storage: Storage = new Storage();

	const leftPages = [
		{
			name: "Home",
			routes: "/",
		},
		{
			name: "Products",
			routes: "/products",
		},
		{
			name: "Treatments",
			routes: "/treatments",
		},
	];

	const rightPages = [
		{
			name: "FAQ",
			routes: "/faq",
		},
		{
			name: "About Us",
			routes: "/about_us",
		},
	];

	let activeStyle = {
		color: "#9d5c58",
	};

	let nonactiveStyle = {
		textDecoration: "none",
	};

	useEffect(() => {
		if (headerRef.current) {
			const { clientHeight } = headerRef.current;
			setHeaderHeight(clientHeight);
		}
	}, [headerRef]);

	// Observe the scroller event and update offset
	useEffect(() => {
		const onScroll = () => {
			setOffset(window.pageYOffset);
		};
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => {window.removeEventListener("scroll", onScroll);}
	}, [offset]);

	useEffect(() => {
		if (localStorage.getItem("testimonial")) {
			setTimeout(() => {
				scrollToTestimonial();
				localStorage.removeItem("testimonial");
			}, 1500);
		}
	}, []);

	//Scroll to Testimonial Function
	const scrollToTestimonial = () => {
		const element = document.getElementById("testimonialDiv");
        if (element) {
            setSwitchToggle(!switchToggle);
            element.scrollIntoView({ behavior: "smooth" });
        }
	};

	//Click on Testimonial on Nav
	const testimonialClick = () => {
		if (location.pathname !== "/") {
			localStorage.setItem("testimonial", "true");
			window.location.href = "/";
		}
		scrollToTestimonial();
	};

	const toggleHamburger = () => {
		setSwitchToggle(!switchToggle);
	};

	const closeMobileMenuOnClick = (isNormalPage: boolean = true) => {
		setSwitchToggle(false);
		if (isNormalPage) {
			window.scrollTo(0, 0);
		}
	}
	
	return (
		<AppBar
			ref={headerRef}
			position="fixed"
			id="header"
			sx={{
				background: "transparent",
				boxShadow: "none",
				marginBottom: "20px",
				zIndex: "1202",
			}}
			className={`headerAppbar ${offset > 0 ? 'headerOnScroll' : ''} ${location.pathname.split("/").pop() === "" ? '' : 'headerOnPage'} `}
		>
			<Toolbar className="navbar-container">
				<Grid container className="navbar-mobile-container">
					<Grid
						item
						sm={1}
						md={1}
						lg={1}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							className={`hamburger ${checkToggle}`}
							onClick={toggleHamburger}
						>
							<span className="bar"></span>
							<span className="bar"></span>
							<span className="bar"></span>
						</div>
					</Grid>
					<Grid
						className={`headerMainLink navbar-links ${checkToggle}`}
						item
						sm={10}
						md={10}
						lg={10}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<>
							{leftPages.map((page, index) => {
								return (
									<NavLink
										className={`leftHeaderLink ${offset > 0 ? 'headerOnScrollLink' : ''}`}
										to={page.routes}
										key={index}
										style={({ isActive }) =>
											isActive ? activeStyle : nonactiveStyle
										}
										onClick={() => closeMobileMenuOnClick()}
									>
										{page.name}
									</NavLink>
								);
							})}
						</>
						<a
							href="/"
							className={`header-title ${offset > 0 || checkToggle ? 'headerOnScrollLink' : ''}`}
							style={{
								color: "white",
								paddingLeft: 5,
								paddingRight: 5,
								fontSize: 25,
								fontWeight: "bold",
								textDecoration: "none",
							}}
						>
							<div className='headerLogo'></div>
						</a>
						<>
							<span
								className={`rightHeaderLink ${offset > 0 ? 'headerOnScrollLink' : ''}`}
								style={{ cursor: "pointer" }}
								onClick={() => testimonialClick()}
							>
								Testimonial
							</span>
							{rightPages.map((page, index) => {
								return (
									<NavLink
										className={`rightHeaderLink ${offset > 0 ? 'headerOnScrollLink' : ''}`}
										to={page.routes}
										key={index}
										style={({ isActive }) =>
											isActive ? activeStyle : nonactiveStyle
										}
										onClick={() => closeMobileMenuOnClick()}
									>
										{page.name}
									</NavLink>
								);
							})}
						</>
					</Grid>
					<Grid
						item
						className="loginButton"
						sm={1}
						md={1}
						lg={1}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "flex-end",
						}}
					>
						<MyAccountDropdownModal />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
