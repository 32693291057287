import "./AdminPanel.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../modules/Api/Data/ConstantInterface";
import { useEffect, useRef, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import DrawerListItem from "../DrawerListItem/DrawerListItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import ScienceIcon from "@mui/icons-material/Science";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import TuneIcon from "@mui/icons-material/Tune";
import FlatwareIcon from "@mui/icons-material/Flatware";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
import Dashboard from "./Dashboard/Dashboard";
import AdminBooking from "./Core/AdminBooking/AdminBooking";
import AdminProduct from "./Core/AdminProduct/AdminProduct";
import AdminAddProduct from "./Core/AdminProduct/ActionAdminProduct/AdminAddProduct";
import AdminEditProduct from "./Core/AdminProduct/ActionAdminProduct/AdminEditProduct";
import AdminIngredient from "./Core/AdminIngredient/AdminIngredient";
import AdminConfig from "./Core/AdminConfig/AdminConfig";
import AdminTreatment from "./Core/AdminTreatment/AdminTreatment";
import AdminPayment from "./Payment/Payment";
import AdminAdmin from "./User/Admin/Admin";
import AdminCustomer from "./User/Customer/Customer";
import AdminFaq from "./Content/Faq/AdminFaq";
import AdminHero from "./Content/Hero/AdminHero";
import AddAdminTreatment from "./Core/AdminTreatment/ActionAdminTreatment/AddAdminTreatment";
import EditAdminTreatment from "./Core/AdminTreatment/ActionAdminTreatment/EditAdminTreatment";
import AdminAddIngredient from "./Core/AdminIngredient/ActionAdminIngredient/AdminAddIngredient";
import AdminEditIngredient from "./Core/AdminIngredient/ActionAdminIngredient/AdminEditIngredient";
import LogoutIcon from "@mui/icons-material/Logout";
import Storage from "../../modules/Storage/Core/Storage";
import Session from "../../modules/Session/Core/Session";
import AddAdmin from "./User/Admin/ActionAdmin/AddAdmin";
import AdminRestrictor from "../Common/Restrictor/AdminRestrictor";
import EditAdminBooking from "./Core/AdminBooking/ActionAdminBooking/EditAdminBooking";
import RescheduleAdminBooking from "./Core/AdminBooking/ActionAdminBooking/RescheduleAdminBooking";
import { useHelper } from "../Context/HelperProvider";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrCodeScanner from "../QrCode/Scanner/QrCodeScanner";
import AdminAddFaq from "./Content/Faq/ActionAdminFaq/AdminAddFaq";
import TodayAdminBooking from "./Core/AdminBooking/ActionAdminBooking/TodayAdminBooking";
import AdminEditFaq from "./Content/Faq/ActionAdminFaq/AdminEditFaq";
import AdminFaqClass from "./Content/Faq/AdminFaqClass";
import AdminAddFaqClass from "./Content/Faq/ActionAdminFaq/AdminAddFaqClass";
import { useAdmin } from "../Context/AdminProvider";
import AdminAddHero from "./Content/Hero/ActionAdminHero/AdminAddHero";
import AdminEditHero from "./Content/Hero/ActionAdminHero/AdminEditHero";
import AdminEditCustomer from "./User/Customer/ActionCustomer/AdminEditCustomer";
import AdminRefund from "./Payment/Refund";


const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	background: '#6E543C',
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const AdminPanel = () => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const adminPanelHeaderRef = useRef<any>(null);
	const storage: Storage = new Storage();
	const session: Session = new Session();
	const { setAdminPanelHeaderHeight } = useHelper();
	const { logout } = useAdmin();
	const [ isQrScannerActive, setIsQrScannerActive ] = useState(false);

	useEffect(() => {
		if (adminPanelHeaderRef.current) {
			const { clientHeight } = adminPanelHeaderRef.current;
			setAdminPanelHeaderHeight(clientHeight);
		}
	}, [adminPanelHeaderRef]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const onMenuItemClick = (path: string) => {
		navigate(`/${path}`);
	};

	const showActiveMenu = (isTitle: boolean = false) => {
		switch (location.pathname) {
			default:
			case `/${BACKOFFICE_URL}/dashboard`:
				return isTitle ? "Dashboard" : <Dashboard />;
			case `/${BACKOFFICE_URL}/core/booking`:
				return isTitle ? "Booking" : <AdminBooking />;
			case location.pathname.match(/.*?\/core\/booking\/edit\/[a-zA-Z0-9]*/im)?.input:
				return isTitle ? "Booking" : <EditAdminBooking />;
			case location.pathname.match(/.*?\/core\/booking\/reschedule\/[a-zA-Z0-9]*/im)?.input:
				return isTitle ? "Reschedule Booking" : <RescheduleAdminBooking />;
			case `/${BACKOFFICE_URL}/core/booking/today`:
				return isTitle ? "Today's Booking" : <TodayAdminBooking />;
			case `/${BACKOFFICE_URL}/core/product`:
				return isTitle ? "Product" : <AdminProduct />;
			case `/${BACKOFFICE_URL}/core/product/add`:
				return isTitle ? "Product" : <AdminAddProduct />;
			case location.pathname.match(
					/.*?\/core\/product\/edit\/[a-zA-Z0-9]*/im)?.input:
					return isTitle ? "Product" : <AdminEditProduct />;
			case `/${BACKOFFICE_URL}/core/ingredient`:
				return isTitle ? "Ingredient" : <AdminIngredient />;
			case `/${BACKOFFICE_URL}/core/ingredient/add`:
				return isTitle ? "Ingredient" : <AdminAddIngredient />;
			case location.pathname.match(
				/.*?\/core\/ingredient\/edit\/[a-zA-Z0-9]*/im)?.input:
				return isTitle ? "Treatment" : <AdminEditIngredient />;
			case `/${BACKOFFICE_URL}/core/treatment`:
				return isTitle ? "Treatment" : <AdminTreatment />;
			case `/${BACKOFFICE_URL}/core/treatment/add`:
				return isTitle ? "Treatment" : <AddAdminTreatment />;
			case location.pathname.match(/.*?\/core\/treatment\/edit\/[a-zA-Z0-9]*/im)
				?.input:
				return isTitle ? "Treatment" : <EditAdminTreatment />;
			case `/${BACKOFFICE_URL}/core/config`:
				return isTitle ? "Configuration" : <AdminConfig />;
			case `/${BACKOFFICE_URL}/content/hero`:
				return isTitle ? "Hero Banner" : <AdminHero />;
			case `/${BACKOFFICE_URL}/content/hero/add`:
				return isTitle ? "Hero Banner" : <AdminAddHero />;
			case location.pathname.match(/.*?\/content\/hero\/edit\/[a-zA-Z0-9]*/im)
				?.input:
				return isTitle ? "Hero Banner" : <AdminEditHero />;
			case `/${BACKOFFICE_URL}/content/faq`:
				return isTitle ? "FAQ" : <AdminFaq />;
			case `/${BACKOFFICE_URL}/content/faq/add`:
				return isTitle ? "FAQ" : <AdminAddFaq />;
			case location.pathname.match(
				/.*?\/content\/faq\/edit\/[a-zA-Z0-9]*/im)?.input:
				return isTitle ? "FAQ" : <AdminEditFaq />;	
			case `/${BACKOFFICE_URL}/content/class-faq`:
				return isTitle ? "FAQ Class" : <AdminFaqClass />;
			case `/${BACKOFFICE_URL}/content/class-faq/add`:
				return isTitle ? "FAQ Class" : <AdminAddFaqClass />;
			case `/${BACKOFFICE_URL}/user/customer`:
				return isTitle ? "Customer" : <AdminCustomer />;
			case location.pathname.match(
				/.*?\/user\/customer\/edit\/[a-zA-Z0-9]*/im)?.input:
				return isTitle ? "Customer" : <AdminEditCustomer />;	
			case `/${BACKOFFICE_URL}/user/admin`:
				return isTitle ? "Admin" : <AdminAdmin />;
			case `/${BACKOFFICE_URL}/user/admin/add`:
				return isTitle ? 'Admin' : <AddAdmin/>;
			case `/${BACKOFFICE_URL}/transaction/payment`:
				return isTitle ? "Payment" : <AdminPayment />;
			case `/${BACKOFFICE_URL}/transaction/refund`:
				return isTitle ? "Refund" : <AdminRefund />;
		}
	};

	const logOut = () => {
		logout();
		navigate(`/${BACKOFFICE_URL}`);
	}

	const onReadQrCode = () => {
		setIsQrScannerActive(true);
	}

	return (
		<>
			{
				isQrScannerActive &&
				<QrCodeScanner onCompleted={() => setIsQrScannerActive(false)}/>
			}
			<div className="adminPanelOuterDiv">
				<AdminRestrictor/>
				<div className="adminPanelOuterDivActions">
					<IconButton
						className='actionBtn'
						color="inherit"
						aria-label="qrscanner"
						onClick={onReadQrCode}
						title={'QR Code Scanner'}
					>
						<QrCodeScannerIcon />
					</IconButton>
					<IconButton
						className='actionBtn'
						color="inherit"
						aria-label="logout"
						onClick={logOut}
						title={'Logout'}
					>
						<LogoutIcon />
					</IconButton>
				</div>
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<AppBar position="fixed" open={open}>
						<Toolbar ref={adminPanelHeaderRef}>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								sx={{
									marginRight: 5,
									...(open && { display: "none" }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography variant="h6" noWrap component="div">
								{showActiveMenu(true)}
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant="permanent" open={open}>
						<DrawerHeader>
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === "rtl" ? (
									<ChevronRightIcon />
								) : (
									<ChevronLeftIcon />
								)}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<List>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/dashboard`}
								title={"Dashboard"}
								icon={<DashboardIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/dashboard`
								)}
							/>
						</List>
						<Divider />
						<List>
							<DrawerListItem
								isDrawerOpen={open}
								id={-1}
								title={"Core Management"}
								icon={<SettingsIcon />}
								isDisplayOnly={true}
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/core/booking`}
								title={"Booking"}
								icon={<EventIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/core/booking`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/core/product`}
								title={"Product"}
								icon={<InventoryIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/core/product`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/core/ingredient`}
								title={"Ingredient"}
								icon={<FlatwareIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/core/ingredient`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/core/treatment`}
								title={"Treatment"}
								icon={<ScienceIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/core/treatment`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/core/config`}
								title={"Configuration"}
								icon={<TuneIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/core/config`
								)}
								isSubMenu
							/>
						</List>
						<Divider />
						<List>
							<DrawerListItem
								isDrawerOpen={open}
								id={-1}
								title={"Content Management"}
								icon={<FormatPaintIcon />}
								isDisplayOnly={true}
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/content/hero`}
								title={"Hero Banner"}
								icon={<BurstModeIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/content/hero`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/content/faq`}
								title={"FAQ"}
								icon={<ContactSupportIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/content/faq`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/content/class-faq`}
								title={"FAQ Class"}
								icon={<HelpOutlineIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/content/class-faq`
								)}
								isSubMenu
							/>
						</List>
						<Divider />
						<List>
							<DrawerListItem
								isDrawerOpen={open}
								id={-1}
								title={"User Management"}
								icon={<PeopleIcon />}
								isDisplayOnly={true}
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/user/customer`}
								title={"Customer"}
								icon={<PersonIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/user/customer`
								)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/user/admin`}
								title={"Admin"}
								icon={<ManageAccountsIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(
									`${BACKOFFICE_URL}/user/admin`
								)}
								isSubMenu
							/>
						</List>
						<Divider />
						<List>
							<DrawerListItem
								isDrawerOpen={open}
								id={-1}
								title={"Transactions"}
								icon={<PaymentsIcon />}
								isDisplayOnly={true}
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/transaction/payment`}
								title={"Payment"}
								icon={<PaidIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(`${BACKOFFICE_URL}/transaction/payment`)}
								isSubMenu
							/>
							<DrawerListItem
								isDrawerOpen={open}
								id={`${BACKOFFICE_URL}/transaction/refund`}
								title={"Refund"}
								icon={<AccountBalanceIcon />}
								onClick={onMenuItemClick}
								isActive={location.pathname.includes(`${BACKOFFICE_URL}/transaction/refund`)}
								isSubMenu
							/>
						</List>
					</Drawer>
					<div className="adminPanelDiv">
						<DrawerHeader />
						<div className="adminPanelContDiv">{showActiveMenu()}</div>
					</div>
				</Box>
			</div>
		</>
	);
};

export default AdminPanel;
