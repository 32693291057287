import "./Home.scss";
import Hero from "../Hero/Hero";
import MainAboutUs from "../AboutUs/MainAboutUs/MainAboutUs";
import TreatmentList from "../Treatments/TreatmentList/TreatmentList";
import HomeProducts from "../Products/HomeProducts/HomeProducts";
import BenefitsList from "../BenefitsList/BenefitsList";
import Testimonial from "../Testimonial/Testimonial";
import Location from "../Location/Location";

const Home = () => {

    return (
        <div className="homeContainer">
            <Hero />
            <MainAboutUs />
            <TreatmentList />
            <HomeProducts />
            <BenefitsList />
            <Testimonial />
            <Location />
        </div>
    )
}

export default Home;