import { getToken } from "firebase/messaging";
import msg from "../Core/messaging.inc";

export default class Messaging {

    /**
     * Retrieves a client FCM token, if only client granted notification permission
     * @returns Promise
     */
    getClientToken = () => {
        return getToken(msg, {vapidKey: process.env.REACT_APP_VAPIDKEY});
    }//end getClientToken()
}//end class
