import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { KEY_UID } from "../../../modules/Storage/Api/ConstantInterface";
import { GetBookingsByUidGql } from "../../../modules/GraphQl/Resolvers/Booking/getBookingsByUid.gql";
import { GetBookingsByUidMutationGql } from "../../../modules/GraphQl/Resolvers/Booking/getBookingsByUidMutation.gql";
import './MyAccountBooking.scss';
import MyAccountBookingItem from "./MyAccountBookingItem/MyAccountBookingItem";
import BookingInterface from "../../../modules/Booking/Api/Data/BookingInterface";
import { useLocation, useNavigate } from "react-router-dom";
import { BOOKING_STATUS } from "../../../modules/Booking/Api/Data/ConstantInterface";
import MyAccountTitle from "../MyAccountTitle/MyAccountTitle";
import statusMapper from "../../../modules/Booking/Model/StatusMapper";
import { useHelper } from "../../Context/HelperProvider";
import { useUser } from "../../Context/UserProvider";

const MyAccountBooking = () => {

    const { cryptor, storage } = useHelper();
    const { gqlHeader } = useUser();
    const location = useLocation();
    const navigate = useNavigate();

    const checkValidBookingStatus = () => {
        let status: string = location.pathname.split('/').pop() ?? '';

        if (Object.keys(BOOKING_STATUS).includes(status)) {
            return false;
        }
        return true;
    }

    const { data, loading, error } = useQuery(GetBookingsByUidGql, {
        variables: {
            uid: storage.getItem(KEY_UID),
            status: location.pathname.split('/').pop()
        },
        fetchPolicy: 'cache-and-network',
        skip: checkValidBookingStatus(),
        context: gqlHeader
    });

    const [ rehitBooking, { data: rehitData, loading: rehitLoading, error: rehitError } ] = useMutation(GetBookingsByUidMutationGql);
    const [ bookings, setBookings ] = useState<BookingInterface[]>([]);
    const [ canLoadMore, setCanLoadMore ] = useState(true);

    useEffect(() => {
        if (data) {
            setBookings([...data.getBookingsByUid]);
        }
    }, [data]);

    useEffect(() => {
        if (rehitData) {
            if (rehitData.getBookingsByUid) {
                setBookings([...bookings, ...rehitData.getBookingsByUid]);
            }
        }
    }, [rehitData]);

    useEffect(() => {
        if (rehitError) {
            setCanLoadMore(false);
        }
    }, [rehitError]);

    const rehitBookingGql = async () => {
        let lastVisibleId = cryptor.decrypt(bookings[bookings.length - 1].id);
        await rehitBooking({
            variables: {
                uid: storage.getItem(KEY_UID),
                status: location.pathname.split('/').pop(),
                lastVisibleId
            },
            context: gqlHeader
        })
    }

    return (
        <div className='myAccountBookingDiv'>
            <MyAccountTitle title={`My ${statusMapper(location.pathname.split('/').pop() ?? '')} Booking(s)`}/>
            {
                data && data.getBookingsByUid && bookings && bookings.length > 0 ?
                <div className="myAccountBookingCont">
                    {
                        bookings.map((booking: BookingInterface) => {
                            return (
                                <MyAccountBookingItem key={booking.id} {...booking} />
                            );
                        })
                    }
                    {
                        canLoadMore ? 
                        <span className="myAccountButton" onClick={() => rehitBookingGql()}>Load more...</span> :
                        <span className="myAccountBookingEnd">You have reached the end :)!</span>
                    }
                </div> : 
                <div className="myAccountBookingCont myAccountNoBookingCont">
                    You don't have any bookings yet!
                    <span className="myAccountButton" onClick={() => navigate('/booking')}>Book Now</span>
                </div>
            }
        </div>
    );
}//end MyAccountBooking()

export default MyAccountBooking;
