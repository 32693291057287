import { gql } from "@apollo/client";

const GET_TREATMENTS_FOR_ADMIN = gql`
  query getTreatmentsForAdmin {
    getTreatmentsForAdmin {
      id
      isEnable
      title
      price
      description
      images {
        fileName
        src
        position
      }
      createdAt
    }
  }
`;

export const GetTreatmentsForAdminGql = GET_TREATMENTS_FOR_ADMIN;
