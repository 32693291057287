import Booking from "../../../../Booking/Booking";

const TodayAdminBooking = () => {
	return (
		<div className="rescheduleBookingDiv">
			<Booking 
				isAdmin
				isToday
			/>
		</div>
	);
}//end TodayAdminBooking()

export default TodayAdminBooking;
