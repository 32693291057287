import { gql } from "@apollo/client";

const COMPARE_BOOKING_ITEM_STATUS = gql`
  query compareBookingItemStatus($id: String!, $status: String!) {
    compareBookingItemStatus(id: $id, status: $status) {
      status
      message
    }
  }
`;

export const CompareBookingItemStatusGql = COMPARE_BOOKING_ITEM_STATUS;
