import { gql } from "@apollo/client";

const REMOVE_INGREDIENT_BY_ID = gql`
	mutation removeIngredientById($id: String!) {
		removeIngredientById(id: $id) {
			status
			message
		}
	}
`;

export const RemoveIngredientById = REMOVE_INGREDIENT_BY_ID;
