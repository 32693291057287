import { gql } from "@apollo/client";

const UPDATE_BOOKING_ITEM_BY_ID = gql`
  mutation updateBookingItemById($id: String!, $data: String!) {
    updateBookingItemById(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const UpdateBookingItemByIdGql = UPDATE_BOOKING_ITEM_BY_ID;
