import Cryptor from "../../Cryptor/Core/Cryptor";

/**
 * A wrapper for localStorage
 */
export default class Storage {

    cryptor: Cryptor;

    constructor() {
        this.cryptor = new Cryptor();
    }//end constructor()

    getItem(key: string) {
        try {
            return this.cryptor.decrypt(localStorage.getItem(key));
        } catch {
            return '';
        }
    }//end getItem()

    setItem(key: string, value: any) {
        localStorage.setItem(key, this.cryptor.encrypt(value));
    }//end setItem()

    hasItem(key: string) {
        try {
            if (this.getItem(key) === '' || this.getItem(key) === null) {
                return false;
            } else {
                return true;
            }
        } catch {
            return false;
        }
    }//end hasItem()

    deleteItem(key: string) {
        localStorage.removeItem(key);
    }//end deleteItem()
}//end class
