import { gql } from "@apollo/client";

const CREATE_INGREDIENT = gql`
	mutation createIngredient($name: String!, $description: String) {
		createIngredient(name: $name, description: $description) {
			status
			message
		}
	}
`;

export const CreateIngredient = CREATE_INGREDIENT;
