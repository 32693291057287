import { gql } from "@apollo/client";

const SEARCH_BOOKINGS_FOR_ADMIN = gql`
  mutation searchBookingsForAdmin($query: String!) {
    searchBookingsForAdmin(query: $query) {
      id
      incrementId
      status
      uid
      username
      pid
      grandTotal
      currency
      createdAt
      completedAt
      children {
        id
        status
        treatmentId
        treatmentName
        treatmentPrice
        treatmentSrc
        start
        end
        createdAt
      }
    }
  }
`;
export const SearchBookingsForAdminGql = SEARCH_BOOKINGS_FOR_ADMIN;
