import { gql } from "@apollo/client";

const GET_ALL_INGREDIENTS = gql`
	query getAllIngredients {
		getAllIngredients {
			id
			name
			description
		}
	}
`;

export const GetAllIngredients = GET_ALL_INGREDIENTS;
