import { gql } from "@apollo/client";

const UPDATE_FAQ_BY_ID = gql`
	mutation updateFaqById($id: String!, $data: FaqInputType!) {
		updateFaqById(id: $id, data: $data) {
			status
			message
		}
	}
`;

export const UpdateFaqById = UPDATE_FAQ_BY_ID;
