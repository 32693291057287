import "./AdminTreatment.scss";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import TreatmentInterface from "../../../../modules/Treatment/Api/Data/TreatmentInterface";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { GetTreatmentsForAdminGql } from "../../../../modules/GraphQl/Resolvers/Treatment/getTreatmentsForAdmin.gql";
import { DeleteTreatmentByIdGql } from "../../../../modules/GraphQl/Resolvers/Treatment/deleteTreatmentById.gql";
import { useAdmin } from "../../../Context/AdminProvider";
import { useHelper } from '../../../Context/HelperProvider';


const AdminTreatment = () => {
    const { utility } = useHelper();
	const { gqlHeader } = useAdmin();
	const navigate = useNavigate();

	const { data, loading, error, refetch } = useQuery(GetTreatmentsForAdminGql, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [
		deleteTreatment,
		{ data: deleteData },
	] = useMutation(DeleteTreatmentByIdGql);

	const [treatments, setTreatments] = useState<TreatmentInterface[]>([]);
	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

	const columns: GridColDef[] = [
		{
			field: "thumbnail",
			headerName: "Thumbnail",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<div className="gridTableImgDiv">
						<img className="gridTableImg" src={params.row.images[0].src} alt="gridTableImg"/>
					</div>
				);
			},
		},
		{
			field: "title",
			headerName: "Title",
			width: 150,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "price",
			headerName: "Price",
			width: 150,
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams) => (
				<span>RM {params.row.price}</span>
			),
			align: "left",
			sortComparator: utility.dataGridPriceComparator,
		},
		{
			field: "description",
			headerName: "Description",
			width: 110,
			editable: false,
			align: "left",
		},
		{
			field: "isEnable",
			headerName: "Is Enabled?",
			sortable: false,
			editable: false,
			width: 120,
			renderCell: (params: GridRenderCellParams) => {
				return `${params.row.isEnable === true ? "Yes" : "No"}`;
			},
			align: "left",
		},
		{
			field: "createdAt",
			headerName: "Created at",
			sortable: true,
			editable: false,
			width: 210,
			renderCell: (params: GridRenderCellParams) => {
				return `${utility.dateFormatter(new Date(params.row.createdAt))}`;
			},
			align: "left",
		},
		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							navigate(
								`/${BACKOFFICE_URL}/core/treatment/edit/${params.row.id}`
							);
						}}
					>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
			align: "left",
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							deleteItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span>
				);
			},
			align: "left",
		},
	];

	useEffect(() => {
		if (data) {
			if (data.getTreatmentsForAdmin) {
				setTreatments([...data.getTreatmentsForAdmin]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (deleteData) {
			if (deleteData.deleteTreatmentById.status) {
				refetch();
			}
		}
	}, [deleteData]);

	const deleteItemPrompt = (treatment: TreatmentInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${treatment.title}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected treatment - ${treatment.title}.`,
				onClick: deleteItem,
				extra: treatment,
			},
		});
	};

	const deleteItem = async (treatment: TreatmentInterface) => {
		await deleteTreatment({
			variables: {
				id: treatment.id,
			},
			context: gqlHeader,
		});
	};

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Treatments</span>
                <span></span>
				<ActionButton
					label={"Add New Treatment"}
					title={"Click to add a new treatment."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/core/treatment/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
					styling={'basic-brown'}
				/>
			</span>   
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={treatments}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
	);
}; //end AdminTreatment()

export default AdminTreatment;
