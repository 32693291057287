import "./Footer.scss";
import Copyright from "../Copyright/Copyright";
import NavigationBar from "../NavigationBar/NavigationBar";

const Footer = () => {
    return (
        <div className="footer">
            <NavigationBar />
            <Copyright />
        </div>
    )
}

export default Footer;