import React, { useContext, useReducer, useState } from "react";
import AdminProductReducer from "../AdminPanel/Core/AdminProduct/ActionAdminProduct/AdminProductReducer";
import Utility from "../../modules/Utility/Utility";

const ProductContext = React.createContext<any>(null);

export const useProduct = () => {
    return useContext(ProductContext)
}

const ProductProvider = ({children}) => {

    const utility: Utility = new Utility();

    // let initState: any = {
    //     functionalities: [""],
    //     usageDirection: [""],
    // };

    // const [ state, dispatch ] = useReducer(AdminProductReducer, initState);

    // const addSpace = (data: any, category: any) => {
    //     if (category === "FUNCTION") {
    //         initState = data;
    //         console.log(initState);
    //         let type = `ADD_${category}`;
    //         dispatch({ type: type });
    //     }
    // }

    function addFunction(data: any) {
		return data.push("");
	}

	function removeFunction(data: any, index) {
		data.splice(index, 1);
		return data;
	}

	const functionChange = (data: any, index) => {

	}

	// const addUsage = () => {
	// 	setFormData({...formData, usageDirection: [...formData.usageDirection, ""]})
	// }

	// const removeUsage = (index) => {
	// 	const usageList = formData.usageDirection;
	// 	usageList.splice(index, 1);
	// 	setFormData({...formData, usageDirection: usageList})
	// }

	// const usageChange = (e, index) => {
	// 	const value = utility.escapeEmoji(e.target.value);
	// 	const usageList = formData.usageDirection;
	// 	usageList[index] = value;
	// 	setFormData({...formData, usageDirection: usageList});
	// }

    return (
        <ProductContext.Provider value={{
            addFunction,
            removeFunction
        }}>
            {children}
        </ProductContext.Provider>
    )
}

export default ProductProvider;