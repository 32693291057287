import "../Error404.scss";
import ErrorText from "../../../assets/images/Error/Error404-text.svg";
import Error from "../../../assets/images/Error/Error404.svg";

const AdminError404 = () => {
    return (
        <div className="errorContainer">
            <div className="errorContent errorAdminContent">
                <div className="errorContentHeader">
                    There's a problem. Sorry!  
                </div>
                <div className="errorImage">
                    <img src={Error} alt="topError"/>
                </div>
                <div className="errorContentBottom">
                    page not found.
                </div>
                <div className="errorButton">
                    <a href="/bhcadmin/dashboard"><img src={ErrorText} alt="errorImage"/></a>
                </div>
            </div>
        </div>
    )
}

export default AdminError404;