import { gql } from "@apollo/client";

const GET_TREATMENT_BY_ID = gql`
  query getTreatmentById($id: String!) {
    getTreatmentById(id: $id) {
      id
      isEnable
      title
      price
      description
      images {
        fileName
        src
        position
      }
      urlKey
      createdAt
    }
  }
`;

export const GetTreatmentByIdGql = GET_TREATMENT_BY_ID;
