import './BookingSlot.scss';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import LockClockIcon from '@mui/icons-material/LockClock';
import { useEffect, useState } from 'react';

const BookingSlot = (props: any) => {
    const {
        aid,
        uid,
        localUid,
        status,
        isAdmin
    } = props;

    const [ displayText, setDisplayText ] = useState<any>('');

    useEffect(() => {
        if (isAdmin && aid) {
            setDisplayText(<><BookmarkAddedIcon/> Reserved for reschedule!</>);
        } else if (isAdmin && !aid) {
            setDisplayText(<><BlockIcon/> Reserved by customer.</>);
        } else if (!isAdmin && uid === localUid) {
            if (status === 'unpaid') {
                setDisplayText(<><WarningIcon/> Payment Pending!</>);
            } else if (status === 'new') {
                setDisplayText(<><LockClockIcon/> Reserved Temporarily!</>);
            } else {
                setDisplayText(<><BookmarkAddedIcon/> Reserved!</>);
            }
        } else {
            setDisplayText(<><BlockIcon/> Not available.</>);
        }
    }, []);

    return (
        <div className={`bookingSlotDiv ${(uid !== localUid && !isAdmin) ? 'bookingSlotDivDisabled' : status === 'unpaid' ? 'bookingSlotDivUnpaid' : status === 'new' ? 'bookingSlotDivNew' : ''}`}>
            <span>
                <span className='bookingSlotCont'>{displayText}</span>
            </span>
        </div>
    );
}//end BookingSlot()

export default BookingSlot;