import { FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import './TextInput.scss';
import { useState } from 'react';
import Utility from '../../../modules/Utility/Utility';

const TextInput = (props: any) => {
    const {
        identifier,
        label,
        isRequired,
        canShowError,
        errorText,
        onChange,
        hasPassword,
        minLength,
        maxLength,
        prefix,
        isNumber,
        inputType,
        isAutocomplete,
        tabIndex,
        value: parentValue,
        onEnter,
        icon
    } = props;

    const [ isPassword, setIsPassword ] = useState<boolean>(typeof hasPassword === 'undefined' ? false : true);
    const [ value, setValue ] = useState(parentValue ?? '');
    const [ inputMaxLength, setInputMaxLength ] = useState(maxLength);
    const [ onInit, setOnInit ] = useState(true);
    const utility: Utility = new Utility();
    let randomId: string = utility.randomElementId();
    let randomElementId: string = `textInput${randomId}${identifier}`;
    let helperTextId: string = `textInputHelperText${randomId}${identifier}`;

    const onShowPassword = () => {
        setIsPassword(true);
    }

    const onHidePassword = () => {
        setIsPassword(false);
    }

    const onInputChange = (e: any, identifier: string) => {
        let value: string = e.target.value;
        if (isNumber) {
            value = value.replace(/[^\d]+/g, '');
        }

        if (inputType === 'phone') {
            setInputMaxLength(maxLength);
            if (value.length >= 3) {
                let phone: number = parseInt(value.substring(0, 3));
                if (phone === 111) {
                    setInputMaxLength(10);
                }
            }
        }

        if (inputType === 'username') {
            value = utility.escapeSymbol(value);
        }

        value = utility.escapeEmoji(value);

        if (onInit) {
            setOnInit(false);
        }

        setValue(value);
        onChange(value, identifier);
    }

    return (
        <div className="textInputDiv">
            <OutlinedInput
                fullWidth 
                autoComplete={isAutocomplete ? 'on' : 'new-password'}
                id={randomElementId}
                required={isRequired}
                error={onInit ? false : canShowError ? true : false}
                onInput={(e: any) => onInputChange(e, identifier)}
                onTouchEnd={(e: any) => onInputChange(e, identifier)}
                onChange={(e: any) => onInputChange(e, identifier)}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        if (onEnter instanceof Function) {
                            e.preventDefault();
                            onEnter();
                        }
                    }
                }}
                value={value}
                className="textInput"
                tabIndex={tabIndex}
                inputProps={
                    {
                        minLength: minLength,
                        maxLength: inputMaxLength,
                        placeholder: label
                    }
                }
                type={isPassword ? 'password' : 'text' }
                startAdornment={
                    inputType === 'phone' ?
                    <InputAdornment position="start">{prefix}</InputAdornment> :
                    <InputAdornment position="start">
                        { icon ? icon : inputType === 'username' ? <PersonIcon/> : <LockIcon/>}
                    </InputAdornment>
                }
                endAdornment={
                    hasPassword ?
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onShowPassword}
                            onMouseDown={onHidePassword}
                            edge="end"
                        >
                            {isPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment> : null
                }
            />
            <FormHelperText id={helperTextId}>{onInit ? ' ' : canShowError ? errorText : ' '}</FormHelperText>
        </div>
    );
}

export default TextInput;
