import "./BottomCarouselItem.scss";
const BottomCarouselItem = (props) => {
    
    const { sentItem, length, itemType } = props;
    const navigation = (type: string, urlKey: string) => {
        window.location.replace(`/${type}/${urlKey}`);
    }

    return (
        <div className="filteredItemDiv">
            {itemType === "treatments" ? 
                <div className="filtered-item" onClick={() => navigation(itemType, sentItem.urlKey)}>
                    <img src={sentItem.images[0].src} className={`${length === 1 ? "oneImage" : ""}`} /> 
                    <div className="services-detail">
                        <span>{sentItem.title}</span>
                    </div>
                </div> :   
                <div className="product-filtered-item" onClick={() => navigation(itemType, sentItem.urlKey)}>
                    <img src={sentItem.thumbnail[0].src} className={`${length === 1 ? "oneImage" : ""}`} />
                    <div className="services-detail">
                        <span>{sentItem.title}</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default BottomCarouselItem;
