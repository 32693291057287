import "./SingleTreatment.scss";
import WaterTheraphy from "../../../assets/images/WaterTheraphy.jpeg";
import { NavLink } from "react-router-dom";

const SingleTreatment = (props: any) => {
    const {title, images, description, index, urlKey} = props;
    let result = "";

    const wordSlice = (description: any) => {
        return description.slice(0, 280);
    }

    result = wordSlice(description);

    return (
        <div className="treatmentCard" key={index}>
            <NavLink to={`/treatments/${urlKey}`}>
                <div className="treatmentImages">
                    {images ? <img src={images[0].src} alt="treatmentImage" /> : <img src={WaterTheraphy} alt="treatmentImage" />} 
                    <div className="treatmentDetails">
                        <div className="treatmentTitle">{title}</div>
                        <p>{result} ...</p>
                        <div className="more">
                            <span className="read-more">read more</span>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default SingleTreatment;