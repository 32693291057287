import { gql } from "@apollo/client";

const CREATE_PAYMENT = gql`
  query createPayment($id: String!) {
    createPayment(id: $id) {
      status
      message
    }
  }
`;
export const CreatePaymentGql = CREATE_PAYMENT;
