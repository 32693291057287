import { gql } from "@apollo/client";

const RESCHEDULE_BOOKING = gql`
  mutation rescheduleBooking($id: String!, $bid: String!, $parentId: String!, $start: String!, $end: String!) {
    rescheduleBooking(id: $id, bid: $bid, parentId: $parentId, start: $start, end: $end) {
      status
      message
    }
  }
`;
export const RescheduleBookingGql = RESCHEDULE_BOOKING;
