import { gql } from "@apollo/client";

const GET_BOOKING_BY_ID = gql`
  query getBookingById($id: String!) {
    getBookingById(id: $id) {
      id
      incrementId
      status
      uid
      username
      phone
      pid
      grandTotal
      currency
      createdAt
      completedAt
      children {
        id
        incrementId
        bid
        status
        treatmentId
        treatmentName
        treatmentPrice
        treatmentSrc
        start
        end
        createdAt
        isNotified
        isRescheduled
        rescheduledHistory {
          start
          end
        }
        isRefunded
        refundedHistory {
          transactionId
          amount
          reason
          createdAt
        }
        cancelReason
        cancelledAt
      }
    }
  }
`;

export const GetBookingByIdGql = GET_BOOKING_BY_ID;
