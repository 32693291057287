import "./BottomCarousel.scss";
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import BottomCarouselItem from "./BottomCarouselItem/BottomCarouselItem";

const BottomCarousel = ({ data, viewItem }) => {
    let length = 0;

    if (data.length === 1) {
      length = 1;
    } else {
      length = 4;
    }

    function SliderNextArrow(props) {
        const { onClick } = props;
        return (
          <div
            className={"slick-next-custom"}
            onClick={onClick}
          >
            <AiOutlineRight/>
          </div>
        );
    }

    function SliderPrevArrow(props) {
        const { onClick } = props;
        return (
          <div
            className={"slick-prev-custom"}
            onClick={onClick}
          >
            <AiOutlineLeft/>
          </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: length,
        slidesToScroll: length,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: length === 1 ? length : 3,
                  slidesToScroll: length === 1 ? length : 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: length === 1 ? length : 2,
                  slidesToScroll: length === 1 ? length : 2
                }
            }
        ]
    };

    return (
        <div className="bottom-carousel">
            {viewItem === "treatment" ? <div className="carousel-heading">You May Also Find These Treatment</div> : <div className="carousel-heading">You May Also Like</div> }
            <Slider {...settings} className={`${length === 1 ? "oneImageSlider" : ""}`}>
                {data.map((item) => {
                    return <BottomCarouselItem key={item.id} sentItem={item} length={length} itemType={viewItem}/>
                })}
            </Slider>
        </div>
    )
}

export default BottomCarousel;