import { gql } from "@apollo/client";

const DELETE_TEMP_BOOKINGS = gql`
  mutation deleteTempBookings($ids: [String]!) {
    deleteTempBookings(ids: $ids) {
      status
      message
    }
  }
`;
export const DeleteTempBookingsGql = DELETE_TEMP_BOOKINGS;
