import { gql } from "@apollo/client";

const USER_LOGIN = gql`
  mutation userLogin($username: String!, $password: String!) {
    userLogin(username: $username, password: $password) {
      id
      username
      phone
      isVerified
    }
  }
`;

export const UserLoginGql = USER_LOGIN;
