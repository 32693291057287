import "../AdminContent.scss";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { GetAllFaq } from "../../../../modules/GraphQl/Resolvers/Faq/getAllFaq.gql";
import { DeleteFaqById } from "../../../../modules/GraphQl/Resolvers/Faq/deleteFaqById.gql";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import FaqInterface from "../../../../modules/FAQ/Api/Data/FaqInterface";
import { useHelper } from "../../../Context/HelperProvider";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminFaq = () => {

    const { utility } = useHelper();
    const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

    const { data, refetch } = useQuery(GetAllFaq, {
		context: gqlHeader
	});

    const [
		deleteFaq,
		{ data: deleteData },
	] = useMutation(DeleteFaqById);

    const [ faq, setFaq ] = useState<FaqInterface[]>([]);
	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

    const deleteItemPrompt = (faq: FaqInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${faq.title}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected faq - ${faq.title}.`,
				onClick: deleteItem,
				extra: faq,
			},
		});
	};

    const deleteItem = async (faq: FaqInterface) => {
        await deleteFaq({
            variables: {
                id: faq.id,
            },
			context: gqlHeader
        })
    }

    useEffect(() => {
        if(data) {
            if(data.getAllFaq){
                setFaq([...data.getAllFaq]);
            }
        }
    }, [data])

    useEffect(() => {
		if (deleteData) {
			if (deleteData.deleteFaqById.status) {
				refetch();
			}
		}
	}, [deleteData]);

    const columns: GridColDef[] = [
		{
			field: "title",
			headerName: "Title",
			width: 150,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "description",
			headerName: "Description",
			width: 400,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "class",
			headerName: "Class",
			sortable: true,
			editable: false,
			width: 150,
			align: "left",
		},
		{
			field: "createdAt",
			headerName: "Created at",
			sortable: true,
			editable: false,
			width: 210,
			renderCell: (params: GridRenderCellParams) => {
				return `${utility.dateFormatter(new Date(params.row.createdAt))}`;
			},
			align: "left",
		},
		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							navigate(
								`/${BACKOFFICE_URL}/content/faq/edit/${params.row.id}`
							);
						}}
					>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
			align: "left",
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							deleteItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span>
				);
			},
			align: "left",
		},
	];

    return (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Manage FAQs</span>
                <span></span>
                <ActionButton 
					label={"Add New Faq"}
					title={"Click to add a new Faq."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/content/faq/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={faq}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
    );
}//end AdminFaq()

export default AdminFaq;
