import { gql } from "@apollo/client";

const DELETE_TEMP_BOOKING = gql`
  mutation deleteTempBooking($id: String!) {
    deleteTempBooking(id: $id) {
      status
      message
    }
  }
`;
export const DeleteTempBookingGql = DELETE_TEMP_BOOKING;
