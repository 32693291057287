import { gql } from "@apollo/client";

const CREATE_BULK_REFUND = gql`
  mutation createBulkRefund($refunds: [PaymentRefundInput]! ) {
    createBulkRefund(refunds: $refunds) {
      status
      message
    }
  }
`;
export const CreateBulkRefundGql = CREATE_BULK_REFUND;
