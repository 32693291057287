import { gql } from "@apollo/client";

const GET_USER_BY_FIELD = gql`
  mutation getUserByField($username: String, $phone: String) {
    getUserByField(username: $username, phone: $phone) {
      id
      username
      phone
      isVerified
    }
  }
`;

export const GetUserByFieldGql = GET_USER_BY_FIELD;
