import "../../CoreActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { ProductIngredientsInterface } from "../../../../../modules/Product/Api/Data/ProductInterface";
import { useMutation } from "@apollo/client";
import { CreateIngredient } from "../../../../../modules/GraphQl/Resolvers/Product/createIngredient.gql";
import { useAdmin } from "../../../../Context/AdminProvider";


const AdminAddIngredient = () => {

	const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

	const [ formData, setFormData ] = useState<ProductIngredientsInterface>({
		description: "",
		name: "",
	});

	const [ createIngredient, { data } ] = useMutation(CreateIngredient);
	const [ isDisable, setIsDisable ] = useState(true);

	const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		setIsDisable(validateFormData());
	};

	const validateFormData = () => {
		if (formData.name.length <= 0) {
			return true;
		}
		return false;
	};

	const onSubmit = async () => {
		await createIngredient({
			variables: {
				...formData,
			},
			context: gqlHeader,
		});
	};

	useEffect(() => {
		if (data) {
			if (data.createIngredient.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/core/ingredient`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [data]);

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Add New Ingredients</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Name</td>
						<td>
							<BasicTextInput
								identifier="name"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.name?.length > 0 ? false : true}
								errorText="Name is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={1}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default AdminAddIngredient;
