import { gql } from "@apollo/client";

const CREATE_REAL_BOOKING = gql`
  mutation createRealBooking($bookingIds: [String]!) {
    createRealBooking(bookingIds: $bookingIds) {
      finalAmount
      booking {
        bid
        treatmentId
        treatmentName
        treatmentPrice
        treatmentSrc
        start
        end
      }
      bookingItemIds
      bookingId
    }
  }
`;
export const CreateRealBookingGql = CREATE_REAL_BOOKING;
