import { gql } from "@apollo/client";

const GET_TREATMENTS_FOR_CUSTOMER = gql`
  query getTreatmentsForCustomer {
    getTreatmentsForCustomer {
      id
      isEnable
      title
      price
      description
      images {
        fileName
        src
        position
      }
      urlKey
      createdAt
    }
  }
`;

export const GetTreatmentsForCustomerGql = GET_TREATMENTS_FOR_CUSTOMER;
