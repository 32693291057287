/* eslint-disable react-hooks/exhaustive-deps */
import "../../ActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import FaqInterface from "../../../../../modules/FAQ/Api/Data/FaqInterface";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { CreateFaq } from "../../../../../modules/GraphQl/Resolvers/Faq/createFaq.gql";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { GetAllFaqClass } from "../../../../../modules/GraphQl/Resolvers/Faq/getAllFaqClass.gql";
import { useAdmin } from "../../../../Context/AdminProvider";

const AdminAddFaq = () => {
	const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

    const [ formData, setFormData ] = useState<FaqInterface>({
        title: "",
        description: "",
        class: "",
    });
	const [ dataClass, setDataClass ] = useState<any>([]);
    const [ isDisable, setIsDisable ] = useState(true);
	
    const [ createFaq, { data } ] = useMutation(CreateFaq);
	const { data: ClassData } = useQuery(GetAllFaqClass);

    const handleChangeSelect = (e) => {
        formData["class"] = e.target.value
        setFormData({ ...formData });
        setIsDisable(validateFormData());
    };

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		setIsDisable(validateFormData());
	};

    const validateFormData = () => {
		if (formData.title.length <= 0) {
			return true;
		}

		if (formData.description.length <= 0) {
			return true;
		}

        if (formData.class.length <= 0) {
            return true;
        }
		return false;
	};

	const onSubmit = async () => {
		await createFaq({
			variables: {
				...formData,
			},
			context: gqlHeader
		});
	};

    useEffect(() => {
        if (data) {
            if (data.createFaq.status) {
                const navigateTimer = setTimeout(() => {
                    navigate(`/${BACKOFFICE_URL}/content/faq`);
                    return () => clearTimeout(navigateTimer);
                }, 1000);
            }
        }
    }, [data])

	useEffect(() => {
		if (ClassData) {
			if (ClassData.getAllFaqClass) {
				setDataClass([...ClassData.getAllFaqClass]);
			}
		}
	}, [ClassData])

    return (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Add New FAQ</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={0}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} isRequired="true" errorText={"Description is required."}/>
						</td>
					</tr>
                    <tr>
                        <td className="adminActionHeader">Class</td>
                        <td>
                            <FormControl required sx={{ width: 300, mb: 2 }}>
                                <Select id="select-required"
                                    value={formData.class}
                                    onChange={(e) => handleChangeSelect(e)}
                                >
									{ dataClass ? 
										dataClass.map((item, index) => {
											return <MenuItem key={item.id} value={item.title}>{item.title}</MenuItem>
										}) : 	
										<div>
											<MenuItem value="Product">Product</MenuItem>
											<MenuItem value="Booking">Booking</MenuItem> 
										</div>
									}
                                </Select>
                                <FormHelperText sx={{ ml:0, mr: 0 }}>Required</FormHelperText>
                            </FormControl>
                        </td>
                    </tr>
				</tbody>
			</table>
		</div>
    )
};

export default AdminAddFaq;