import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState, useEffect, useReducer } from "react";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import "../../../AdminAction.scss";
import "./ActionBooking.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import {
	KEY_BOOKING_RESCHEDULE,
} from "../../../../../modules/Storage/Api/ConstantInterface";
import { GetBookingByIdGql } from "../../../../../modules/GraphQl/Resolvers/Booking/getBookingById.gql";
import BookingItemInterface from "../../../../../modules/Booking/Api/Data/BookingInterface";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { BOOKING_STATUS_SELECTION } from "../../../../../modules/Booking/Api/Data/ConstantInterface";
import statusMapper from "../../../../../modules/Booking/Model/StatusMapper";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import editAdminBookingReducer, { editAdminBookingReducerInitialState } from "./EditAdminBookingReducer";
import { default as evt } from "./EditAdminBookingReducerDispatchEvents";
import { CreateRefundGql } from "../../../../../modules/GraphQl/Resolvers/Refund/createRefund.gql";
import { CreateBulkRefundGql } from "../../../../../modules/GraphQl/Resolvers/Refund/createBulkRefund.gql";
import { UpdateBookingItemByIdGql } from "../../../../../modules/GraphQl/Resolvers/Booking/updateBookingItemById.gql";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useHelper } from "../../../../Context/HelperProvider";
import { useAdmin } from "../../../../Context/AdminProvider";

const EditAdminBooking = () => {
	const { cryptor, storage, utility } = useHelper();
	const navigate = useNavigate();
	const location = useLocation();
	const { gqlHeader } = useAdmin();
	const [ state, dispatch ] = useReducer(editAdminBookingReducer, editAdminBookingReducerInitialState);
	const {
		data: initData,
	} = useQuery(GetBookingByIdGql, {
		variables: {
			id: location.pathname.split("/").pop(),
		},
		fetchPolicy: "no-cache",
		context: gqlHeader
	});

	const [
		createRefund,
		{
			data: createRefundData,
		}
	] = useMutation(CreateRefundGql);

	const [
		createBulkRefund,
		{
			data: createBulkRefundData,
		}
	] = useMutation(CreateBulkRefundGql);

	const [
		updateBookingItem,
		{
			data: updateBookingItemData,
		}
	] = useMutation(UpdateBookingItemByIdGql);

	const [isDisable, setIsDisable] = useState(true);

	useEffect(() => {
		if (createRefundData) {
			if (createRefundData.createRefund.status) {
				window.location.reload();
			}
		}
	}, [createRefundData]);

	useEffect(() => {
		if (createBulkRefundData) {
			if (createBulkRefundData.createBulkRefund.status) {
				window.location.reload();
			}
		}
	}, [createBulkRefundData]);

	useEffect(() => {
		if (updateBookingItemData) {
			if (updateBookingItemData.updateBookingItemById.status) {
				window.location.reload();
			}
		}
	}, [updateBookingItemData]);

	useEffect(() => {
		if (initData) {
			if (typeof initData.getBookingById.id !== "string") {
				navigate(`/${BACKOFFICE_URL}/core/booking`);
			} else {
				dispatch({ type: evt.EVT_SET_PHONE, payload: initData.getBookingById.phone });
				dispatch({ type: evt.EVT_SET_BOOKING_ID, payload: initData.getBookingById.id });
				dispatch({ type: evt.EVT_SET_PAYMENT_ID, payload: initData.getBookingById.pid });
				initData.getBookingById.children.forEach((bookingItem: BookingItemInterface) => {
					dispatch({ type: evt.EVT_UPDATE_STATUS, payload: { id: bookingItem.id, status: bookingItem.status } });
				});
			}
		}
	}, [initData]);

	useEffect(() => {
		if (state.bookingId && state.paymentId) {
			preprocessBulkRefund();
		}
	}, [state.bookingId, state.paymentId]);

	const onSubmit = async () => { 
		state.status.forEach(async (item: BookingItemInterface) => {
			let data: any = {
				status: item.status,
				parentId: state.bookingId,
			};

			if (item.status === 'cancelled') {
				data.cancelReason = 'Cancelled by admin.';
			}
			await updateBookingItem({
				variables: {
					id: item.id,
					data: cryptor.encrypt(JSON.stringify(data))
				},
				context: gqlHeader
			})
		})
	};

	const bookingStatus = (currentStatus: any) => {
		const availableStatuses = BOOKING_STATUS_SELECTION.filter((status: any) => { return status.key === currentStatus });
		let statusSelect: any = [];
		availableStatuses[0].value.map((status: any) => {
			statusSelect.push(<MenuItem value={status}>{statusMapper(status)}</MenuItem>);
		});
		return statusSelect;
	}

	const onStatusChange = (e: SelectChangeEvent, id: string) => {
		dispatch({ type: evt.EVT_UPDATE_STATUS, payload: { id: id, status: e.target.value } });
		setIsDisable(false);
	}

	const getCurrentStatus = (id :any) => {
		let filterStatus: any = state.status.filter((status: any) => { return status.id === id});
		let currentStatus: any = 'new';
		if (filterStatus.length > 0) {
			currentStatus = filterStatus[0].status;
		}
		return currentStatus;
	}

	const onRefund = async (data: any) => {
		await createRefund({
			variables: {
				paymentId: state.paymentId,
				bookingId: state.bookingId,
				bookingItemId: data.id,
				amount: data.treatmentPrice
			},
			context: gqlHeader
		})
	}

	const onBulkRefund = async () => {
		await createBulkRefund({
			variables: {
				refunds: state.bulkRefund
			},
			context: gqlHeader
		})
	}

	const preprocessBulkRefund = () => {
		let children: BookingItemInterface[] = initData.getBookingById.children;
		let filtered = children.filter((child: BookingItemInterface) => { return ['paid', 'absent', 'rescheduled'].includes(child.status) });
		if (filtered.length > 0) {
			let bulkRefund: any = [];
			filtered.forEach((filter: BookingItemInterface) => {
				bulkRefund.push({
					paymentId: state.paymentId,
					bookingId: state.bookingId,
					bookingItemId: filter.id,
					amount: filter.treatmentPrice
				})
			});
			dispatch({ type: evt.EVT_SET_CAN_BULK_REFUND, payload: false });
			dispatch({ type: evt.EVT_SET_BULK_REFUND, payload: bulkRefund });
		}
	}

	const columns: GridColDef[] = [
		{
			field: "incrementId",
			headerName: "Booking Item ID",
			width: 200,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<>#{params.row.incrementId}</>
				);
			},
		},
		{
			field: "statuses",
			headerName: "Booking Status",
			width: 200,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<FormControl fullWidth>
						<InputLabel id={`bookingItemStatusLabel${params.row.id}`}>Status</InputLabel>
						<Select
							labelId={`bookingItemStatusLabelId${params.row.id}`}
							id={`bookingItemStatusId${params.row.id}`}
							value={getCurrentStatus(params.row.id)}
							label="Status"
							onChange={(e: any) => onStatusChange(e, params.row.id)}
							disabled={['cancelled', 'refunded', 'completed'].includes(params.row.status) ? true : false }
						>
							{ bookingStatus(params.row.status) }
						</Select>
					</FormControl>
				);
			},
		},
        {
            field: 'contact',
            headerName: 'Contact Customer',
            width: 150,
            sortable: true,
            editable: false,
			renderCell: (params: GridRenderCellParams) => {
                return (
					<div className="bookingCallDiv">
						<ActionButton
							label={'Call Customer'} 
							title={'Click to call customer'} 
							icon={<LocalPhoneIcon/>}
							isDisable={['cancelled', 'refunded', 'completed'].includes(params.row.status) ? true : false }
							onClick={() => {
								window.open(`tel:${state.phone}`, '_self');
							}}                           
							canRateLimit={true}
							rateLimitCount={10}
							rateLimitFor={3} 
							styling={'icon-transparent'}
						/>
					</div>
                );
            }
        },
		{
			field: "treatmentSrc",
			headerName: "Thumbnail",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<div className="gridTableImgDiv">
						<img className="gridTableImg" src={params.row.treatmentSrc} alt="gridTableImg"/>
					</div>
				);
			},
		},
		{
			field: "treatmentName",
			headerName: "Treatment",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<>{params.row.treatmentName}</>
				);
			},
		},
		{
			field: "treatmentPrice",
			headerName: "Price",
			width: 130,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => (
				<span>RM {params.row.treatmentPrice}</span>
			),
			align: "left",
			sortComparator: utility.dataGridPriceComparator,
		},
		{
			field: "timeslot",
			headerName: "Booking timeslot",
			width: 150,
			renderCell: (params: GridRenderCellParams) => (
				<>{new Date(params.row.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, {new Date(params.row.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - {new Date(params.row.end).toLocaleTimeString('en-US', { hour: '2-digit'})}</>
			)
		},
		{
			field: "reschedule",
			headerName: "Reschedule",
			width: 400,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					params.row.isRescheduled ? 
					<>Previous timeslot: <br/>{new Date(params.row.rescheduledHistory.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, {new Date(params.row.rescheduledHistory.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - {new Date(params.row.rescheduledHistory.end).toLocaleTimeString('en-US', { hour: '2-digit'})}</> :
					['confirmed', 'in_progress', 'cancelled', 'refunded', 'completed'].includes(params.row.status) ? 
					<>Reschedule for this booking is not allowed.</> : 
					<>
						<div className="bookingCallDiv">
							<ActionButton 
								label={'Reschedule'} 
								title={'Click to reschedule.'} 
								isDisable={false}
								icon={<EventRepeatIcon/>}
								onClick={() => {onReschedule(params.row)}}
								canRateLimit={true}
								rateLimitCount={10}
								rateLimitFor={3}
								styling={'icon-transparent'}
							/>
						</div>
					</>
				);
			},
		},
		{
			field: "refund",
			headerName: "Refund (For Absent ONLY)",
			width: 400,
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					params.row.isRefunded ? 
					<>Refund created at: <br/>{new Date(params.row.refundedHistory.createdAt).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, {new Date(params.row.refundedHistory.createdAt).toLocaleTimeString('en-US', { hour: '2-digit'})}</> :
					getCurrentStatus(params.row.id) === 'absent' ?
					<>
						<div className="bookingCallDiv">
							<ActionButton 
								label={'Refund'} 
								title={'Click to refund.'} 
								isDisable={false}
								icon={<AccountBalanceIcon/>}
								onClick={() => {onRefund(params.row)}}
								canRateLimit={true}
								rateLimitCount={10}
								rateLimitFor={3}
								styling={'icon-transparent'}
							/>
						</div>
					</> : <>Refund for this booking is not allowed.</>
				);
			},
		},
    ];

	const onReschedule = (data: any) => {
		storage.setItem(KEY_BOOKING_RESCHEDULE, JSON.stringify({...data, parentId: initData.getBookingById.id}));
		navigate(`/${BACKOFFICE_URL}/core/booking/reschedule/${data.id}`)
	}

	return initData && initData.getBookingById &&
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
				<span>#{initData.getBookingById.incrementId}</span>
				<ActionButton
					label={"Refund All"}
					title={"Click to refund all."}
					isDisable={state.canBulkRefund}
					onClick={() => onBulkRefund()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
				/>
				<ActionButton
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
				/>
			</span>
			<div className="adminActionContDiv">
				<div className='dataGrid'>
				{
					initData && initData.getBookingById && initData.getBookingById.children &&
					<DataGrid
						rows={initData.getBookingById.children}
						columns={columns}
						getRowHeight={() => 'auto'} getEstimatedRowHeight={() => 200}
						pageSize={5}
						rowsPerPageOptions={[5]}
					/>
				}
				</div>
			</div>
		</div>
}; //end EditAdminBooking()

export default EditAdminBooking;
