import { gql } from "@apollo/client";

export const BOOKING_FRAGMENT = gql`
    fragment BookingFragment on Booking {
        id
        incrementId
        status
        uid
        username
        pid
        grandTotal
        grandTotalPaid
        currency      
        createdAt
        refundedAt
        completedAt
        children {
            id
            incrementId
            bid
            status
            treatmentId
            treatmentName
            treatmentPrice
            treatmentSrc
            start
            end
            createdAt
            canNotify
            isNotified
            isRescheduled
            rescheduledHistory {
                start
                end
            }
            isRefunded
            refundedHistory {
                transactionId
                amount
                reason
                createdAt
            }
            cancelledAt
            cancelReason
        }
        isFailedPayment
        repaymentTimeout
        repaymentId
    }
`;