import "../AdminCore.scss";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../Common/Modal/Modal";
import ModalInterface from "../../../Common/Modal/ModalInterface";
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { GetAllIngredients } from "../../../../modules/GraphQl/Resolvers/Product/getAllIngredients.gql";
import { RemoveIngredientById } from "../../../../modules/GraphQl/Resolvers/Product/removeIngredientById.gql";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { ProductIngredientsInterface } from "../../../../modules/Product/Api/Data/ProductInterface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminIngredient = () => {
	const navigate = useNavigate();
	const { gqlHeader } = useAdmin();

	const { data, refetch } = useQuery(GetAllIngredients, {
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});

	const [
		removeIngredient,
		{ data: deleteData },
	] = useMutation(RemoveIngredientById);

	const [ingredients, setIngredients] = useState<ProductIngredientsInterface[]>(
		[]
	);

	const [modal, setModal] = useState<ModalInterface>({
		isModalActive: false,
	});

	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			width: 200,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "description",
			headerName: "Description",
			width: 400,
			sortable: true,
			editable: false,
			align: "left",
		},
		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			editable: false,
			width: 100,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							navigate(
								`/${BACKOFFICE_URL}/core/ingredient/edit/${params.row.id}`
							);
						}}
					>
						<EditIcon fontSize="small" />
						Edit
					</span>
				);
			},
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			editable: false,
			width: 70,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<span
						className="actionTab"
						onClick={() => {
							removeItemPrompt(params.row);
						}}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</span>
				);
			},
			align: "left",
		},
	];

	useEffect(() => {
		if (data) {
			if (data.getAllIngredients) {
				setIngredients([...data.getAllIngredients]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (deleteData) {
			if (deleteData.removeIngredientById.status) {
				refetch();
			}
		}
	}, [deleteData]);

	const removeItemPrompt = (ingredient: ProductIngredientsInterface) => {
		setModal({
			...modal,
			isModalActive: true,
			title: `Are you sure you want to delete ${ingredient.name}?`,
			content: <div className="infoLabel">This process is irreversible!</div>,
			action: {
				label: "Confirm",
				title: `Click to confirm removing the selected ingredients - ${ingredient.name}.`,
				onClick: removeItem,
				extra: ingredient,
			},
		});
	};

	const removeItem = async (ingredient: ProductIngredientsInterface) => {
		await removeIngredient({
			variables: {
				id: ingredient.id,
			},
			context: gqlHeader
		});
	};

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionHeading">
                <span>Manage Ingredients</span>
                <span></span>
                <ActionButton 
					label={"Add New Ingredient"}
					title={"Click to add a new ingredient."}
					isDisable={false}
					onClick={() => {
						navigate(`/${BACKOFFICE_URL}/core/ingredient/add`);
					}}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<div className="adminActionContDiv">
				<div className="dataGrid">
					<DataGrid
						rows={ingredients}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5]}
					/>
				</div>
			</div>
			<Modal
				isModalActive={modal.isModalActive}
				onModalClose={() => {
					setModal({ ...modal, isModalActive: false });
				}}
				title={modal.title}
				content={modal.content}
				action={modal.action}
				showCancelButton={false}
				showCancelIcon={true}
			/>
		</div>
	);
}; //end AdminIngredient()

export default AdminIngredient;
