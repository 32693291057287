import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { BACKOFFICE_URL } from "../../modules/Api/Data/ConstantInterface";
import SharedLayout from "../SharedLayout/SharedLayout";
import Home from "../Home/Home";
import AboutUs from "../AboutUs/AboutUs/AboutUs";
import UserRegister from "../UserRegister/UserRegister";
import UserLogin from "../UserLogin/UserLogin";
import UserVerification from "../UserVerification/UserVerification";
import UserForgotPassword from "../UserForgotPassword/UserForgotPassword";
import Booking from "../Booking/Booking";
import MyAccount from "../MyAccount/MyAccount";
import AdminLogin from "../AdminLogin/AdminLogin";
import AdminPanel from "../AdminPanel/AdminPanel";
import StripePayment from "../StripePayment/StripePayment";
import StripePaymentRedirect from "../StripePaymentRedirect/StripePaymentRedirect";
import StripePaymentResult from "../StripePaymentResult/StripePaymentResult";
import TreatmentList from "../Treatments/TreatmentList/TreatmentList";
import SingleTreatmentDetail from "../Treatments/SingleTreatmentDetail/SingleTreatmentDetail";
import FAQ from "../FAQ/FAQ";
import ProductListing from "../Products/ProductListing/ProductListing";
import SingleProduct from "../Products/SingleProducts/SingleProduct";
import Error404 from "../Error404/Error404";
import AdminError404 from "../Error404/AdminError/AdminError404";

const Routes = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route caseSensitive path="/" element={<SharedLayout />}>
				<Route caseSensitive index element={<Home />} />
				<Route caseSensitive path="about_us" element={<AboutUs />} />
				<Route caseSensitive path="treatments">
					<Route caseSensitive index element={<TreatmentList />} />
					<Route caseSensitive path=":urlKey" element={<SingleTreatmentDetail />} />
				</Route>
				<Route caseSensitive path="products">
					<Route caseSensitive index element={<ProductListing />} />
					<Route caseSensitive path=":urlKey" element={<SingleProduct />} />
				</Route>
				<Route caseSensitive path="faq" element={<FAQ />} />
				<Route caseSensitive path="login" element={<UserLogin />} />
				<Route caseSensitive path="register" element={<UserRegister />} />
				<Route caseSensitive path="verify" element={<UserVerification />} />
				<Route
					caseSensitive
					path="reset_password"
					element={<UserForgotPassword />}
				/>
				<Route caseSensitive path="booking" element={<Booking />}/>
				<Route caseSensitive path="payment" element={<StripePayment />}/>
				<Route caseSensitive path="payment/redirect" element={<StripePaymentRedirect />}/>
				<Route caseSensitive path="payment/:status" element={<StripePaymentResult />}/>
				<Route caseSensitive path="user" element={<MyAccount />} />
				<Route caseSensitive path="user/:username" element={<MyAccount />} />
				<Route
					caseSensitive
					path="user/:username/:section"
					element={<MyAccount />}
				/>
				<Route
					caseSensitive
					path="user/:username/:section/:sectionAction"
					element={<MyAccount />}
				/>
				<Route path="/*" element={<Error404 />}/>
			</Route>
			<Route>
				<Route caseSensitive path={BACKOFFICE_URL} element={<AdminLogin />} />
				<Route path={`${BACKOFFICE_URL}/*`} element={<AdminError404 />} />
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/dashboard`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/booking`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/booking/edit/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/booking/reschedule/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/booking/today`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/product`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/product/add`}
					element={<AdminPanel />}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/product/edit/:id`}
					element={<AdminPanel />}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/ingredient`}
					element={<AdminPanel />}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/ingredient/add`}
					element={<AdminPanel />}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/ingredient/edit/:id`}
					element={<AdminPanel />}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/treatment`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/treatment/add`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/treatment/edit/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/core/config`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/hero`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/hero/add`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/hero/edit/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/faq`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/faq/add`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/faq/edit/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/class-faq`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/content/class-faq/add`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/user/customer`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/user/customer/edit/:id`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/user/admin`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/user/admin/add`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/transaction/payment`}
					element={<AdminPanel/>}
				/>
				<Route
					caseSensitive
					path={`${BACKOFFICE_URL}/transaction/refund`}
					element={<AdminPanel/>}
				/>
			</Route>
		</Route>
	)
);

export default Routes;
