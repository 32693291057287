import { gql } from "@apollo/client";
import { BOOKING_FRAGMENT } from "../../Fragments/BookingFragment";

const GET_BOOKINGS_BY_UID = gql`
  ${BOOKING_FRAGMENT}
  mutation getBookingsByUid($uid: String!, $status: String!, $lastVisibleId: String!) {
    getBookingsByUid(uid: $uid, status: $status, lastVisibleId: $lastVisibleId) {
      ...BookingFragment
    }
  }
`;

export const GetBookingsByUidMutationGql = GET_BOOKINGS_BY_UID;
