import "./BenefitsList.scss";
import { useState } from "react";
import noColorants from "../../assets/logo/benefits/1-NoColorants.svg";
import noFormaldehydes from "../../assets/logo/benefits/1-NoFormaldehydes.svg";
import noFragrance from "../../assets/logo/benefits/1-NoFragrance.svg";
import noParaben from "../../assets/logo/benefits/1-NoParaben.svg";
import noSilicone from "../../assets/logo/benefits/1-NoSilicone.svg";
import noSulfate from "../../assets/logo/benefits/1-NoSulfate.svg";

const BenefitsList = () => {

    const [benefits, setBenefits] = useState([
        {
            title: "No Colorants",
            images: noColorants,
        },
        {
            title: "No Formaldehydes",
            images: noFormaldehydes,
        },
        {
            title: "No Fragrance",
            images: noFragrance,
        },
        {
            title: "No Paraben",
            images: noParaben,
        },
        {
            title: "No Silicone",
            images: noSilicone,
        },
        {
            title: "No Sulfate",
            images: noSulfate,
        }
    ]);

    return (
        <div className="benefitsListContainer">
            <div className="benefitsDiv">
                <div className="benefitsTitle">
                    <h1>Benefits with Clean Formula</h1>
                </div>
                <div className="benefitsContent">
                    {benefits.map((item, index) => {
                        return (
                            <div className="benefits" key={index}>
                                <div className="benefitsImage">
                                    <img src={item.images}></img>
                                </div>
                                <div className="benefitsDetails">
                                    <div className="detailsTitle">
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BenefitsList;