/* eslint-disable react-hooks/exhaustive-deps */
import "../../ActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import ImageUpload from '../../../../Common/ImageUpload/ImageUpload';
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { BannerImageInputInterface } from "../../../../../modules/Banner/Api/Data/BannerInterface";
import { GetBannerById } from "../../../../../modules/GraphQl/Resolvers/Banner/getBannerById.gql";
import { UpdateBannerById } from "../../../../../modules/GraphQl/Resolvers/Banner/updateBannerById.gql";
import { useHelper } from "../../../../Context/HelperProvider";
import { useAdmin } from "../../../../Context/AdminProvider";

const AdminAddHero = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { cryptor, utility } = useHelper();
	const { gqlHeader } = useAdmin();

	const { data: initData } = useQuery(GetBannerById, {
		variables: {
			id: location.pathname.split("/").pop(),
		},
		context: gqlHeader
	})
	const [ updateBannerById, { data: updateData } ] = useMutation(UpdateBannerById);

    const [ formData, setFormData ] = useState<any>({
        title: "",
        description: "",
        isEnabled: true,
		images: [""],
    });
	const [oldData, setOldData] = useState<any>({
		images: [],
	});
    const [ isDisable, setIsDisable ] = useState(true);
	const [fieldsToBeUpdated, setFieldsToBeUpdated] = useState<string[]>([]);

	const onSwitchChange = (e: any) => {
		setFormData({
			...formData,
			isEnable: e.target.checked,
		});
		updateFieldsToBeUpdated("isEnable");
	};

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		updateFieldsToBeUpdated(identifier);
	};

	const updateFieldsToBeUpdated = (field: string) => {
		if (!fieldsToBeUpdated.includes(field)) {
			fieldsToBeUpdated.push(field);
		}
		setFieldsToBeUpdated([...fieldsToBeUpdated]);
	};

    const validateFormData = () => {
		if (formData.title.length <= 0) {
			return true;
		}

		if (formData.description.length <= 0) {
			return true;
		}

        if (formData.images.length <= 0) {
            return true;
        }
		return false;
	};

	const onUpload = (position: number, fileObj: any) => {
        let image: BannerImageInputInterface = {
            base64: fileObj.base64,
            contentType: fileObj.type,
            fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
            position: position
        };
        /** @ts-ignore */
		let tempImage: any = [...formData.images];
		tempImage[position] = image;
		updateFieldsToBeUpdated(`images[${position}]`);
        setFormData({...formData, images: tempImage});
    }

	const onDelete = (position: number) => {
		if (oldData.images[position] && oldData.images[position].hasOwnProperty('src')) {
			setFormData({ ...formData, images: formData.images.filter((img: any) => { return img.position !== position }) });
			updateFieldsToBeUpdated(`images[${position}]_del`);
		}
	};

	const onSubmit = async () => {
		let data: any = {
			oldImages: [],
			images: oldData.images.length > 0 ? [...oldData.images] : [],
		};

		fieldsToBeUpdated.forEach((field: string) => {
			if (field === "images[0]") {
				if(oldData.images[0]) {
					data["oldImages"].push(oldData.images[0].src);
				}
				data.images[0] = formData.images[0];
			} else if (field === "images[0]_del") {
				data["oldImages"].push(oldData.images[0].src); 
				let filtered: any[] = [...data.images.filter((image: any) => { return image.position !== 0 })];
				data.images.splice(0, filtered.length, ...filtered);
			} else {
				data[field] = formData[field];
			}
		});

		data.images = data.images.filter((image: any) => { return image });
		let processedImage: any = [];
		data.images.forEach((image: any, index: number) => {
			processedImage.push({
				...image,
				position: index
			});
		})

		data.images = [...processedImage];

		await updateBannerById({
			variables: {
				id: location.pathname.split("/").pop(),
				data: cryptor.encrypt(JSON.stringify(data)),
			},
			context: gqlHeader
		});
	};

	useEffect(() => {
        if (initData) {
            if (typeof initData.getBannerById.id !== "string") {
                navigate(`/${BACKOFFICE_URL}/content/hero}`);
            } else {
                setFormData({...initData.getBannerById});
				const tempThumbnail: any = [];
				tempThumbnail.push(...initData.getBannerById.images);
				setOldData({images: tempThumbnail});
            }
        }
    }, [initData])

	useEffect(() => {
		if (updateData) {
			if (updateData.updateBannerById.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/content/hero`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [updateData]);

	useEffect(() => {
		if (fieldsToBeUpdated.length > 0) {
			setIsDisable(validateFormData());
		}
	}, [fieldsToBeUpdated]);

    return initData && initData.getBannerById ? (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>{initData.getBannerById.title}</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
                        <td className='adminActionHeader'>Enable</td>
                        <td><Switch onChange={onSwitchChange} checked={formData.isEnabled}/></td>
                    </tr>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								value={initData.getBannerById.title}
								tabIndex={0}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} value={initData.getBannerById.description} />
						</td>
					</tr>
					<tr>
                        <td className='adminActionHeader'>Main thumbnail</td>
                        <td>
							{initData.getBannerById.images && 
							initData.getBannerById.images[0] ? ( 
								<ImageUpload position={0} onUpload={onUpload} imgSrc={initData.getBannerById.images[0].src} onDelete={onDelete} isRequired/> 
							) : ( 
								<ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired/>
							)}	
						</td>
                    </tr>
				</tbody>
			</table>
		</div>
    ) : null;
};

export default AdminAddHero;