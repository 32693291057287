import "../../CoreActionAdmin.scss";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import ImageUpload from '../../../../Common/ImageUpload/ImageUpload';
import MultiSelectChip from '../../../../Common/MultiSelectChip/MultiSelectChip';
import { Switch, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ProductImageInputInterface } from "../../../../../modules/Product/Api/Data/ProductInterface";
import { CreateProduct } from "../../../../../modules/GraphQl/Resolvers/Product/createProduct.gql";
import { useHelper } from "../../../../Context/HelperProvider";
import { useAdmin } from "../../../../Context/AdminProvider";
import AdminProductVariation from "./AdminProductVariation/AdminProductVariation";

const AdminAddProduct = () => {
	const { utility } = useHelper();
	const { gqlHeader } = useAdmin();
	const navigate = useNavigate();

	const [formData, setFormData] = useState<any>({
		title: "",
		description: "",
		isEnabled: false,
		capacity: "",
		price: "",
		ingredients: [""],
		functionalities: [""],
		usageDirection: [""],
		thumbnail: [""],
		urlKey: "",
	});

	const [createProduct, { data }] = useMutation(CreateProduct);

	const [isDisable, setIsDisable] = useState(true);

	const addFunction = () => {
		setFormData({ ...formData, functionalities: [...formData.functionalities, ""] })
	}

	const removeFunction = (index) => {
		const list = formData.functionalities;
		list.splice(index, 1);
		setFormData({ ...formData, functionalities: list });
	}

	const functionChange = (e, index) => {
		const value = utility.escapeEmoji(e.target.value);
		const list = formData.functionalities;
		list[index] = value;
		setFormData({ ...formData, functionalities: list });
	}

	const addUsage = () => {
		setFormData({ ...formData, usageDirection: [...formData.usageDirection, ""] })
	}

	const removeUsage = (index) => {
		const usageList = formData.usageDirection;
		usageList.splice(index, 1);
		setFormData({ ...formData, usageDirection: usageList })
	}

	const usageChange = (e, index) => {
		const value = utility.escapeEmoji(e.target.value);
		const usageList = formData.usageDirection;
		usageList[index] = value;
		setFormData({ ...formData, usageDirection: usageList });
	}

	const onSwitchChange = (e: any) => {
		setFormData({
			...formData,
			isEnabled: e.target.checked
		});
	}

	const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
	}

	const onVariantChange = (variant: any) => {
		let newPrice: string[] = [];
		let newCapacity: string[] = [];
		variant.forEach((item: any) => {
			newPrice.push(item.price);
			newCapacity.push(item.capacity);
		});
		formData["price"] = newPrice.toString();
		formData["capacity"] = newCapacity.toString();
		setFormData({ ...formData });
	};

	const chipChange = (value: any) => {
		const temp: any = [];
		value.map((item) => {
			temp.push("/ingredients/" + item);
		})
		formData.ingredients = temp;
		setFormData({ ...formData });
	}

	const validateFormData = () => {
		if (formData.price.length <= 0) {
			return true;
		}

		if (formData.capacity.length <= 0) {
			return true;
		}

		if (formData.description.length <= 0) {
			return true;
		}

		if (formData.title.length <= 0) {
			return true;
		}

		if (typeof formData.thumbnail[0] === 'undefined') {
			return true;
		}

		if (formData.ingredients[0] === "" || formData.ingredients.length <= 0) {
			return true;
		}

		if (formData.thumbnail[0].length <= 0) {
			return true;
		}
		return false;
	}

	const onUpload = (position: number, fileObj: any) => {
		let image: ProductImageInputInterface = {
			base64: fileObj.base64,
			contentType: fileObj.type,
			fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
			position: position
		};
		/** @ts-ignore */
		formData.thumbnail[position] = image;
		setFormData({ ...formData });
	}

	const onDelete = (position: number) => {
		setFormData({ ...formData, thumbnail: formData.thumbnail.filter((thumbnail: any) => { return thumbnail.position !== position }) });
	};

	const onSubmit = async () => {

		const urlKey = utility.urlKeyCreation(formData.title);

		/** @ts-ignore */
		const finalthumbnail = formData.thumbnail.filter((item: any) => {
			if (Object.keys(item).length !== 0) {
				return true;
			}
		})

		//Move their position to respective index
		finalthumbnail.map((item, index) => {
			item.position = index;
		})

		//Update all prices since price is now in string separated by commas
		let tempPrice = formData["price"].split(",");
		tempPrice.map((price: string) => {
			utility.preprocessPrice(price);
		});
		formData["price"] = tempPrice.toString();

		await createProduct({
			variables: {
				...formData,
				thumbnail: finalthumbnail,
				urlKey: urlKey,
			},
			context: gqlHeader
		});
	}

	useEffect(() => {
		if (data) {
			if (data.createProduct.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/core/product`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [data]);

	useEffect(() => {
		setIsDisable(validateFormData());
	}, [formData]);

	return (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
				<span>Confirm</span>
				<span></span>
				<ActionButton
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
					styling={'basic-brown'}
				/>
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className='adminActionHeader'>Enable</td>
						<td><Switch onChange={onSwitchChange} /></td>
					</tr>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={1}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} />
						</td>
					</tr>
					<AdminProductVariation
						price={formData.price}
						capacity={formData.capacity}
						onVariantChange={onVariantChange}
					/>
					<tr>
						<td className='adminActionHeader'>Ingredients</td>
						<MultiSelectChip onChange={chipChange} isRequired={true} errorText="At least one ingredient is required" />
					</tr>
					<tr>
						<td className='adminActionHeader'>Functionalities</td>
						{formData.functionalities.map((singleItem, index) => (
							<div key={index}>
								<TextField sx={{ width: "300px" }} variant="outlined" id="function" name="function" value={singleItem} onChange={(e) => functionChange(e, index)} />
								<div className="first-division">
									{formData.functionalities.length - 1 === index && formData.functionalities.length < 4 && (
										<button type="button" className="add-btn" onClick={addFunction}>
											<span>Add Functionalities</span>
										</button>
									)}
								</div>
								<div className="second-division">
									{formData.functionalities.length > 1 && (
										<button type="button" className="remove-btn" onClick={() => removeFunction(index)}>
											<span>Remove</span>
										</button>
									)}
								</div>
							</div>
						))}
					</tr>

					<tr>
						<td className='adminActionHeader'>Usage Direction</td>
						{formData.usageDirection.map((singleItem, index) => (
							<div key={index}>
								<TextField sx={{ width: "300px" }} variant="outlined" id="function" name="function" value={singleItem} onChange={(e) => usageChange(e, index)} />
								<div className="first-division">
									{formData.usageDirection.length - 1 === index && formData.usageDirection.length < 4 && (
										<button type="button" className="add-btn" onClick={addUsage}>
											<span>Add Usage Direction</span>
										</button>
									)}
								</div>
								<div className="second-division">
									{formData.usageDirection.length > 1 && (
										<button type="button" className="remove-btn" onClick={() => removeUsage(index)}>
											<span>Remove</span>
										</button>
									)}
								</div>
							</div>
						))}
					</tr>

					<tr>
						<td className='adminActionHeader'>Main thumbnail</td>
						<td><ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired /></td>
					</tr>
					<tr>
						<td className='adminActionHeader'>Secondary thumbnail</td>
						<td><ImageUpload position={1} onUpload={onUpload} onDelete={onDelete} /></td>
					</tr>
					<tr>
						<td className='adminActionHeader'>Third thumbnail</td>
						<td><ImageUpload position={2} onUpload={onUpload} onDelete={onDelete} /></td>
					</tr>
					<tr>
						<td className='adminActionHeader'>Fourth thumbnail</td>
						<td><ImageUpload position={3} onUpload={onUpload} onDelete={onDelete} /></td>
					</tr>
					<tr>
						<td className='adminActionHeader'>Fifth thumbnail</td>
						<td><ImageUpload position={4} onUpload={onUpload} onDelete={onDelete} /></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default AdminAddProduct;
