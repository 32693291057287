import { gql } from "@apollo/client";

const CREATE_ADMIN = gql`
  mutation createAdmin($username: String!, $password: String!) {
    createAdmin(username: $username, password: $password) {
      status
      message
    }
  }
`;

export const CreateAdminGql = CREATE_ADMIN;
