import { gql } from "@apollo/client";

const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      username
      phone
      isVerified
      createdAt
    }
  }
`;

export const GetAllUsersGql = GET_ALL_USERS;
