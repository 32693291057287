import { gql } from "@apollo/client";

const ADMIN_LOGIN = gql`
  mutation adminLogin($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      id
      username
    }
  }
`;

export const AdminLoginGql = ADMIN_LOGIN;
