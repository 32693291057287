import { gql } from "@apollo/client";

const CREATE_TREATMENT = gql`
  mutation createTreatment($title: String!, $isEnable: Boolean!, $price: String!, $description: String!, $images: [TreatmentImageInput]!, $urlKey: String!) {
    createTreatment(title: $title, isEnable: $isEnable, price: $price, description: $description, images: $images, urlKey: $urlKey) {
      status
      message
    }
  }
`;

export const CreateTreatmentGql = CREATE_TREATMENT;
