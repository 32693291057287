import { gql } from "@apollo/client";

const GET_BOOKINGS_FOR_ADMIN = gql`
  query getBookingsForAdmin {
    getBookingsForAdmin {
      id
      incrementId
      status
      uid
      username
      pid
      grandTotal
      currency
      createdAt
      completedAt
      children {
        id
        status
        treatmentId
        treatmentName
        treatmentPrice
        treatmentSrc
        start
        end
        createdAt
      }
    }
  }
`;

export const GetBookingsForAdminGql = GET_BOOKINGS_FOR_ADMIN;
