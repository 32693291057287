import { useState, useEffect } from 'react';
import ActionButton from '../../../../Common/ActionButton/ActionButton';
import BasicTextInput from '../../../../Common/BasicTextInput/BasicTextInput';
import '../../../AdminAction.scss';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { BACKOFFICE_URL } from '../../../../../modules/Api/Data/ConstantInterface';
import { KEY_ADMIN_ADMINS } from '../../../../../modules/Storage/Api/ConstantInterface';
import { CreateAdminGql } from '../../../../../modules/GraphQl/Resolvers/Admin/createAdmin.gql';
import { AdminInterface } from '../../../../../modules/Api/Data/DocumentInterface';
import { useHelper } from '../../../../Context/HelperProvider';
import { useAdmin } from '../../../../Context/AdminProvider';

const AddAdmin = () => {

    const { storage } = useHelper();
    const { gqlHeader } = useAdmin();
    const navigate = useNavigate();
    const [ formData, setFormData ] = useState<AdminInterface>({
        username: '',
        password: ''
    });

    const [ createAdmin, { data, loading, error } ] = useMutation(CreateAdminGql);
    const [ isDisable, setIsDisable ] = useState(true);

    useEffect(() => {
        if (data) {
            if (data.createAdmin.status) {
                storage.deleteItem(KEY_ADMIN_ADMINS);
                const navigateTimer = setTimeout(() => {
                    navigate(`/${BACKOFFICE_URL}/user/admin`);
                    return () => clearTimeout(navigateTimer);
                }, 1000);
            }
        }
    }, [data]);

    const onChange = (value: string, identifier: string) => {
        formData[identifier] = value;
        setFormData({...formData});

        setIsDisable(validateFormData());
    }

    const onSubmit = async () => {
        await createAdmin({
            variables: {
                ...formData           
            },
            context: gqlHeader
        });
    }

    const validateFormData = () => {
        /** @ts-ignore */
        if (formData.username?.length <= 0) {
            return true;
        }
        /** @ts-ignore */
        if (formData.password?.length <= 0) {
            return true;
        }

        return false;
    }

    return (
        <div className='adminActionDiv'>
            <span className="adminActionHeading adminActionBookingHeading">
                <span>Add New Admin</span>
                <span></span>
                <ActionButton 
                    label={'Confirm'} 
                    title={'Click to submit.'} 
                    isDisable={isDisable}
                    onClick={() => onSubmit()}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
            <table className='adminActionTable'>
                <tbody>
                    <tr>
                        <td className='adminActionHeader'>Username</td>
                        <td>                            
                            <BasicTextInput 
                                identifier='username'
                                isRequired={true}
                                maxLength={15}
                                /** @ts-ignore */
                                canShowError={formData.username?.length > 0 ? false : true}
                                errorText="Username is required."
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={1}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='adminActionHeader'>Password</td>
                        <td>
                            <BasicTextInput 
                                identifier='password'
                                isRequired={true}
                                maxLength={15}
                                hasPassword={true}
                                /** @ts-ignore */
                                canShowError={formData.password.length > 0 ? false : true}
                                errorText="Password is required."
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={2}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}//end AddAdmin()

export default AddAdmin;
