enum BOOKING_REDUCER_DISPATCH_EVENTS_ENUM {
    EVT_ADD_RESERVED_BOOKING_COUNT = 'add_reserved_booking_count',
    EVT_MINUS_RESERVED_BOOKING_COUNT  = 'minus_reserved_booking_count',
    EVT_IS_CALENDAR_OPEN = 'is_calendar_open',
    EVT_SET_SLOT_MIN_TIME = 'set_slot_min_time',
    EVT_SET_SLOT_MAX_TIME = 'set_slot_max_time',
    EVT_SET_SELECTED_DATE = 'set_selected_date',
    EVT_SET_EVENTS = 'set_booking_events',
    EVT_SET_MAX_MONTH = 'set_max_month',
    EVT_SET_RESERVED_BOOKING_LIMIT = 'set_reserved_booking_limit',
    EVT_SET_TEMP_BOOKING = 'set_temp_bookings',
    EVT_SET_TEMP_BOOKING_IDS_LOCALSTORAGE = 'set_temp_booking_ids_localstorage',
    EVT_UPDATE_TEMP_BOOKING = 'update_temp_bookings',
    EVT_DELETE_TEMP_BOOKING = 'delete_temp_bookings',
    EVT_SET_START_DATE = 'set_start_date',
    EVT_SET_END_DATE = 'set_end_date',
    EVT_SET_SELECTED_TREATMENT = 'set_selected_treatment'
}

export default BOOKING_REDUCER_DISPATCH_EVENTS_ENUM;
