import { useState, useEffect } from 'react';

const Timer = (props: any) => {

    const {
        end,
        isCompleted,
        extra
    } = props;

    const [ timer, setTimer ] = useState('');

    useEffect(() => {
        
        const timeout = setTimeout(() => {
            let endDate: any = new Date(end);
            let startDate: any = new Date();
            let diff = Math.abs(endDate - startDate);

            let ms = diff % 1000;
            diff = (diff - ms) / 1000
            let ss = diff % 60;
            diff = (diff - ss) / 60
            let mm = diff % 60;
            diff = (diff - mm) / 60
            let hh = diff % 24;
            let days = (diff - hh) / 24
        
            if (endDate < startDate || end === 0) {
                clearTimeout(timeout);
                setTimer('');
                isCompleted(extra);
            } else {
                setTimer(preprocessTimerLabel(days, hh, mm, ss));
            }
        }, 1000);

        return () => clearTimeout(timeout);
    });

    const preprocessTimerLabel = (days: number, hh: number, mm: number, ss: number) => {
        return `${days > 0 ? '${days}:' : ''}${hh > 0 ? hh >= 10 ? `${hh}:` : `0${hh}:` : ''}${mm >= 10 ? `${mm}:` : `0${mm}:`}${ss >= 10 ? `${ss}` : `0${ss}`}`;
    }

    return (
        <div>
            {timer}
        </div>
    );
}//end Timer()

export default Timer;