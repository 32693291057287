import './Booking.scss';
import { Calendar, CalendarApi } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import BookingSlot from '../BookingSlot/BookingSlot';
import { useEffect, useReducer, useRef, useState } from 'react';
import { EventImpl } from '@fullcalendar/core/internal';
import { Snackbar } from '@mui/material';
import { KEY_AID, KEY_BOOKING_MAXMONTH, KEY_BOOKING_TEMPIDS, KEY_CUSTOMER_TREATMENTS_BOOKING, KEY_UID } from '../../modules/Storage/Api/ConstantInterface';
import Modal from '../Common/Modal/Modal';
import BookingInterface from '../../modules/Booking/Api/Data/BookingInterface';
import TreatmentSelectionModal from '../TreatmentSelectionModal/TreatmentSelectionModal';
import TreatmentInterface from '../../modules/Treatment/Api/Data/TreatmentInterface';
import ModalInterface from '../Common/Modal/ModalInterface';
import { GetLiveBookingsGql } from '../../modules/GraphQl/Resolvers/Booking/getLiveBookings.gql';
import { useMutation, useQuery } from '@apollo/client';
import { CreateTempBookingGql } from '../../modules/GraphQl/Resolvers/Booking/createTempBooking.gql';
import { DeleteTempBookingGql } from '../../modules/GraphQl/Resolvers/Booking/deleteTempBooking.gql';
import { MD5 } from 'crypto-js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ReactDOMServer from "react-dom/server";
import { DeleteTempBookingsGql } from '../../modules/GraphQl/Resolvers/Booking/deleteTempBookings.gql';
import UserRestrictor from '../Common/Restrictor/UserRestrictor';
import bookingReducer, { bookingReducerInitialState } from './BookingReducer';
import { default as evt } from './BookingReducerDispatchEvents';
import { UpdateTempBookingGql } from '../../modules/GraphQl/Resolvers/Booking/updateTempBooking.gql';
import BookingItemInterface from '../../modules/Booking/Api/Data/BookingInterface';
import ActionButton from '../Common/ActionButton/ActionButton';
import { useUser } from '../Context/UserProvider';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsappButton from '../Common/WhatsappButton/WhatsappButton';
import { useHelper } from '../Context/HelperProvider';
import { GetMaxMonthGql } from '../../modules/GraphQl/Resolvers/Config/getMaxMonth.gql';
import statusMapper from '../../modules/Booking/Model/StatusMapper';

const Booking = (props: any) => {
    const {
        isAdmin,
        isToday,
        selectedTreatment,
        selectedBooking
    } = props;
    
    const BookingPlaceholder = () => {
        return (
            <div className='bookingPlaceholder'>
                <div className='clickableDiv'>
                    {
                        isToday ?
                        <>
                            <TouchAppIcon fontSize='medium'/>
                            <span>Click customer booking(s) for more information.</span>
                        </> :
                        <>
                            <PanToolAltIcon fontSize='medium'/>
                            <span>Tap to book a new slot!</span>
                        </>
                    }
                </div>
                <div></div>
            </div>
        );
    }

    const { cryptor, utility, storage } = useHelper();
    const calendarRef = useRef(null);
    const { gqlHeader, user } = useUser();
    const [ state, dispatch ] = useReducer(bookingReducer, bookingReducerInitialState);
    const [ snackbar, setSnackbar ] = useState({
        content: '',
        duration: 3000,
        isActive: false
    });
    const [ modal, setModal ] = useState<ModalInterface>({
        isModalActive: false
    });
    const { data, loading, error, startPolling, stopPolling } = useQuery(GetLiveBookingsGql, {
        fetchPolicy: 'no-cache',
        context: gqlHeader
    });

    const { data: configData } = useQuery(GetMaxMonthGql, {
        context: gqlHeader
    });

    const [ createTempBooking, 
        { 
            data: addTempBookingData,
        } 
    ] = useMutation(CreateTempBookingGql);

    const [ updateTempBooking, 
        { 
            data: updateTempBookingData,
        } 
    ] = useMutation(UpdateTempBookingGql);

    const [ deleteTempBooking, 
        { 
            data: delTempBookingData,
        } 
    ] = useMutation(DeleteTempBookingGql);    
    const [ deleteTempBookings, 
        { 
            data: delTempBookingsData,
        } 
    ] = useMutation(DeleteTempBookingsGql);

    useEffect(() => {
        startPolling(5000);
        return () => stopPolling()
    }, [startPolling, stopPolling]);

    useEffect(() => {
        if (data && data.getLiveBookings) {
            dispatch({ type: evt.EVT_SET_EVENTS, payload: [...data.getLiveBookings] });
        }
    }, [data]);

    useEffect(() => {
        if (configData && configData.getMaxMonth) {
            dispatch({ type: evt.EVT_SET_MAX_MONTH, payload: configData.getMaxMonth });
        }
    }, [configData]);

    useEffect(() => {
        if (!isToday) {
            document.querySelector('.fc-toolbar-title')?.addEventListener('click', onDateClick);
        }
        document.querySelectorAll('.fc-timegrid-slot-lane')?.forEach((slot: any) => {
            slot.innerHTML = ReactDOMServer.renderToString(<BookingPlaceholder/>)
        });
        document.querySelectorAll('.nonClickableDiv').forEach((div: any) => {
            div.removeEventListener('click', () => {});
        });

        if (storage.hasItem(KEY_BOOKING_MAXMONTH)) {
            let maxMonthFromStorage: number = parseInt(storage.getItem(KEY_BOOKING_MAXMONTH));
            dispatch({ type: evt.EVT_SET_MAX_MONTH, payload: maxMonthFromStorage });
        }

        if (isAdmin) {
            dispatch({ type: evt.EVT_SET_RESERVED_BOOKING_LIMIT, payload: 1 });
        }

        if (isAdmin && isToday) {
            dispatch({ type: evt.EVT_SET_RESERVED_BOOKING_LIMIT, payload: -1 });
        }

        if (storage.hasItem(KEY_BOOKING_TEMPIDS)) {
            deleteAllTemporaryBookings(storage.getItem(KEY_BOOKING_TEMPIDS).split(','));
        }

        calculateNewStartDate();
        setNewSlotMinTime();

        return () => {
            window.removeEventListener("beforeunload", onUnload);
            window.removeEventListener("unload", onUnload);
        }
    }, []);

    const onUnload = (e: any) => {
        if (state.temporaryBookings.length > 0) {
            deleteAllTemporaryBookings();
            e.preventDefault();
            e.returnValue = true;
        }
    }

    useEffect(() => {
        window.onbeforeunload = onUnload;
        return () => {
            window.onbeforeunload = null;
        }
    }); 

    useEffect(() => {
        if (addTempBookingData) {
            if (typeof addTempBookingData.createTempBooking.bid === 'string') {
                dispatch({ type: evt.EVT_SET_TEMP_BOOKING, payload: [...state.temporaryBookings, {...addTempBookingData.createTempBooking}]})
                dispatch({ type: evt.EVT_SET_TEMP_BOOKING_IDS_LOCALSTORAGE, payload: [...state.temporaryBookingIds, addTempBookingData.createTempBooking.bid ]})
            }
        }
    }, [addTempBookingData]);

    useEffect(() => {
        if (updateTempBookingData) {
            if (typeof updateTempBookingData.updateTempBooking.bid === 'string') {
                dispatch({ type: evt.EVT_UPDATE_TEMP_BOOKING, payload: updateTempBookingData.updateTempBooking })
            }
        }
    }, [updateTempBookingData]);

    
    useEffect(() => {
        if (delTempBookingsData) {
            if (delTempBookingsData.deleteTempBookings.status) {
                storage.deleteItem(KEY_BOOKING_TEMPIDS);
            }
        }
    }, [delTempBookingsData]);

    useEffect(() => {
        setNewSlotMinTime();
    }, [state.selectedDate]);

    const deleteAllTemporaryBookings = async (tempBookingIds: any[] = []) => {
        if (tempBookingIds.length === 0) {
            state.temporaryBookings.map((booking: BookingInterface) => {tempBookingIds.push(booking.bid)});
        }
        await deleteTempBookings({
            variables: {
                ids: tempBookingIds
            },
            context: gqlHeader
        })
    }

    /**
     * If today's current time has already passed the last booking slot,
     * make start date and end date one day later
     */
    const calculateNewStartDate = (lastBookingSlot: Date = new Date(new Date().setHours(16, 0, 0, 0))) => {
        if (state.startDate > lastBookingSlot) {
            let newStartDate: Date = new Date(new Date().setDate(state.startDate.getDate() + 1));
            let newEndDate: Date = new Date(new Date().setMonth(newStartDate.getMonth() + state.maxMonth));
            dispatch({ type: evt.EVT_SET_START_DATE, payload: newStartDate });
            dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: newStartDate });
            dispatch({ type: evt.EVT_SET_END_DATE, payload: newEndDate });
        }
    }

    const setNewSlotMinTime = () => {
        let now: Date = state.selectedDate;
        let currentSlotMinTime = parseInt(state.slotMinTime.split(':')[0]);
        let slotMinTimeDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), currentSlotMinTime, 0, 0);
        if (now >= slotMinTimeDate) {
            let nowHours: number = now.getHours() % 2 === 0 ? now.getHours() : now.getHours() + 1;
            if ([10, 12, 14, 16].includes(nowHours)) {
                if (nowHours + 2 === 18) {
                    calculateNewStartDate(new Date(new Date().setHours(15, 0, 0, 0)));
                }
                dispatch({ type: evt.EVT_SET_SLOT_MIN_TIME, payload: `${nowHours+2}:00:00` });
            }
        } else {
            dispatch({ type: evt.EVT_SET_SLOT_MIN_TIME, payload: '10:00:00' });
        }
    }//end setNewSlotMinTime()

    const tempBookingIdGenerator = (uid: string) => {
        return MD5(`${uid}${new Date()}`).toString();
    }

    const disableFriday = (date: any) => {
        return new Date(date).getDay() === 5;
    }

    const onDateClick = (e) => {
        dispatch({ type: evt.EVT_IS_CALENDAR_OPEN, payload: true });
    }

    const onCalendarDateChange = (value: any) => {
        let newDate = new Date(value);
        if (calendarRef.current) {
            /** @ts-ignore */
            let calendar = calendarRef.current.getApi();
            calendar.gotoDate(newDate);
            dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: newDate });
            dispatch({ type: evt.EVT_IS_CALENDAR_OPEN, payload: false });
        }
    }

    const renderEventContent = (eventInfo: any) => {
        return (
            <>
                <BookingSlot
                    isAdmin={isAdmin}
                    id={eventInfo.event.id}
                    bid={eventInfo.event.extendedProps.bid}
                    aid={eventInfo.event.extendedProps.aid}
                    incrementId={eventInfo.event.extendedProps.incrementId}
                    title={eventInfo.event.title} 
                    timeText={eventInfo.timeText} 
                    uid={eventInfo.event.extendedProps.uid}
                    localUid={storage.getItem(KEY_UID)}
                    status={cryptor.decrypt(eventInfo.event.extendedProps.status)}
                />
            </>
        );
    }

    const handleDateSelect = (selectInfo) => {
        let calendarApi: Calendar = selectInfo.view.calendar;
        let events: EventImpl[] = calendarApi.getEvents();
        let endDate: Date = new Date(selectInfo.end);
        let startDate: Date = new Date(selectInfo.start);

        if (isToday) {
            return;
        }

        if (state.reservedBookingCount === state.reservedBookingLimit) {
            setSnackbar({
                ...snackbar, 
                content: isToday ? 'You are not allowed to create any booking.' : !isAdmin ? `You have reached reserved booking threshold.` : `You can only select one slot for reschedule.`,
                isActive: true
            });
        } else {
            if (startDate >= new Date()) {
                let sameTimeEvent: EventImpl[] = events.filter((event: EventImpl) => { return event.startStr === selectInfo.startStr });
    
                if (sameTimeEvent.length < 3) {
                    if (endDate.getHours() - startDate.getHours() > 2) {
                        calendarApi.unselect(); // clear date selection
                        let event: any = {
                            bid: tempBookingIdGenerator(storage.getItem(KEY_UID)),
                            uid: storage.getItem(KEY_UID),
                            username: user.username,
                            phone: user.phone,
                            start: selectInfo.startStr,
                            end: new Date(startDate.setHours(startDate.getHours() + 2)).toISOString(),
                            treatmentId: state.selectedTreatment?.id,
                            treatmentName: state.selectedTreatment?.title,
                            treatmentPrice: state.selectedTreatment?.price,
                            treatmentSrc: state.selectedTreatment?.images[0].src,
                            status: 'new'
                        }
                        if (isAdmin || isToday) {
                            event.aid = storage.getItem(KEY_AID);
                        }
                        calendarApi.addEvent(event);
                        createTemporaryBooking(event);
                    } else {
                        let event: any = {
                            bid: tempBookingIdGenerator(storage.getItem(KEY_UID)),
                            uid: storage.getItem(KEY_UID),
                            username: user.username,
                            phone: user.phone,
                            start: selectInfo.startStr,
                            end: selectInfo.endStr,
                            treatmentId: state.selectedTreatment?.id,
                            treatmentName: state.selectedTreatment?.title,
                            treatmentPrice: state.selectedTreatment?.price,
                            treatmentSrc: state.selectedTreatment?.images[0].src,
                            status: 'new'
                        };

                        if (isAdmin || isToday) {
                            event.aid = storage.getItem(KEY_AID);
                        }                        
                        calendarApi.addEvent(event);
                        createTemporaryBooking(event);
                    }
                    setSnackbar({
                        ...snackbar, 
                        content: `You have booked for ${utility.localeDateFormatter(startDate)} successfully.`,
                        isActive: true
                    });
                } else {
                    setSnackbar({
                        ...snackbar, 
                        content: `${utility.localeDateFormatter(startDate)} has been fully booked.`,
                        isActive: true
                    });
                }
            } else {
                let div: any = document.querySelector('.fc-highlight');
                div.style.cursor = 'not-allowed';
            }
        }
    }

    const createTemporaryBooking = async (event: BookingItemInterface) => {
        dispatch({ type: evt.EVT_ADD_RESERVED_BOOKING_COUNT });
        await createTempBooking({
            variables: {
                aid: event.aid,
                bid: event.bid,
                treatmentId: event.treatmentId,
                treatmentName: event.treatmentName,
                treatmentPrice: event.treatmentPrice,
                treatmentSrc: event.treatmentSrc,
                uid: event.uid,
                username: event.username,
                phone: event.phone,
                start: event.start,
                end: event.end
            },
            context: gqlHeader
        })
    }

    const deleteTemporaryBooking = async (id: string) => {
        await deleteTempBooking({
            variables: {
                id: id
            },
            context: gqlHeader
        })
    }

    const closeSnackbar = () => {
        setSnackbar({...snackbar, isActive: false})
    }

    const onDeletePrompt = (info: any) => {
        setModal({
            ...modal,
            isModalActive: true,
            content: <div>
                <table className='bookingInfoTable'>
                    <tbody>
                        <tr>
                            <td className='bookingInfoHeader'>Start at</td>
                            <td className='bookingInfoValue'>&nbsp;{utility.dateFormatter(info.event.start)}</td>
                        </tr>
                        <tr>
                            <td className='bookingInfoHeader'>End at</td>
                            <td className='bookingInfoValue'>&nbsp;{utility.dateFormatter(info.event.end)}</td>
                        </tr>
                        <tr>
                            <td className='bookingInfoHeader'>Treatment Name</td>
                            <td className='bookingInfoValue'>&nbsp;{info.event.extendedProps.treatmentName}</td>
                        </tr>
                        <tr>
                            <td className='bookingInfoHeader'>Treatment Price</td>
                            <td className='bookingInfoValue'>&nbsp;RM {info.event.extendedProps.treatmentPrice}</td>
                        </tr>
                        {
                            (isToday || isAdmin) &&
                            <>
                                <tr>
                                    <td className='bookingInfoHeader'>Booked By</td>
                                    <td className='bookingInfoValue'>&nbsp;{cryptor.decrypt(info.event.extendedProps.username)}</td>
                                </tr>
                                <tr>
                                    <td className='bookingInfoHeader'>Contact Number</td>
                                    <td className='bookingInfoValue'>&nbsp;{cryptor.decrypt(info.event.extendedProps.phone)}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                {
                    (isToday || isAdmin) &&
                    <div className='bookingInfoContactCustomerDiv'>
                        <ActionButton
                            label={'Call Customer'} 
                            title={'Click to call customer'} 
                            icon={<LocalPhoneIcon/>}
                            isDisable={false}
                            onClick={() => {
                                window.open(`tel:${cryptor.decrypt(info.event.extendedProps.phone)}`, '_self');
                            }}                           
                            canRateLimit={true}
                            rateLimitCount={10}
                            rateLimitFor={3} 
                            styling={'icon-transparent'}
                        />
                        <WhatsappButton
                            label={'Message Customer'}
                            title={'Click to message customer via WhatsApp.'}
                            contact={cryptor.decrypt(info.event.extendedProps.phone)}
                        />
                    </div>
                }
                {
                    !isAdmin && cryptor.decrypt(info.event.extendedProps.status) === 'unpaid' &&
                    <ActionButton
                        label={'Pay Now'} 
                        title={'Click to pay now.'} 
                        isDisable={false}
                        onClick={() => {
                            window.location.href = `/user/${user.username}/booking/unpaid`
                        }}                           
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3} 
                        styling={'basic-brown'}
                    />
                }
            </div>,
            action: !isAdmin && cryptor.decrypt(info.event.extendedProps.status) === 'new' &&
            [
                {
                    label: 'Remove booking?',
                    title: 'Click to remove your booking',
                    onClick: onDeleteBooking,
                    extra: info
                }
            ]
        });
    }

    const onDeleteBooking = (info: any) => {
        deleteTemporaryBooking(info.event.extendedProps.bid);
        dispatch({ type: evt.EVT_MINUS_RESERVED_BOOKING_COUNT });
        dispatch({ type: evt.EVT_SET_TEMP_BOOKING_IDS_LOCALSTORAGE, payload: state.temporaryBookingIds.filter((id: string) => { return id !== info.event.extendedProps.bid })});
        dispatch({ type: evt.EVT_SET_TEMP_BOOKING, payload: state.temporaryBookings.filter((booking: BookingInterface) => { return booking.bid !== info.event.extendedProps.bid })});
        let date: string = utility.localeDateFormatter(new Date(info.event.start));
        info.event.remove();
        setSnackbar({
            ...snackbar,
            isActive: true,
            content: `Your booking on ${date} has been removed successfully.`
        });
    }

    const onUpdateTempBooking = (id: any) => {
        deleteTemporaryBooking(id);
        dispatch({ type: evt.EVT_MINUS_RESERVED_BOOKING_COUNT });
        dispatch({ type: evt.EVT_SET_TEMP_BOOKING_IDS_LOCALSTORAGE, payload: state.temporaryBookingIds.filter((tid: string) => { return tid !== id })});
        dispatch({ type: evt.EVT_SET_TEMP_BOOKING, payload: state.temporaryBookings.filter((booking: BookingInterface) => { return booking.bid !== id })});
    }

    const onTreatmentSelected = (treatment: TreatmentInterface) => {
        dispatch({ type: evt.EVT_SET_SELECTED_TREATMENT, payload: treatment });
    }

    return (
        <div className='bookingDiv'>
            { !isAdmin && <UserRestrictor/> }
            <Snackbar 
                message={snackbar.content}
                open={snackbar.isActive}
                autoHideDuration={snackbar.duration}
                onClose={closeSnackbar}
                className='bookingSnackbar'
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    open={state.isCalendarOpen}
                    minDate={state.startDate}
                    maxDate={state.endDate}
                    value={state.selectedDate}
                    onChange={(newDate: any) => dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: new Date(newDate) })}
                    onAccept={(e) => onCalendarDateChange(e)}
                    onClose={() => dispatch({ type: evt.EVT_IS_CALENDAR_OPEN, payload: false })}
                    shouldDisableDate={disableFriday}
                    renderInput={(params) => <></>}
                    componentsProps={{
                        actionBar: {
                            actions: ['accept', 'cancel'],
                        },
                    }}
                />
            </LocalizationProvider>
            <FullCalendar
                ref={calendarRef}
                viewClassNames={''}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                initialView='timeGridHours'
                views={{
                    timeGridHours: {
                        type: 'timeGridDay',
                        slotMinTime: state.slotMinTime,
                        slotMaxTime: state.slotMaxTime,
                        validRange: {
                            start: state.startDate,
                            end: state.endDate
                        }
                    }
                }}
                customButtons={{
                    prev: {
                        text: 'Prev',
                        click: function() {
                            if (calendarRef.current) {
                                /** @ts-ignore */
                                let calendar: CalendarApi = calendarRef.current.getApi();
                                calendar.prev();
                                if (calendar.getDate() < new Date()) {
                                    dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: state.startDate });
                                } else {
                                    dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: calendar.getDate() });
                                }
                            }
                        }
                    },
                    next: {
                        text: 'Next',
                        click: function() {
                            if (calendarRef.current) {
                                /** @ts-ignore */
                                let calendar: CalendarApi = calendarRef.current.getApi();
                                calendar.next();
                                dispatch({ type: evt.EVT_SET_SELECTED_DATE, payload: calendar.getDate() });
                            }
                        }
                    }
                }}
                slotLabelFormat={{
                    hour: 'numeric'
                }}
                allDaySlot={false}
                headerToolbar={{
                    start: isToday ? '' : "prev",
                    center: "title",
                    end: isToday ? '' : "next"
                }}
                select={handleDateSelect}
                displayEventTime
                events={state.events}
                hiddenDays={[5]}
                eventContent={renderEventContent}
                height={'auto'}
                editable={true}
                forceEventDuration={true}
                eventDurationEditable={false}
                eventResizableFromStart={false}
                eventStartEditable={false}
                slotDuration={'02:00:00'}
                selectable={true}
                selectMirror={false} 
                longPressDelay={100}
                eventLongPressDelay={500}
                selectLongPressDelay={100}
                initialDate={state.startDate}
                validRange={{
                    start: state.startDate,
                    end: state.endDate
                }}
                eventClick={(info: any) => {
                    if (info.event.extendedProps.uid === storage.getItem(KEY_UID) || isAdmin) {
                        onDeletePrompt(info);
                    } else {
                        return;
                    }
                }}
                eventDrop={async (info: any) => {
                    if (isAdmin) {
                        if (info.event.extendedProps.aid === storage.getItem(KEY_AID)) {
                            await updateTempBooking({
                                variables: {
                                    bid: info.oldEvent.id,
                                    start: info.event.start,
                                    end: info.event.end
                                },
                                context: gqlHeader
                            });                        
                        } else {
                            return;
                        }
                    } else {
                        if (info.event.extendedProps.uid === storage.getItem(KEY_UID)) {
                            await updateTempBooking({
                                variables: {
                                    bid: info.oldEvent.id,
                                    start: info.event.start,
                                    end: info.event.end
                                },
                                context: gqlHeader
                            });                        
                        } else {
                            return;
                        }
                    }
                }}
            />
            {
                !isToday &&
                <TreatmentSelectionModal 
                    isAdmin={isAdmin} 
                    preSelectedTreatment={
                        selectedTreatment ? selectedTreatment : 
                        storage.hasItem(KEY_CUSTOMER_TREATMENTS_BOOKING) ?
                            JSON.parse(storage.getItem(KEY_CUSTOMER_TREATMENTS_BOOKING)) 
                            : null
                    } 
                    preSelectedBooking={selectedBooking}
                    onSelected={onTreatmentSelected} 
                    booking={state.temporaryBookings}
                    onUpdateBooking={onUpdateTempBooking}
                />
            }
            <Modal 
                isModalActive={modal.isModalActive}
                onModalClose={() => {setModal({...modal, isModalActive: false})}}
                title={modal.title}
                content={modal.content}
                action={modal.action}
                showCancelButton={false}
                showCancelIcon={true}
            />
        </div>
    );
}//end Booking()

export default Booking;