import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserProvider";

const UserRestrictor = () => {
    const { isUserLoggedIn, isVerified } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isVerified) {
            if (location.pathname !== '/') {
                navigate(`/verify?redirect=${location.pathname.charAt(0) === '/' ? location.pathname.substring(1) : location.pathname}`)
            } else {
                navigate('/verify');
            }
        }
        if (!isUserLoggedIn()) {
            if (location.pathname !== '/') {
                navigate(`/login?redirect=${location.pathname.charAt(0) === '/' ? location.pathname.substring(1) : location.pathname}`)
            } else {
                navigate('/login');
            }
        }
    }, []);

    return <></>
}//end UserRestrictor()

export default UserRestrictor;
