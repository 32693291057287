/* eslint-disable react-hooks/exhaustive-deps */
import "../../ActionAdmin.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useAdmin } from "../../../../Context/AdminProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { UpdateFaqById } from "../../../../../modules/GraphQl/Resolvers/Faq/updateFaqById.gql";
import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import TextArea from "../../../../Common/TextArea/TextArea";
import FaqInterface from "../../../../../modules/FAQ/Api/Data/FaqInterface";
import { GetFaqById } from "../../../../../modules/GraphQl/Resolvers/Faq/getFaqById.gql";

const AdminEditFaq = () => {

	const navigate = useNavigate();
    const location = useLocation();
	const { gqlHeader } = useAdmin();

    const [ formData, setFormData ] = useState<FaqInterface>({
        title: "",
        description: "",
        class: "",
    });

    const { data: initData } = useQuery(GetFaqById, {
        variables: {
            id: location.pathname.split("/").pop(),
        },
		context: gqlHeader
    })

    const [ updateFaq, { data: updateData } ] = useMutation(UpdateFaqById)
    const [ isDisable, setIsDisable ] = useState(true);

    const handleChangeSelect = (e) => {
        formData["class"] = e.target.value
        setFormData({ ...formData });
        setIsDisable(validateFormData());
    };

    const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		setIsDisable(validateFormData());
	};

    const validateFormData = () => {
		if (formData.title.length <= 0) {
			return true;
		}

		if (formData.description.length <= 0) {
			return true;
		}

        if (formData.class.length <= 0) {
            return true;
        }
		return false;
	};

	const onSubmit = async () => {
        await updateFaq({
			variables: {
				id: location.pathname.split("/").pop(),
				data: formData,
			},
			context: gqlHeader
		})
	};

    useEffect(() => {
        if (initData) {
            if (typeof initData.getFaqById.id !== "string") {
                navigate(`/${BACKOFFICE_URL}/content/faq}`);
            } else {
                setFormData({
                    title: initData.getFaqById.title, 
                    description: initData.getFaqById.description, 
                    class: initData.getFaqById.class 
                });
            }
        }
    }, [initData])

    useEffect(() => {
		if (updateData) {
			if (updateData.updateFaqById.status) {
				const navigateTimer = setTimeout(() => {
					navigate(`/${BACKOFFICE_URL}/content/faq`);
					return () => clearTimeout(navigateTimer);
				}, 1000);
			}
		}
	}, [updateData]);

    return initData && initData.getFaqById ? (
        <div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
                <span>Edit FAQ</span>
                <span></span>
                <ActionButton 
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								tabIndex={0}
                                value={initData.getFaqById.title}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea identifier="description" onChange={onChange} isRequired="true" errorText={"Description is required."} value={initData.getFaqById.description} />
						</td>
					</tr>
                    <tr>
                        <td className="adminActionHeader">Class</td>
                        <td>
                            <FormControl required sx={{ width: 300, mb: 2 }}>
                                <Select id="select-required"
                                    value={formData.class}
                                    onChange={(e) => handleChangeSelect(e)}
                                >
                                    <MenuItem value="Product">Product</MenuItem>
                                    <MenuItem value="Booking">Booking</MenuItem>
                                </Select>
                                {formData.class.length <= 0 ? <FormHelperText sx={{ ml:0, mr: 0 }}>Required</FormHelperText> : "" }
                            </FormControl> 
                        </td>
                    </tr>
				</tbody>
			</table>
		</div>
    ) : null;
};

export default AdminEditFaq;