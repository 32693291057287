import { gql } from "@apollo/client";

const GET_BANNER_FOR_CUSTOMER = gql`
	query getBannerForCustomer {
		getBannerForCustomer {
			id
			isEnabled
			title
			description
			images {
				fileName
				src
				position
			}
		}
	}
`;

export const GetBannerForCustomer = GET_BANNER_FOR_CUSTOMER;
