import { gql } from "@apollo/client";

const DELETE_FAQ_CLASS_BY_ID = gql`
	mutation deleteFaqClassById($id: String!) {
		deleteFaqClassById(id: $id) {
			status
			message
		}
	}
`;

export const DeleteFaqClassById = DELETE_FAQ_CLASS_BY_ID;