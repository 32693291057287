import { gql } from "@apollo/client";

const GET_ALL_ADMINS = gql`
  query getAllAdmins {
    getAllAdmins {
      id
      username
      createdAt
    }
  }
`;

export const GetAllAdminsGql = GET_ALL_ADMINS;
