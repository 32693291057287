import './MyAccountTitle.scss';

const MyAccountTitle = (props: any) => {
    const { title } = props;
    return (
        <span className="myAccountTitle">{title}</span>
    );
}//end MyAccountTitle()

export default MyAccountTitle;
