import { gql } from "@apollo/client";

const GET_ALL_FAQ_CLASS = gql`
	query getAllFaqClass {
		getAllFaqClass {
            id
			title
		}
	}
`;

export const GetAllFaqClass = GET_ALL_FAQ_CLASS;