import { useState, useEffect } from 'react';
import ActionButton from '../Common/ActionButton/ActionButton';
import { useMutation } from "@apollo/client";
import './UserForgotPassword.scss';
import { useNavigate } from 'react-router-dom';
import { GetUserByFieldGql } from '../../modules/GraphQl/Resolvers/User/getUserByField.gql';
import { UpdateUserByIdGql } from '../../modules/GraphQl/Resolvers/User/updateUserById.gql';
import { UserInterface } from '../../modules/Api/Data/DocumentInterface';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import BasicTextInput from '../Common/BasicTextInput/BasicTextInput';
import { KEY_RESET_PASSWORD_DONE_OTP, KEY_RESET_PASSWORD_FOR_UID } from '../../modules/Storage/Api/ConstantInterface';
import { useHelper } from '../Context/HelperProvider';

interface ResetPwData {
    username: string,
    phone: string,
    password: string,
    repassword: string
}

const UserForgotPassword = () => {
    const { cryptor, storage } = useHelper();

    const navigate = useNavigate();
    const [ resetPwData, setResetPwData ] = useState<ResetPwData>({
        username: '',
        phone: '',
        password: '',
        repassword: ''
    });
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isDisable, setIsDisable ] = useState({
        submitSearchForUserButton: true,
        submitResetPasswordButton: true
    });
    const [ isPasswordResetted, setIsPasswordResetted ] = useState(false);
    const [ isChecked, setIsChecked ] = useState({
        rmbUsername: false,
        rmbPhone: false
    })
    const [ SearchForUser, { data: searchForUserData }] = useMutation(GetUserByFieldGql);
    const [ UpdateUser, { data: updateUserData, }] = useMutation(UpdateUserByIdGql);

    useEffect(() => {
        if (searchForUserData) {
            if (typeof searchForUserData.getUserByField.id !== 'string') {
                setErrorMsg('Username or phone number does not exist! Please try again!');
            } else {
                setErrorMsg('');
                storage.setItem(KEY_RESET_PASSWORD_FOR_UID, JSON.stringify({...searchForUserData.getUserByField}));
                navigate('/verify');
            }
        }
    }, [searchForUserData]);

    useEffect(() => {
        if (updateUserData) {
            if (updateUserData.updateUserById) {
                storage.deleteItem(KEY_RESET_PASSWORD_FOR_UID);
                storage.deleteItem(KEY_RESET_PASSWORD_DONE_OTP);
                setIsPasswordResetted(true);
            }
        }
    }, [updateUserData]);

    useEffect(() => {
        validateResetPwData();
    }, [resetPwData]);

    const onChange = (val: string, interfaceData: string) => {
        switch (interfaceData) {
            case 'username':
                resetPwData.username = val;
                break;
            case 'phone':
                resetPwData.phone = val;
                break;
            case 'password':
                resetPwData.password = val;
                break;    
            case 'repassword':
                resetPwData.repassword = val;
                break;  
        }
        setResetPwData({...resetPwData});
    }

    const validateResetPwData = () => {
        isDisable.submitSearchForUserButton = 
            (isChecked.rmbUsername && resetPwData.username.length <= 0 ? true : false) ? true : false ||
            (isChecked.rmbPhone && resetPwData.phone.length <= 0 ? true : false) ? true : false || 
            (!isChecked.rmbUsername && !isChecked.rmbPhone) ? true : false;

        isDisable.submitResetPasswordButton = 
            resetPwData.password.length <= 0 ? true : false ||
            resetPwData.repassword.length <= 0 ? true : false || 
            resetPwData.password !== resetPwData.repassword ? true : false;
            
        setIsDisable({...isDisable});
    }

    const onUpdateUserPassword = async () => {
        let userData: UserInterface = {
            password: resetPwData.password
        }
        await UpdateUser({
            variables: {
                id: JSON.parse(storage.getItem(KEY_RESET_PASSWORD_FOR_UID)).id,
                data: cryptor.encrypt(JSON.stringify(userData))
            }
        });
    }

    const onCheckboxChange = (e: any, identifier: string) => {
        isChecked[identifier] = e.target.checked;
        setIsChecked({...isChecked});

        if (e.target.checked === false) {
            resetPwData[identifier] = '';
            setResetPwData({...resetPwData});
        }
    }

    const onSearchForUser = async () => {
        if (resetPwData.username.length !== 0 && resetPwData.phone.length !== 0) {
            await SearchForUser({
                variables: {
                    username: resetPwData.username,
                    phone: `60${resetPwData.phone}`,
                }
            })
        } else if (resetPwData.username.length !== 0 && resetPwData.phone.length === 0) {
            await SearchForUser({
                variables: {
                    username: resetPwData.username
                }
            })
        } else if (resetPwData.username.length === 0 && resetPwData.phone.length !== 0) {
            await SearchForUser({
                variables: {
                    phone: `60${resetPwData.phone}`
                }
            })
        } 
    }

    return (
        <div className="userForgotPasswordDiv">
            {
                isPasswordResetted ? 
                <div className='userForgotPasswordContDiv'>
                    <div className='userForgotPasswordForm'>
                        <span className='userForgotPasswordTitle'>
                            Congratulations!
                            <span className='userForgotPasswordSubTitle'>Your password has been resetted successfully!</span>
                        </span>
                        <ActionButton 
                            label={'Login Now'} 
                            isDisable={false}
                            title={'Click to login.'} 
                            onClick={() => {navigate('/login')}}
                            styling={'basic'}
                        />
                    </div>
                </div> : storage.hasItem(KEY_RESET_PASSWORD_DONE_OTP) && storage.getItem(KEY_RESET_PASSWORD_DONE_OTP) === 'true' ?
                <div className='userForgotPasswordContDiv'>
                    <form className='userForgotPasswordForm'>
                        <span className='userForgotPasswordTitle'>
                            Reset Password
                            <span className='userForgotPasswordSubTitle'>
                                Kindly update your password
                            </span>
                        </span>
                        <div>
                            <BasicTextInput 
                                identifier='password'
                                label="Password" 
                                isRequired={true}
                                canShowError={resetPwData.password.length <= 0 ? true : false}
                                errorText="Password is required."
                                hasPassword={true}
                                maxLength={15}
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={1}
                            />
                            <BasicTextInput
                                identifier='repassword'
                                label="Retype password" 
                                isRequired={true}
                                canShowError={resetPwData.repassword.length <= 0 || resetPwData.password !== resetPwData.repassword ? true : false}
                                errorText={resetPwData.repassword.length <= 0 ? "Retype password is required." : "Passwords do not match."}
                                hasPassword={true}
                                maxLength={15}
                                isAutocomplete={false}
                                onChange={onChange}
                                tabIndex={2}
                                onEnter={onUpdateUserPassword}
                            />        
                        </div>
                        <span className='errorMsg'>{errorMsg}</span>
                    </form>
                    <ActionButton 
                        label={'Submit'} 
                        isDisable={isDisable.submitResetPasswordButton}
                        title={'Click to submit.'} 
                        onClick={onUpdateUserPassword}
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3}
                        styling={'form-bottom'}
                    />
                </div> :
                <div className='userForgotPasswordContDiv'>
                    <form className='userForgotPasswordForm'>
                        <span className='userForgotPasswordTitle'>
                            Reset Password
                            <span className='userForgotPasswordSubTitle'>
                                Please select at least one option
                            </span>
                        </span>
                        <div>
                            <FormGroup>
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            value={isChecked.rmbUsername}
                                            onChange={(e: any) => onCheckboxChange(e, 'rmbUsername')} 
                                        />
                                    } 
                                    label="I remember my username." 
                                />
                                {
                                    isChecked.rmbUsername ? 
                                    <BasicTextInput 
                                        identifier='username'
                                        label="Username" 
                                        isRequired={true}
                                        canShowError={resetPwData.username.length <= 0 ? true : false}
                                        errorText="Username is required."
                                        minLength={15}
                                        maxLength={15}
                                        isAutocomplete={true}
                                        onChange={onChange}
                                        tabIndex={1}
                                    /> : null        
                                }                                     
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            value={isChecked.rmbPhone}
                                            onChange={(e: any) => onCheckboxChange(e, 'rmbPhone')} 
                                        />
                                    } 
                                    label="I remember my phone number." 
                                />
                                {
                                    isChecked.rmbPhone ? 
                                    <BasicTextInput 
                                        identifier='phone'
                                        label="Phone number" 
                                        isRequired={true}
                                        canShowError={resetPwData.phone.length <= 0 ? true : false}
                                        errorText="Phone number is required."
                                        isNumber={true}
                                        inputType={"phone"}
                                        prefix={"+60"}
                                        minLength={9}
                                        maxLength={10}
                                        isAutocomplete={true}
                                        onChange={onChange}
                                        tabIndex={2}
                                    /> : null        
                                } 
                            </FormGroup>           
                        </div>
                        <span id="recaptcha-reset"></span>
                        <span className='errorMsg'>{errorMsg}</span>
                    </form>
                    <ActionButton 
                        label={'Continue'} 
                        isDisable={isDisable.submitSearchForUserButton}
                        title={'Click to continue.'} 
                        onClick={onSearchForUser}
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3}
                        styling={'form-bottom'}
                    />
                </div>
            }
        </div>
    );
}

export default UserForgotPassword;
