export default interface PaymentInterface {
    id?: string,
    stripeId?: string,
    amount?: string,
    status?: string,
    type?: string,
    incrementId?: string,
    currency?: string,
    paymentCreatedAt?: string,
    customer?: string,
    env?: string,
    paymentId?: string,
    chargeId?: string,
    chargeCreatedAt?: string,
    paymentMethodId?: string,
    paymentMethod?: string,
    balanceTxnId?: string,
    balanceTxnCreatedAt?: string,
    processingFee?: string,
    netAmount?: string,
    isRefunded?: boolean,
    isDisputed?: boolean,
    createdAt?: string,
    bookingId?: string
}

export interface PaymentRefundRequestInterface {
    paymentId: string,
    bookingId: string,
    bookingItemId: string,
    amount: string
}

/**
 * Read more on https://stripe.com/docs/payments/payment-intents/verifying-status#handling-webhook-events
 */
export enum PAYMENT_STATUS {
    'processing'                = 'Processing',                //The customer’s payment was submitted to Stripe successfully. Only applicable to payment methods with delayed success confirmation.
    'succeeded'                 = 'Succeeded',                 //Customer’s payment succeeded
    'amount_capturable_updated' = 'Amount Capturable Updated', //Customer’s payment is authorized and ready for capture
    'payment_failed'            = 'Payment Failed',            //Customer’s payment was declined by card network or otherwise expired
}

export enum PAYMENT_METHOD {
    'fpx' = 'FPX',               //Fpx
    'card' = "Credit/Debit Card" //Credit/Debit Card
}