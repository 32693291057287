import React, { useContext, useEffect, useState } from "react";
import Cryptor from "../../modules/Cryptor/Core/Cryptor";
import Session from "../../modules/Session/Core/Session";
import Storage from "../../modules/Storage/Core/Storage";
import Utility from "../../modules/Utility/Utility";

const HelperContext = React.createContext<any>(null);

export const useHelper = () => {
    return useContext(HelperContext);
}

const HelperProvider = ({ children }) => {
    const [ cryptor, setCryptor ] = useState<Cryptor>(new Cryptor());
    const [ session, setSession ] = useState<Session>(new Session());
    const [ storage, setStorage ] = useState<Storage>(new Storage());
    const [ utility, setUtility ] = useState<Utility>(new Utility());
    const [ headerHeight, setHeaderHeight ] = useState(0);
    const [ sideMenuHeight, setSideMenuHeight ] = useState(0);
    const [ adminPanelHeaderHeight, setAdminPanelHeaderHeight ] = useState(0);

    useEffect(() => {
        document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    }, [headerHeight]);

    useEffect(() => {
        document.documentElement.style.setProperty("--side-menu-height", `${sideMenuHeight}px`);
    }, [sideMenuHeight]);

    useEffect(() => {
        document.documentElement.style.setProperty("--admin-panel-header-height", `${adminPanelHeaderHeight}px`);
    }, [adminPanelHeaderHeight]);

    const updateHeaderHeight = (headerHeight: any) => {
        setHeaderHeight(headerHeight);
    }//end updateHeaderHeight()

    const updateSideMenuHeight = (sideMenuHeight: any) => {
        setSideMenuHeight(sideMenuHeight);
    }//end updateSideMenuHeight()

    const updateAdminPanelHeaderHeight = (adminPanelHeaderHeight: any) => {
        setAdminPanelHeaderHeight(adminPanelHeaderHeight);
    }//end updateAdminPanelHeaderHeight()

    return (
        <HelperContext.Provider value={{
            cryptor: cryptor,
            session: session,
            storage: storage,
            utility: utility,
            headerHeight: headerHeight,
            setHeaderHeight: updateHeaderHeight,
            setSideMenuHeight: updateSideMenuHeight,
            setAdminPanelHeaderHeight: updateAdminPanelHeaderHeight
        }}>
            {children}
        </HelperContext.Provider>
    );
}//end HelperProvider()

export default HelperProvider;
