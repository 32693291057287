import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DeleteTempBookingsGql } from "../../modules/GraphQl/Resolvers/Booking/deleteTempBookings.gql";
import { KEY_BOOKING_TEMPIDS } from "../../modules/Storage/Api/ConstantInterface";
import { useHelper } from "../Context/HelperProvider";
import { useUser } from "../Context/UserProvider";

/**
 * Cleanup temporary booking whenever user leaves the booking page
 * @returns 
 */
const BookingCleanup = () => {
    const location = useLocation();
    const { storage } = useHelper();
    const { gqlHeader } = useUser();
    const [ DeleteTempBookings, { data } ] = useMutation(DeleteTempBookingsGql);

    useEffect(() => {
        if (location.pathname !== '/booking') {
            if (storage.hasItem(KEY_BOOKING_TEMPIDS)) {
                deleteTempBookings(storage.getItem(KEY_BOOKING_TEMPIDS));
            }
        }
    });

    useEffect(() => {
        if (data) {
            if (data.deleteTempBookings.status) {
                storage.deleteItem(KEY_BOOKING_TEMPIDS);
            }
        }
    }, [data])

    const deleteTempBookings = async (tempBookingIds: string) => {
        await DeleteTempBookings({
            variables: {
                ids: tempBookingIds.split(',')
            },
            context: gqlHeader
        });
    }

    return (
        <></>
    );
}//end BookingCleanup()

export default BookingCleanup;
