import { useState, useEffect } from 'react';
import ActionButton from '../Common/ActionButton/ActionButton';
import TextInput from '../Common/TextInput/TextInput';
import { useMutation } from "@apollo/client";
import './AdminLogin.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BACKOFFICE_URL } from '../../modules/Api/Data/ConstantInterface';
import { AdminLoginGql } from '../../modules/GraphQl/Resolvers/Admin/adminLogin.gql';
import { useAdmin } from '../Context/AdminProvider';

interface LoginData {
    username: string,
    password: string,
}

const AdminLogin = () => {

    const navigate = useNavigate();
    const [ loginData, setLoginData ] = useState<LoginData>(
        {
            username: '',
            password: ''
        }
    );

    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isDisable, setIsDisable ] = useState(true);
    const [ submitBtnLabel, setSubmitBtnLabel ] = useState('login');
    const [ LoginAdmin, { data, loading, error }] = useMutation(AdminLoginGql);
    const { login } = useAdmin();
    const [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {
        if (data) {
            if (typeof data.adminLogin.id !== 'string') {
                setErrorMsg('Account not found. Please contact your admin for further assistance.');
                setIsDisable(false);
            } else {
                login(data.adminLogin);
                setErrorMsg('');
                setSubmitBtnLabel('Logging in...');
                setIsDisable(true);
                const timer = setTimeout(() => {
                    if (searchParams.has('redirect')) {
                        navigate(`/${BACKOFFICE_URL}/${searchParams.get('redirect')}`);
                    } else {
                        navigate(`/${BACKOFFICE_URL}/dashboard`);
                    }
                    clearTimeout(timer);
                }, 2000);
            }
        }
    }, [data]);

    const onChange = (val: string, interfaceData: string) => {
        switch (interfaceData) {
            case 'username':
                loginData.username = val;
                break;
            case 'password':
                loginData.password = val;
                break;
        }
        setLoginData({...loginData});
        setIsDisable(validateLoginData());
    }

    const validateLoginData = () => {
        if (loginData.username.length <= 0) {
            return true;
        }

        if (loginData.password.length <= 0) {
            return true;
        }

        return false;
    }

    const onSubmit = async () => {
        if (!validateLoginData()) {
            await LoginAdmin({
                variables: {
                    username: loginData.username,
                    password: loginData.password
                },
            });
        }
    }

    if (error) {
        console.error(error)
    }

    return (
        <div className="adminLoginDiv">
            <div className='adminLoginContDiv'>
                <span className='adminLoginTitle'>Beauvie Hair Care</span>
                <form className='adminLoginForm'>
                    <span className='adminLoginSubTitle'>Admin Login</span>
                    <TextInput 
                        identifier='username'
                        label="Username" 
                        isRequired={true}
                        canShowError={loginData.username.length <= 0 ? true : false}
                        errorText="Username is required."
                        maxLength={15}
                        inputType={"username"}
                        isAutocomplete={true}
                        onChange={onChange}
                        tabIndex={1}
                    />
                    <TextInput 
                        identifier='password'
                        label="Password" 
                        isRequired={true}
                        canShowError={loginData.password.length <= 0 ? true : false}
                        errorText="Password is required."
                        hasPassword={true}
                        maxLength={15}
                        isAutocomplete={true}
                        onChange={onChange}
                        tabIndex={2}
                        onEnter={onSubmit}
                    />
                    <span className='errorMsg'>{errorMsg}</span>
                    <ActionButton 
                        label={submitBtnLabel} 
                        title={'Click to login.'} 
                        isDisable={isDisable}
                        onClick={onSubmit}
                        canRateLimit={true}
                        rateLimitCount={10}
                        rateLimitFor={3}
                    />
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;
