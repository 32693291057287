import { default as evt } from "./EditAdminBookingReducerDispatchEvents";

interface EditAdminBookingReducerStatusInterface {
    id: string,
    status: string
}

interface EditAdminBookingReducerStateInterface {
    status: EditAdminBookingReducerStatusInterface[],
    phone: string,
    bookingId: string,
    paymentId: string,
    canBulkRefund: boolean,
    bulkRefund: []
}

interface EditAdminBookingReducerActionInterface {
    type: evt,
    payload?: any
}

export const editAdminBookingReducerInitialState: EditAdminBookingReducerStateInterface = {
    status: [],
    phone: '',
    bookingId: '',
    paymentId: '',
    canBulkRefund: true,
    bulkRefund: []
}

const editAdminBookingReducer = (state: any | EditAdminBookingReducerStateInterface, action: EditAdminBookingReducerActionInterface) => {
    switch (action.type) {
        case evt.EVT_UPDATE_STATUS:
            let prevStatus = state.status.filter((status: any) => { return status.id !== action.payload.id });
            return {
                ...state,
                status: [action.payload, ...prevStatus]
            };
        case evt.EVT_SET_PHONE:
            return {
                ...state,
                phone: action.payload
            }
        case evt.EVT_SET_BOOKING_ID:
            return {
                ...state,
                bookingId: action.payload
            }
        case evt.EVT_SET_PAYMENT_ID:
            return {
                ...state,
                paymentId: action.payload
            }
        case evt.EVT_SET_CAN_BULK_REFUND:
            return {
                ...state,
                canBulkRefund: action.payload
            }
        case evt.EVT_SET_BULK_REFUND:
            return {
                ...state,
                bulkRefund: action.payload
            }
        default: 
            return {
                ...state
            }
    }
}//end editAdminBookingReducer()

export default editAdminBookingReducer;
