import ActionButton from "../../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../../Common/BasicTextInput/BasicTextInput";
import ImageUpload from "../../../../Common/ImageUpload/ImageUpload";
import TextArea from "../../../../Common/TextArea/TextArea";
import { Switch } from "@mui/material";
import { useState, useEffect } from "react";
import TreatmentInterface, {
	TreatmentImageUploadInterface,
} from "../../../../../modules/Treatment/Api/Data/TreatmentInterface";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKOFFICE_URL } from "../../../../../modules/Api/Data/ConstantInterface";
import { GetTreatmentByIdGql } from "../../../../../modules/GraphQl/Resolvers/Treatment/getTreatmentById.gql";
import { UpdateTreatmentByIdGql } from "../../../../../modules/GraphQl/Resolvers/Treatment/updateTreatmentById.gql";
import { useAdmin } from "../../../../Context/AdminProvider";
import { useHelper } from "../../../../Context/HelperProvider";

const EditAdminTreatment = () => {
	const { cryptor, utility } = useHelper();
	const { gqlHeader } = useAdmin();
	const navigate = useNavigate();
	const location = useLocation();
	const [formData, setFormData] = useState<TreatmentInterface>({
		title: "",
		isEnable: false,
		price: "",
		description: "",
		images: [],
	});
	const [oldData, setOldData] = useState<TreatmentInterface>({
		title: "",
		isEnable: false,
		price: "",
		description: "",
		images: [],
	});
	const [fieldsToBeUpdated, setFieldsToBeUpdated] = useState<string[]>([]);

	const {
		data: initData,
		loading: initLoading,
		error: initError,
	} = useQuery(GetTreatmentByIdGql, {
		variables: {
			id: location.pathname.split("/").pop(),
		},
		fetchPolicy: "cache-and-network",
		context: gqlHeader
	});
	const [updateTreatment, { data, loading, error }] = useMutation(
		UpdateTreatmentByIdGql
	);
	const [isDisable, setIsDisable] = useState(true);

	useEffect(() => {
		if (data) {
			if (data.updateTreatmentById.status) {
				navigate(`/${BACKOFFICE_URL}/core/treatment`);
			}
		}
	}, [data]);

	useEffect(() => {
		if (initData) {
			if (typeof initData.getTreatmentById.id !== "string") {
				navigate(`/${BACKOFFICE_URL}/core/treatment`);
			} else {
				setFormData({
					...formData,
					isEnable: initData.getTreatmentById.isEnable,
				});
				setOldData({ ...initData.getTreatmentById });
			}
		}
	}, [initData]);

	useEffect(() => {
		if (fieldsToBeUpdated.length > 0) {
			setIsDisable(validateFormData());
		}
	}, [fieldsToBeUpdated]);

	const updateFieldsToBeUpdated = (field: string) => {
		if (!fieldsToBeUpdated.includes(field)) {
			fieldsToBeUpdated.push(field);
		}
		setFieldsToBeUpdated([...fieldsToBeUpdated]);
	};

	const onSwitchChange = (e: any) => {
		setFormData({
			...formData,
			isEnable: e.target.checked,
		});
		updateFieldsToBeUpdated("isEnable");
	};

	const onChange = (value: string, identifier: string) => {
		formData[identifier] = value;
		setFormData({ ...formData });
		updateFieldsToBeUpdated(identifier);
	};

	const onSubmit = async () => {
		let data: any = {
			oldImages: [],
			images: oldData.images.length > 0 ? [...oldData.images] : [],
		};

		fieldsToBeUpdated.forEach((field: string) => {
			if (field === "price") {
				data[field] = utility.preprocessPrice(formData[field]);
			} else if (field === "images[0]") {
				if (oldData.images[0] && oldData.images[0].hasOwnProperty('src')) {
					data["oldImages"].push(oldData.images[0].src);
				}
				data.images[0] = formData.images[0];
			} else if (field === "images[0]_del") {
				data["oldImages"].push(oldData.images[0].src); 
				let filtered: any[] = [...data.images.filter((img: any) => { return img.position !== 0 })];
				data.images.splice(0, filtered.length, ...filtered);
			} else if (field === "images[1]") {
				if (oldData.images[1] && oldData.images[1].hasOwnProperty('src')) {
					data["oldImages"].push(oldData.images[1].src);
				}
				data.images[1] = formData.images[1];
			} else if (field === "images[1]_del") {
				data["oldImages"].push(oldData.images[1].src);
				let filtered: any[] = [...data.images.filter((img: any) => { return img.position !== 1 })];
				data.images.splice(0, filtered.length, ...filtered);
			} else {
				data[field] = formData[field];
			}
		});
		
		await updateTreatment({
			variables: {
				id: location.pathname.split("/").pop(),
				data: cryptor.encrypt(JSON.stringify(data)),
			},
			context: gqlHeader
		});
	};

	const onUpload = (position: number, fileObj: any) => {
		let image: TreatmentImageUploadInterface = {
			base64: fileObj.base64,
			contentType: fileObj.type,
            fileName: utility.fileNameHasher(fileObj.name, fileObj.type),
			position: position,
		};
		formData.images[position] = image;
		updateFieldsToBeUpdated(`images[${position}]`);
		setFormData({ ...formData });
	};

	const onDelete = (position: number) => {
		if (oldData.images[position] && oldData.images[position].hasOwnProperty('src')) {
			setFormData({ ...formData, images: formData.images.filter((img: any) => { return img.position !== position }) });
			updateFieldsToBeUpdated(`images[${position}]_del`);
		}
	};

	const validateFormData = () => {
		if (fieldsToBeUpdated.includes('images[0]_del') && typeof formData.images[0] === 'undefined') {
			return true;
		}

		if (fieldsToBeUpdated.length > 0) {
			return false;
		}

		if (formData.price.length <= 0) {
			return true;
		}

		if (formData.title.length <= 0) {
			return true;
		}

		return false;
	};

	return initData && initData.getTreatmentById ? (
		<div className="adminActionDiv">
			<span className="adminActionHeading adminActionBookingHeading">
				<span>{initData.getTreatmentById.title}</span>
				<span></span>
				<ActionButton
					label={"Confirm"}
					title={"Click to submit."}
					isDisable={isDisable}
					onClick={() => onSubmit()}
					canRateLimit={true}
					rateLimitCount={10}
					rateLimitFor={3}
					styling={'basic-brown'}
				/>
			</span>
			<table className="adminActionTable">
				<tbody>
					<tr>
						<td className="adminActionHeader">Enable</td>
						<td>
							<Switch onChange={onSwitchChange} checked={formData.isEnable} />
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Title</td>
						<td>
							<BasicTextInput
								identifier="title"
								isRequired={true}
								/** @ts-ignore */
								canShowError={formData.title?.length > 0 ? false : true}
								errorText="Title is required."
								isAutocomplete={false}
								onChange={onChange}
								value={initData.getTreatmentById.title}
								tabIndex={1}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Price</td>
						<td>
							<BasicTextInput
								identifier="price"
								isRequired={true}
								inputType={"price"}
								prefix={"RM"}
								canShowError={formData.price.length > 0 ? false : true}
								errorText="Price is required."
								isAutocomplete={false}
								onChange={onChange}
								value={initData.getTreatmentById.price}
								tabIndex={2}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Description</td>
						<td>
							<TextArea
								identifier="description"
								onChange={onChange}
								value={initData.getTreatmentById.description}
							/>
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Main thumbnail</td>
						<td>
							{initData.getTreatmentById.images &&
							initData.getTreatmentById.images[0] ? (
								<ImageUpload
									position={0}
									onUpload={onUpload}
									onDelete={onDelete}
									imgSrc={initData.getTreatmentById.images[0].src}
									isRequired
								/>
							) : (
								<ImageUpload position={0} onUpload={onUpload} onDelete={onDelete} isRequired/>
							)}
						</td>
					</tr>
					<tr>
						<td className="adminActionHeader">Secondary thumbnail</td>
						<td>
							{initData.getTreatmentById.images &&
							initData.getTreatmentById.images[1] ? (
								<ImageUpload
									position={1}
									onUpload={onUpload}
									onDelete={onDelete}
									imgSrc={initData.getTreatmentById.images[1].src}
								/>
							) : (
								<ImageUpload position={1} onUpload={onUpload} onDelete={onDelete}/>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	) : null;
}; //end EditAdminTreatment()

export default EditAdminTreatment;
