import { gql } from "@apollo/client";

const REMOVE_PRODUCT_BY_ID = gql`
	mutation removeProductById($id: String!) {
		removeProductById(id: $id) {
			status
			message
		}
	}
`;

export const RemoveProductById = REMOVE_PRODUCT_BY_ID;
