import BookingInterface from "../../../../modules/Booking/Api/Data/BookingInterface";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import statusMapper from "../../../../modules/Booking/Model/StatusMapper";
import EditIcon from '@mui/icons-material/Edit';
import TextInput from "../../../Common/TextInput/TextInput";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { BACKOFFICE_URL } from "../../../../modules/Api/Data/ConstantInterface";
import { useMutation, useQuery } from "@apollo/client";
import { GetBookingsForAdminGql } from "../../../../modules/GraphQl/Resolvers/Booking/getBookingsForAdmin.gql";
import { SearchBookingsForAdminGql } from "../../../../modules/GraphQl/Resolvers/Booking/searchBookingsForAdmin.gql";
import { useHelper } from "../../../Context/HelperProvider";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminBooking = () => {
    const { utility } = useHelper();
    const navigate = useNavigate();
    const { gqlHeader } = useAdmin();
    const { data } = useQuery(GetBookingsForAdminGql, {
        fetchPolicy: 'cache-and-network',
        context: gqlHeader
    });
    const [
        SearchBookings,
        {
            data: searchData,
        }
    ] = useMutation(SearchBookingsForAdminGql);
    const [ bookings, setBookings ] = useState<BookingInterface[]>([]);
    const [ searchedIncrementId, setSearchedIncrementId ] = useState('');

    useEffect(() => {
        if (data) {
            if (data.getBookingsForAdmin) {
                setBookings([...data.getBookingsForAdmin]);
            }
        }
    }, [data]);

    useEffect(() => {
        if (searchData) {
            if (searchData.searchBookingsForAdmin) {
                setBookings([...searchData.searchBookingsForAdmin]);
            }
        }
    }, [searchData]);

    const columns: GridColDef[] = [
        {
            field: 'incrementId',
            headerName: 'Booking ID',
            width: 200,
            sortable: true,
            editable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${statusMapper(params.row.status)}`
                );
            }
        },
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            width: 150,
            sortable: true,
            editable: false,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    RM {params.row.grandTotal ?? '0.00'}
                </span>
            ),
            align: 'left',
            sortComparator: utility.dataGridPriceComparator
        },
        {
            field: 'username',
            headerName: 'Booked by',
            width: 110,
            editable: false,
            align: 'left'
        },
        {
            field: 'bookingTotal',
            headerName: 'Total Slots Booked',
            width: 150,
            editable: false,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => {
                let title: any = '';
                params.row.children.map((child: any, index: any) => {
                    title += `Slot ${index+1}: ${new Date(child.start).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, ${new Date(child.start).toLocaleTimeString('en-US', { hour: '2-digit'})} - ${new Date(child.end).toLocaleTimeString('en-US', { hour: '2-digit'})}\n`
                })
                return (
                    <span title={title}>
                        {params.row.children.length}
                    </span>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            sortable: true,
            editable: false,
            width: 210,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    `${utility.dateFormatter(new Date(params.row.createdAt))}`
                );
            },
            align: 'left'
        },
        {
            field: 'edit',
            headerName: 'Action',
            sortable: false,
            editable: false,
            width: 70,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span className='actionTab' onClick={() => {
                        // get available booking timeslot for admin to perform reschedule (booking status = new)
                        navigate(`/${BACKOFFICE_URL}/core/booking/edit/${params.row.id}`)
                    }}><EditIcon fontSize="small"/>Edit</span>
                );
            },
            align: 'left'
        }
    ];

    const onSearchBooking = async (value: any, identifier: any) => {
        await SearchBookings({
            variables: {
                query: value
            },
            context: gqlHeader
        })
    }

    return (
        <div className="adminActionDiv">
            <span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Bookings</span>
                <ActionButton 
                    label={'Live Booking'} 
                    title={'Click to view live booking.'} 
                    isDisable={false}
                    onClick={() => {window.open('/booking', '_blank')}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
                <ActionButton 
                    label={'Today\'s \nBooking'} 
                    title={'Click to view today\'s booking.'} 
                    isDisable={false}
                    onClick={() => {navigate(`/${BACKOFFICE_URL}/core/booking/today`)}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown-outline'}
                />
			</span>
            <div className="adminActionContDiv">
                <TextInput 
                    identifier='incrementId'
                    label="Booking ID" 
                    isRequired={true}
                    canShowError={false}
                    icon={<SearchIcon/>}
                    isAutocomplete={true}
                    onChange={onSearchBooking}
                    tabIndex={1}
                />
                <div className='dataGrid'>
                    <DataGrid
                        rows={bookings}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                    />
                </div>
            </div>
        </div>
    );
}//end AdminBooking()

export default AdminBooking;
