import { gql } from "@apollo/client";

const GET_ALL_TESTIMONIAL_IMAGE = gql`
	query getAllTestimonialImage {
		getAllTestimonialImage {
			fields0
            fields1
		}
	}
`;

export const GetAllTestimonialImage = GET_ALL_TESTIMONIAL_IMAGE;