import { gql } from "@apollo/client";

const UPDATE_BANNER_BY_ID = gql`
	mutation updateBannerById($id: String!, $data: String!) {
		updateBannerById(id: $id, data: $data) {
			status
			message
		}
	}
`;

export const UpdateBannerById = UPDATE_BANNER_BY_ID;