import { gql } from "@apollo/client";

const GET_FAQ_BY_ID = gql`
	query getFaqById ($id: String!) {
		getFaqById (id: $id) {
			id
			title
			description
            class
            createdAt
		}
	}
`;

export const GetFaqById = GET_FAQ_BY_ID;