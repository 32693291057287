import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetCoreConfigGql } from "../../../../modules/GraphQl/Resolvers/Config/getCoreConfig.gql";
import { UpdateCoreConfigGql } from "../../../../modules/GraphQl/Resolvers/Config/updateCoreConfig.gql";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import BasicTextInput from "../../../Common/BasicTextInput/BasicTextInput";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as StripeLogo } from "../../../../assets/logo/stripe_logo.svg";
import './AdminConfig.scss';
import { Switch } from "@mui/material";
import { useHelper } from "../../../Context/HelperProvider";
import ConfigInterface from "../../../../modules/Firebase/Api/Data/ConfigInterface";
import { useAdmin } from "../../../Context/AdminProvider";

const AdminConfig = () => {

    const { cryptor } = useHelper();
    const { gqlHeader } = useAdmin();
    const { data, loading, error } = useQuery(GetCoreConfigGql, {
        fetchPolicy: 'cache-and-network',
        context: gqlHeader
    });
    const [ updateCoreConfig, 
        { 
            data: updatedData,
            loading: updatedLoading,
            error: updatedError
        } 
    ] = useMutation(UpdateCoreConfigGql);
    const [ configData, setConfigData ] = useState<ConfigInterface>({
        stripe: {
            isProduction: false
        }
    });
    const [ updatedConfigField, setUpdatedConfigField ] = useState<string[]>([]);
    const [ isDisable, setIsDisable ] = useState(true);

    useEffect(() => {
        if (data) {
            if (data.getCoreConfig) {
                setConfigData({...configData, ...data.getCoreConfig});
            }
        }
    }, [data]);

    useEffect(() => {
        if (updatedData) {
            if (updatedData.updateCoreConfig.status) {
                window.location.reload();
            }
        }
    }, [updatedData]);

    useEffect(() => {
        updatedConfigField.every((field: string) => {
            let fieldArr: string[] = field.split('/');
            if (configData[fieldArr[0]][fieldArr[1]] && configData[fieldArr[0]][fieldArr[1]].length <= 0) {
                setIsDisable(true);
                return false;
            }
            setIsDisable(false);
            return true;
        });
    }, [updatedConfigField]);

    const onChange = (value: any, identifier: any) => {
        let tempData: any = {};
        let identifierArr: string[] = identifier.split('/');
        if (identifierArr.slice(-1).includes('encrypt')) {
            value = cryptor.encrypt(value);
        }
        if (configData.hasOwnProperty(identifierArr[0])) {
            tempData = {
                [identifierArr[0]] : {
                    ...configData[identifierArr[0]],
                    [identifierArr[1]]: value
                }
            };
        } else {
            tempData = {
                [identifierArr[0]] : {
                    [identifierArr[1]]: value
                }
            };
        }
        updateFieldsToBeUpdated(identifier);
        setConfigData({
            ...configData,
            ...tempData
        })
    }

    const updateFieldsToBeUpdated = (field: string) => {
        if (!updatedConfigField.includes(field)) {
            updatedConfigField.push(field);
        }
        setUpdatedConfigField([...updatedConfigField]);
    }

    const onSubmit = async () => {
        let data: any = [];
        updatedConfigField.forEach((field: any) => {
            let fieldArr = field.split('/');
            data.push({
                key: fieldArr[0],
                value: {
                    [fieldArr[1]]: configData[fieldArr[0]][fieldArr[1]]
                }
            })
        });
        await updateCoreConfig({
            variables: {
                data: cryptor.encrypt(JSON.stringify(data)),
            },
            context: gqlHeader
        })
    }

    return (
        <div className="adminActionDiv">
            <span className="adminActionHeading adminActionBookingHeading">
                <span>Manage Configurations</span>
                <span></span>
                <ActionButton 
                    label={'Update'} 
                    title={'Click to update.'} 
                    isDisable={isDisable}
                    onClick={() => {onSubmit()}}
                    canRateLimit={true}
                    rateLimitCount={10}
                    rateLimitFor={3}
                    styling={'basic-brown'}
                />
			</span>        
            {
                data && data.getCoreConfig &&
                <table className="adminActionTable">
                    <tbody>
                        <tr>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="basic-settings"
                                    id="basic-settings"
                                >
                                    <span className="configSectionTitle">Basic Settings</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="tdHeader">
                                                    Booking Prefix
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='prefix/booking'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.prefix?.booking?.length > 0 ? false : true}
                                                        maxLength={10}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.prefix?.booking}
                                                        tabIndex={1}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Booking ID will be shown to customer as <strong>#{configData.prefix?.booking}00000001</strong>.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    Booking Item Prefix
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='prefix/bookingItem'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.prefix?.bookingItem?.length > 0 ? false : true}
                                                        maxLength={10}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.prefix?.bookingItem}
                                                        tabIndex={1}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Booking Item ID will be shown to customer as <strong>#{configData.prefix?.bookingItem}00000001</strong>.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    Payment Prefix
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='prefix/payment'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.prefix?.payment?.length > 0 ? false : true}
                                                        maxLength={10}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.prefix?.payment}
                                                        tabIndex={2}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Payment ID will be shown to customer as <strong>#{configData.prefix?.payment}00000001</strong>.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    Refund Prefix
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='prefix/refund'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.prefix?.refund?.length > 0 ? false : true}
                                                        maxLength={10}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.prefix?.refund}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Refund ID will be shown to customer as <strong>#{configData.prefix?.refund}00000001</strong>.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="booking-settings"
                                    id="booking-settings"
                                >
                                    <span className="configSectionTitle">Booking Settings</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="tdHeader">
                                                    Maximum month range
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='booking/maxMonth'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.booking?.maxMonth?.length > 0 ? false : true}
                                                        maxLength={2}
                                                        isNumber
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.booking?.maxMonth}
                                                        tabIndex={1}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    The hard limit of maximum month range available for booking. Default is <strong>2</strong>.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="payment-settings"
                                    id="payment-settings"
                                >
                                    <span className="configSectionTitle">Payment Settings</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="tdHeader">
                                                    Retry Payment Timeout
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='payment/repaymentTimeout'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.payment?.repaymentTimeout?.length > 0 ? false : true}
                                                        maxLength={2}
                                                        isNumber
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.payment?.repaymentTimeout}
                                                        tabIndex={1}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    The timeout for customer to fulfill payment for failed payment booking(s). Default is <strong>1</strong> hour.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    Auto-cancel Failed Payment Booking(s)
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='payment/autoCancelDuration'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.payment?.autoCancelDuration?.length > 0 ? false : true}
                                                        maxLength={2}
                                                        isNumber
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.payment?.autoCancelDuration}
                                                        tabIndex={1}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    The duration to cancel failed payment booking(s). Default is <strong>1</strong> hour.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="stripe-settings"
                                    id="stripe-settings"
                                >
                                    <span className="configSectionTitle"><span className="configLogo"><StripeLogo/></span>Stripe Settings</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="tdHeader">
                                                    Is Live mode?
                                                </td>
                                                <td>
                                                    <Switch 
                                                        onChange={(e: any) => {onChange(e.target.checked, 'stripe/isProduction')}}
                                                        checked={configData.stripe?.isProduction}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    If enabled, payment will be created in <strong>[LIVE]</strong> environment. Else, <strong>[TEST]</strong> environment will be used.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    Refund Amount
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='stripe/refundAmount'
                                                        isRequired={true}
                                                        inputType={"price"}
                                                        prefix={'RM'}
                                                        /** @ts-ignore */
                                                        canShowError={configData.stripe?.refundAmount?.length <= 0 ? true : false}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={data.getCoreConfig.stripe?.refundAmount}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Hard limit for refund amount per booking item. Default is <strong>RM 50.00</strong>.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    <strong>[TEST]</strong> Publishable Key
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='stripe/testPublishableKey/encrypt'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.stripe?.testPublishableKey?.length <= 0 ? true : false}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={cryptor.decrypt(data.getCoreConfig.stripe?.testPublishableKey)}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Stripe publishable key for testing environment. Publishable key can be obtained via <a target='_blank' href="https://dashboard.stripe.com/test/apikeys">here</a> (Standard Keys→Publishable Key→Token).
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    <strong>[TEST]</strong> Secret Key
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='stripe/testSecretKey/encrypt'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.stripe?.testSecretKey?.length <= 0 ? true : false}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={cryptor.decrypt(data.getCoreConfig.stripe?.testSecretKey)}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Stripe secret key for testing environment. Secret key can be obtained via <a target='_blank' href="https://dashboard.stripe.com/test/apikeys">here</a> (Standard Keys→Secret Key→Token).
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    <strong>[LIVE]</strong> Publishable Key
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='stripe/publishableKey/encrypt'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.stripe?.publishableKey?.length <= 0 ? true : false}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={cryptor.decrypt(data.getCoreConfig.stripe?.publishableKey)}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Stripe publishable key for live environment. Publishable key can be obtained via <a target='_blank' href="https://dashboard.stripe.com/apikeys">here</a> (Standard Keys→Publishable Key→Token).
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdHeader">
                                                    <strong>[LIVE]</strong> Secret Key
                                                </td>
                                                <td>
                                                    <BasicTextInput 
                                                        identifier='stripe/secretKey/encrypt'
                                                        isRequired={true}
                                                        /** @ts-ignore */
                                                        canShowError={configData.stripe?.secretKey?.length <= 0 ? true : false}
                                                        isAutocomplete={false}
                                                        onChange={onChange}
                                                        value={cryptor.decrypt(data.getCoreConfig.stripe?.secretKey)}
                                                        tabIndex={3}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="infoTd">
                                                    Stripe secret key for live environment. Secret key can be obtained via <a target='_blank' href="https://dashboard.stripe.com/apikeys">here</a> (Standard Keys→Secret Key→Token).
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}//end AdminConfig()

export default AdminConfig;
