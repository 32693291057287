import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { ENDPOINT_GQL } from "../../Api/Data/ConstantInterface";

const httpLink = createHttpLink({
  uri: ENDPOINT_GQL
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

export default client;
