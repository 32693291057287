import "./SingleProduct.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from '@mui/icons-material/Close';
import BottomCarousel from "../../Common/BottomCarousel/BottomCarousel";
import Utility from "../../../modules/Utility/Utility";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Breadcrumbs, Link, Modal, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetProductById } from "../../../modules/GraphQl/Resolvers/Product/getProductById.gql";
import { GetProductsForCustomer } from "../../../modules/GraphQl/Resolvers/Product/getProductsForCustomer.gql";

const SingleProduct = () => {

    const location = useLocation();
    const locationUrl = location.pathname.split("/");
    const { urlKey } = useParams();
    const navigate = useNavigate();
    const utility: Utility = new Utility;

    const [productData, setProductData] = useState<any>({
        title: "",
        description: "",
        price: "",
        capacity: "",
        isEnabled: "",
        ingredients: [],
        functionalities: [],
        usageDirection: [],
        thumbnail: [],
    });
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [thumbnail, setThumbnail] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [fullSizeImage, setFullSizeImage] = useState("");

    const { data } = useQuery(GetProductById, {
        variables: {
            id: urlKey,
        }
    });
    const { data: allData } = useQuery(GetProductsForCustomer);

    const handleOpen = (imageSource) => {
        setFullSizeImage(imageSource.props.children.props.src);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: "auto",
        })
    }, [])

    useEffect(() => {
        if (data) {
            if (typeof data.getProductById.id !== "string") {
                navigate(`/products`);
            } else {
                const capacity = utility.modifyCapacity(data?.getProductById?.capacity ?? '');
                const price = utility.modifyPrice(data?.getProductById?.price);
                const tempIngredient = data.getProductById?.ingredients?.map((ingredient) => {
                    return ingredient.name;
                }).join(", ");
                setProductData({ ...data.getProductById, capacity: capacity, ingredients: tempIngredient, price: price });
                setThumbnail([...data.getProductById.thumbnail]);
            }
        }
    }, [data])

    useEffect(() => {
        if (allData) {
            if (allData.getProductsForCustomer) {
                let filtered = [];
                filtered = allData.getProductsForCustomer.filter((item) => {
                    return item.id !== urlKey;
                });
                setFilteredProduct(filtered);
            }
        }
    }, [allData])

    return (
        <div className="singleProductContainer">
            <Box sx={{ height: "fit-content", background: "#f4eee9", m: "100px 0 40px 0" }}>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{ display: "flex", justifyContent: "center", p: 1, mt: "20px" }}>
                            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbsDiv">
                                <Link href="/">
                                    Home
                                </Link>
                                <Link sx={{ textTransform: "capitalize" }} href={`/${locationUrl[1]}`}>
                                    {locationUrl[1]}
                                </Link>
                                <Typography className="primaryBreadcrumbs">{productData.title}</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                </Grid>
                <div className="singleProductContentDiv">
                    <div className="carouselContent">
                        <Carousel
                            showIndicators={false}
                            showStatus={false}
                            showArrows={false}
                            swipeable={true}
                            dynamicHeight={true}
                            thumbWidth={50}
                            autoFocus={true}
                            useKeyboardArrows={true}
                            onClickItem={(index, item) => {
                                handleOpen(item);
                            }}
                        >
                            {thumbnail.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={item.src} alt={index} />
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div className="singleProductContent">
                        <div className="contentHeader">
                            {productData.title}
                            {
                                productData.capacity &&
                                <div className="capacityDetails">{productData.capacity}</div>
                            }
                        </div>
                        <div className="contentDetails">
                            {
                                productData.description &&
                                <div className="descriptionDetails">{productData.description}</div>
                            }
                            <div className="priceDetails">{productData.price}</div>
                        </div>
                        {
                            productData.functionalities && productData.functionalities.length > 0 &&
                            <Accordion className="accordionOuter">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="accordionTitle">Benefits</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {productData.functionalities.map((benefit, index) => {
                                        return (
                                            <div className="accordionDetails" key={index}>
                                                <Typography className="accordionContent">&#x2022; {benefit}</Typography>
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        }
                        {
                            productData.usageDirection && productData.usageDirection.length > 0 &&
                            <Accordion className="accordionOuter">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="accordionTitle">Direction of use</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {productData.usageDirection.map((usage, index) => {
                                        return (
                                            <div className="accordionDetails" key={index}>
                                                <Typography className="accordionContent">Step {index} : {usage}</Typography>
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        }
                        {
                            productData.ingredients &&
                            <Accordion className="accordionOuter">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="accordionTitle">Ingredients</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="accordionDetails">
                                        <Typography className="accordionContent">{productData.ingredients}</Typography>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </div>
                </div>
                <BottomCarousel data={filteredProduct} viewItem={"products"} />
            </Box>
            <Modal open={open} onClose={handleClose} className="modalRootDiv">
                <div className={`${open ? "open modal" : "modal"} modalDiv`}>
                    <div className="modalHeader">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <div className="modalImageDiv">
                        <img src={fullSizeImage} className="modalImage" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SingleProduct;