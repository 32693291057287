import crypto, { enc } from "crypto-js";
import { SALT } from "../Api/Data/ConstantInterface";

/** Encryption and decryption wrapper of CryptoJS */
export default class Cryptor {

    /**
     * Encrypt provided value with salt
     * 
     * @param value 
     * @returns string
     */
    encrypt(value: any) {
        return crypto.AES.encrypt(value, SALT).toString();
    }//end encrypt()

    /**
     * Decrypt provided value with salt
     * 
     * @param value 
     * @returns string
     */
    decrypt(value: any) {
        if (value) {
            return crypto.AES.decrypt(value, SALT).toString(enc.Utf8);
        }
        return value;
    }//end decrypt()

}//end class